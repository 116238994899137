import React, {useEffect, useState} from "react";
import {Checkbox, MultiSelect, NumberInput, Select} from "@mantine/core";
import {ROLES, ROLES_WOUT_GUEST_TOUS} from "../../constants/roles";
import useApi from "../../hooks/useApi";
import users from "../../api/users";
import {useNotifications} from "@mantine/notifications";
import workshop from "../../api/workshop";

interface Props {
    element: any;
}

const EventRow = (props: Props) => {
    const notifications = useNotifications();

    return (
        <tr key={props.element.id}>
            <td style={{width: 50}}>
                {props.element.id}
            </td>
            <td style={{width: 200}}>
                {props.element.thematic}
            </td>
            <td style={{width: 200}}>{props.element.title}</td>
            <td style={{width: 200}}>
                {props.element.former.firstName + " " + props.element.former.lastName}
            </td>
            <td style={{width: 300}}>
                {props.element.startDate ? new Date(props.element.startDate).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                }) + " - " + new Date(props.element.endDate).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                }) : "Non"}
            </td>
            <td style={{width: 50}}>
                {props.element.price}
            </td>
            <td style={{width: 200}}>
                {props.element?.site?.city + " " + props.element?.site?.postalCode.slice(0, 2)}
            </td>
            <td style={{width: 200}}> {props.element?.totalGuests}</td>
        </tr>
    )
}

export default EventRow