import React, { useEffect, useState } from "react";
import { Checkbox, MultiSelect, NumberInput, Select } from "@mantine/core";
import { ROLES, ROLES_WOUT_GUEST_TOUS } from "../../constants/roles";
import useApi from "../../hooks/useApi";
import users from "../../api/users";
import { useNotifications } from "@mantine/notifications";
import workshop from "../../api/workshop";
import formation from "../../api/formation";
import { formatElementStartAndEndDate } from "../../utility/dateUtility";

interface Props {
    element: any;
}

const FormationRow = (props: Props) => {
    const notifications = useNotifications();
    const [pilot, setPilot] = useState(props.element.former.email);
    const [selectedPilot, setSelectedPilot] = useState("");
    const [usersData, setUsersData] = useState([]);
    const [SubusersData, setSubUsersData] = useState([]);
    const { request: requestUser } = useApi(users.getFromBemanByLastName);
    const { request: requestUserEmail } = useApi(users.getFromBemanByEmail);
    const [search, setSearch] = useState<string>("");
    const [searchStart, setSearchStart] = useState<string>("");
    const { request: requestWorkshop } = useApi(formation.editFormer);

    const searchCopilotEmail = () => {
        requestUser(search).then((res: any) => {
            const tab: any = [];
            for (let i = 0; i < res.data.length; i++) {
                tab.push({
                    label: res.data[i].lastName + " " + res.data[i].firstName,
                    value: res.data[i].email,
                });
            }
            setSubUsersData(tab);
            setUsersData(tab);
        });
    };

    useEffect(() => {
        if (selectedPilot !== "") {
            setPilot(selectedPilot);
            requestUserEmail(selectedPilot).then((res: any) => {
                requestWorkshop(res.data[0].id, props.element.id).then(() => {
                    notifications.showNotification({
                        title: "Nice !",
                        message: "Pilote modifié ! 😀",
                        color: "green",
                    });
                });
            });
        }
    }, [selectedPilot]);

    useEffect(() => {
        if (search?.length > 2 && pilot !== search) {
            if (search?.length > 3) {
                setSearchStart(search);
                searchCopilotEmail();
            }
        }
    }, [search]);

    return (
        <tr key={props.element.id}>
            <td style={{ width: 50 }}>{props.element.id}</td>
            <td style={{ width: 200 }}>{props.element.thematic}</td>
            <td style={{ width: 200 }}>{props.element.title}</td>
            <td style={{ width: 200 }}>
                <Select
                    className="w-full"
                    data={[...usersData, pilot]}
                    defaultValue={pilot}
                    placeholder="Sélectionnez le pilote"
                    searchable
                    nothingFound="Nothing found"
                    onSearchChange={(value) => setSearch(value)}
                    value={pilot}
                    onChange={(value: string) => {
                        setSelectedPilot(value);
                    }}
                />
            </td>
            <td style={{ width: 300 }}>
                {formatElementStartAndEndDate(props.element, "Non")}
            </td>
            <td style={{ width: 50 }}>{props.element.price}</td>
            <td style={{ width: 200 }}>
                {props.element?.site?.city +
                    " " +
                    props.element?.site?.postalCode.slice(0, 2)}
            </td>
            <td style={{ width: 200 }}> {props.element?.totalGuests}</td>
        </tr>
    );
};

export default FormationRow;
