import React from "react";
import {
    MdGroups,
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
} from "react-icons/all";
import profilePictureTest from "../../assets/images/profile-picture-test.png";
import { URL } from "../../constants/URL";
import { Link } from "react-router-dom";
import { getUserRole, getUserPicturePath } from "../../utility/userUtility";

interface InfosCountCardAccountProps {
    key: number;
    el: any;
    myKey: number;
}

function CardLevelBefore(props: InfosCountCardAccountProps) {
    const [showLevel, setShowLevel] = React.useState(true);
    return (
        <>
            <div
                onClick={() => setShowLevel(!showLevel)}
                className="cursor-pointer flex justify-between mt-4 w-full mr-4 px-4 py-1 rounded bg-white md:text-xl text-xs text-gray-600 shadow"
            >
                <div className="flex py-1">
                    NIVEAU {props.myKey + 1}
                    <span className="flex rounded bg-gray-100 px-2 md:pt-1 ml-2 font-bold text-xs md:text-sm">
                        <MdGroups
                            size={28}
                            className="hidden md:block mr-2 -mt-1 leading-none"
                        />
                        1
                    </span>
                </div>
                <div className="flex">
                    <button
                        onClick={() => setShowLevel(!showLevel)}
                        className="flex px-2 md:py-2 text-gray-600"
                    >
                        {showLevel ? (
                            <MdKeyboardArrowUp size={24} className="mr-1" />
                        ) : (
                            <MdKeyboardArrowDown size={24} className="mr-1" />
                        )}
                    </button>
                </div>
            </div>
            {showLevel && (
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 justify-start mt-2">
                    <div className="col-start-1 xl:col-start-2 flex flex-nowrap justify-start mt-2">
                        <div className="w-full bg-white rounded shadow-lg border p-2 m-2">
                            <div className="flex">
                                <img
                                    className="w-20 h-20 rounded"
                                    src={getUserPicturePath(props.el)}
                                    alt="profile"
                                />
                                <div className="px-2">
                                    <p className="font-bold text-gray-600  mb-2">
                                        <span className="hover-underline">
                                            {" "}
                                            <Link
                                                to={
                                                    "/auth/adherents/profil/" +
                                                    props.el.id
                                                }
                                            >
                                                {props.el.firstName}{" "}
                                                <span className="uppercase">
                                                    {props.el.lastName}
                                                </span>
                                            </Link>
                                        </span>
                                        <span className="bg-gray-600 rounded-md p-1 px-2 ml-1 text-white text-center text-xxs">
                                            {getUserRole(props.el)}
                                        </span>
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        {props.el.phoneNumber}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        {props.el.email}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CardLevelBefore;
