import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import CapitalizeLetter from "../../hooks/CapitalizeLetter"
import { format_d_MMMM_YYYY } from "../../utility/dateUtility";

const HomeAdviceWorkshop = ({data, isNotHome = false}: any) => {

    return (
        <div className="w-full ">
            <div className={"p-3 bg-white  bg-opacity-70 hover:bg-opacity-100 cursor-pointer border border-b-1 duration-150"}>
                <Link to={"/auth/workshop?title="+data?.title}>
                    <div className="flex flex-row justify-between" style={{color: "#687687"}}>
                        <div className="flex flex-col text-left">
                            {<div className=" text-eleven text-gray-500">{data?.thematic}</div>}
                            <h4 style={{fontWeight: isNotHome ? "initial" :"bold",color: isNotHome ? "#333" : "#687687",  maxWidth: "250px", fontSize: isNotHome ? "24px" : "15px"}}>{data?.title && CapitalizeLetter(data?.title)}</h4>
                        </div>
                        <div className="flex flex-col text-right uppercase" style={{fontSize:isNotHome ? "18px" : "11px"}}>
                            <p>{format_d_MMMM_YYYY(data?.startDate)}</p>
                            {data?.isVisio ? <p className="text-right uppercase text-gray-500 text-eleven" style={{fontSize: isNotHome ? "18px" : "11px"}}>Visio</p> :
                                <div className='flex-col'>
                                    <p className="text-right text-eleven text-gray-500 uppercase" style={{fontSize: isNotHome ? "18px" :"11px"}}>{data?.site?.city} ({data?.site?.postalCode.slice(0,2)})</p>
                                </div>
                            }
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};
export default HomeAdviceWorkshop;
