import request from "./request";

const endpoint = "/credit_histories";
const securedEndpoint = "/expert-accountant";


const collection = () => {
    return request.get(endpoint);
};
const expertAccountantCollection = (selectedYear:any, page:number) => {
    return request.get(`${securedEndpoint}/credit-history/${selectedYear}/${page}`);
};
const detail = (userId:any, selectedYear:any, page:number) => {
    return request.get(`${securedEndpoint}/credit-detail/${userId}/${selectedYear}/${page}`);
};
const save = (data:any) => {
    return request.post(endpoint, {
        type: data.type,
        amount: data.amount,
        amountLeft: data.amount,
        transaction_id: data.transaction_id,
        description: data.description,
        user: "/api/users/" + data.user,
        date: data.date ?? new Date().toISOString(),
        action: data.action ?? null
    });
};

const update = (data:any) => {
     return request.patch(`${endpoint}/${data.credit}`, {
        transaction: "/api/transactions/" + data.transaction,
    }, {
        headers: {
            'Content-Type': 'application/merge-patch+json',
        }
    })
    .then((response) => {
        // Traitement de la réponse
        console.log('Mise à jour réussie', response);
    })
    .catch((error) => {
        // Gestion des erreurs
        console.error('Erreur lors de la mise à jour:', error);
    });
};

const updateTransactionCredit = (data:any) => {
  var user_id = data.user_id
  return request.post(`/update/${user_id}/transaction/credit`, {
    transaction_id: data.transaction_id ?? null,
    atelier: data.atelier,
    credit: data.credit,
  })
}

const getListCreditEchec = (data:any) => {
  return request.post(`/credit/paiement/echec`, {
    user_id: data.user_id
  })
}

const updateCreditPaiementEchec = (data:any) => {
  var user_id = data.user_id
  return request.post(`/update/${user_id}/credit/echec`, {
    credit_ids: data.credit_ids ?? [],
  })
}

const updateCreditPartialEchec = (data:any) => {
  var user_id = data.user_id
  return request.post(`/update/credit/partial/${user_id}/echec`, {
    credit_partials: data.credit_partials ?? [],
  })
}

const exported = {
    save,
    detail,
	collection,
	expertAccountantCollection,
    update,
    updateTransactionCredit,
    getListCreditEchec,
    updateCreditPaiementEchec,
    updateCreditPartialEchec,
}


export default exported;