import React, {useEffect} from 'react';
import storage from '../../auth/storage'

const Mentions = () => {
    const user:any = storage.getUser()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="mx-auto py-10 px-14 bg-bg-connect bg-cover w-full">
            <div className='flex justify-between items-center w-full mb-10'>
                <a className='w-1/6' href={user ? "#/auth/home" : "/"}><img src="/static/media/logo_FM.a3b3bf10.png" alt=""/></a>
                <div className='w-full'>
                    <h1 className="text-center font-bold text-3xl">Mentions légales</h1>
                </div>
            </div>
            <h3 className='font-bold text-xl mb-3'>Association</h3>
            <div>
                <p>www.asso-forman.fr est le site officiel de l’Association Nationale FORMAN.</p>
                <div className='ml-5'>
                    <li>Adresse :  2 Bis rue Léon Blum 78350 Jouy en Josas</li>
                    <li>Courriel : communication@asso-forman.fr</li>
                    <li>Directeur de la publication : Cédric PAYRAUDEAU</li>
                    <li>Rédaction, animation et gestion éditoriale : Cédric PAYRAUDEAU</li>
                </div>
            </div>
            <hr className='my-4' />
            <h3 className='font-bold text-xl mb-3'>Création</h3>
            <div className='ml-5'>
                <li>Caléis</li>
                <li>62 avenue de l'Europe - 78 140 Vélizy</li>
                <li><a href="http://www.caleis.fr/">http://www.caleis.fr</a></li>
            </div>
            <hr className='my-4' />
            <h3 className='font-bold text-xl mb-3'>Hébergement</h3>
            <div>
                <p>Le site www.asso-forman.fr est hébergé sur un serveur dédié.</p>
                <div className='ml-5'>
                    <li>OVH</li>
                    <li>2 rue Kellermann - 59100 Roubaix - France</li>
                    <li>Tél : 08 203 203 63 / n° indigo (0,118 €/min)</li>
                </div>
            </div>
            <hr className='my-4' />
            <h3 className='font-bold text-xl mb-3'>Accès</h3>
            <p>Vous êtes actuellement connecté au site accessible à l'adresse <a href="https://asso-forman.fr">https://asso-forman.fr</a></p>
            <p>Ce site est destiné aux mandataires du Groupe Omnium Finance et a pour objet la mise à disposition d’une salle et la restauration sur place.</p>
            <p>Il vous permet d'accéder à un espace sécurisé par identifiants (login et mot de passe) qui vous seront communiqués par courriel.</p>
            <hr className='my-4' />
            <h3 className='font-bold text-xl mb-3'>Utilisation générale du site</h3>
            <div className='mb-2'>
                <p>
                    L’association FORMAN met tout en œuvre pour offrir aux internautes des informations,
                    outils et services disponibles et vérifiés.
                </p>
                <p>
                    Il s'engage à faire ses meilleurs efforts pour sécuriser conformément à l'état de l'art l'accès,
                    la consultation et l'utilisation des informations.
                </p>
                <p>
                    L'internaute déclare accepter les risques et les limites de l'Internet, et reconnaître en particulier 
                    qu'il est seul responsable de l'usage qu'il fait des informations.
                </p>
            </div>
            <div className='mb-2'>
                <p>
                    Les informations et opinions contenues sur le site asso-forman.fr ne peuvent en aucun cas être considérées 
                    comme des conseils formels engageant la responsabilité de FORMAN et/ou de leurs auteurs. 
                </p>
                <p>
                    Les services et les données sont uniquement fournis à titre d'information. Ni FORMAN, ni aucun de ses partenaires 
                    fournisseurs d'informations ne sauraient être tenus pour responsables des erreurs, omissions, 
                    altérations ou délais de mise à jour des informations et de leurs éventuelles conséquences dans 
                    le cadre de leur utilisation. En aucun cas, ils ne sauraient être tenus pour responsables 
                    des dommages directs et indirects résultant de l'accès ou de l'usage du site.
                </p>
            </div>
            <div className='mb-2'>
                <p>
                    L'internaute a également connaissance de la nature de l'Internet, notamment de ses performances techniques 
                    et des temps de réponse pour consulter, interroger ou transférer les informations.
                </p>
                <p>
                    Enfin, il appartient à l'internaute de prendre toutes les mesures appropriées pour protéger ses propres données et/ou logiciels 
                    de la contamination par d'éventuels virus circulant au travers du site de FORMAN et/ou des informations qui y figurent.
                </p>
            </div>
            <p>
                Il appartient aux internautes de s'équiper de manière appropriée pour accéder aux services proposés et pour les utiliser, 
                notamment en matière informatique, de télécommunications et de sécurité et de maintenir cet équipement.
            </p>
            <hr className='my-4' />
            <h3 className='font-bold text-xl mb-3'>Droits d'auteur</h3>
            <div className='mb-2'>
                <p>
                    Les photographies, textes, slogans, dessins, images, séquences animées sonores ou non ainsi que toutes œuvres intégrés 
                    dans le site sont la propriété de l'Association Nationale FORMAN ou de tiers ayant autorisé l’Association Nationale FORMAN 
                    à les utiliser.
                </p>
                <p>
                    Les logos, icônes et puces graphiques représentés sur le site sont protégés au titre des droits d'auteur et 
                    des articles L.511.1 et suivants du Code de la propriété intellectuelle relatifs à la protection des modèles déposés.
                </p>
                <p>
                    Les reproductions, sur un support papier ou informatique, dudit site et des œuvres qui y sont reproduits sont autorisées 
                    sous réserve qu'elles soient strictement réservées à un usage personnel excluant tout usage à des fins publicitaires et/ou 
                    commerciales et/ou d'information et/ou qu'elles soient conformes aux dispositions de l'article L122-5 du Code de 
                    la Propriété Intellectuelle.
                </p>
                <p>
                    A l'exception des dispositions ci-dessus, toute reproduction, représentation, utilisation ou modification, 
                    par quelque procédé que ce soit et sur quelque support que ce soit, de tout ou partie du site, 
                    de tout ou partie des différentes œuvres qui le composent, sans avoir obtenu l'autorisation préalable de 
                    l’Association Nationale FORMAN, est strictement interdite et constitue un délit de contrefaçon.
                </p>
            </div>
            <hr className='my-4' />
            <h3 className='font-bold text-xl mb-3'>Droit applicable</h3>
            <p>
                Le contenu de ce site est régi par le droit français en vigueur et tout litige résultant dudit contenu 
                sera soumis aux juridictions françaises.
            </p>
        </div>
    );
};

export default Mentions;