import { ActionIcon, Button, Table, Pagination } from '@mantine/core';
import { FiEdit, FiDelete } from 'react-icons/fi';
import React from 'react';

type Props = {
  rows: React.ReactElement;
  title: string;
  thead: React.ReactElement;
  onCreate?: () => void;
  pagination?: any;
  onPageChange?: (page: number) => void;
};

const ListingComponent = (props: Props) => {

  const handlePageChange = (newPage: number) => {
    if(props.onPageChange){
      props.onPageChange(newPage);
    }
  };

  return (
    <div className="mx-auto bg-white shadow-xl p-5 overflow-auto">
      <>
        <div className=" mx-10 text-xl text-center">
          <span className=" text-2xl text-center">{props.title}</span>
          <Button
            onClick={props.onCreate}
            className="ml-3 bg-gray-500 rounded"
            size="xs"
            compact
          >
            Créer
          </Button>
        </div>
        <div className="mt-3 overflow-auto md:mt-3 text-xl ">
          <Table className={"overflow-auto "} highlightOnHover>
            {props.thead}
            <tbody className={"overflow-auto "}>{props.rows}</tbody>
          </Table>
          {props.pagination && (
            <div style={
              {
                "textAlign": "center",
                "margin": "auto",
                "display": "flex",
                "justifyContent": "center",
                "padding": "10px"
              }
            }>
              <Pagination page={props.pagination.page} total={props.pagination.totalPages} onChange={handlePageChange} />
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default ListingComponent;
