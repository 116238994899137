import React, {useEffect} from 'react';
import sign1 from "../../assets/images/sign1.png"
import sign2 from "../../assets/images/sign2.png"

const Status = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container mx-auto p-10">
            <h1 className="text-center font-bold">
                STATUS
            </h1>
            <h3 className='font-bold text-xl mb-3'>Article 1er - Fondation</h3>
            <p className="text-justify">
                Il est fondé entre les adhérents aux présents statuts une association régie par la loi du 1er juillet 1901 et le décret du 16 août 1901, ayant pour nom : FORMAN.
            </p>
            <h3 className='font-bold text-xl mt-8 mb-2'>Article 2 - Objet de l'Association</h3>
            <p className="text-justify">
                Cette association a pour objet de regrouper les mandataires du réseau Prodémial pour :
                • Favoriser, pour ses membres, l’accès à des formations permettant d’accroître leur qualification professionnelle,
                • Permettre à ses membres d’être informés des évolutions de la législation réglementant la profession,
                • Faciliter par les échanges entre ses membres, leur développement personnel et professionnel,
                • Négocier des conditions préférentielles auprès de prestataires de services ou fournisseurs de produits (transports, hôtels, location de salles, ordinateurs, etc.) au bénéfice de ses membres,
                • Représenter et défendre les intérêts de ses membres auprès des tiers,
                • Organiser toute activité à caractère social, culturel, sportif ou éducatif pour développer entre ses membres des sentiments de bonne confraternité, d’éthique et de cohésion,
                • Mettre à disposition un outil informatique permettant la matérialisation des étapes-clés de la réussite des membres dans leur métier,
                • Réaliser toutes opérations de Formation Professionnelle, ingénierie, et création de supports pédagogiques
                • Et plus généralement, tout objet permettant de faciliter et de développer l’activité professionnelle et sociale de ses membres.

                L'Association s'interdit toute discussion ou manifestation présentant un caractère politique ou confessionnel.
                La durée de l'Association est illimitée.
            </p>
            <h3 className='font-bold text-xl mt-8 mb-2'>Article 3 - Siège social</h3>
            <p className="text-justify">
                Le siège social est fixé au 2 bis rue Léon Blum à Jouy-en-Josas (78350).

                Il pourra être transféré sur proposition du Conseil d'Administration et après validation en Assemblée Générale Extraordinaire à la majorité des 2/3 des voix des membres présents ou représentés.

            </p>
            <h3 className='font-bold text-xl mt-8 mb-2'>Article 4 - Composition</h3>
            <p className="text-justify">
                L'Association se compose de 3 collèges :
                - membres Actifs,
                - membres Associés,
                - membres Bienfaiteurs.

            </p>

            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 5 - Admission
            </h3>
            <p className="text-justify">
                Pour être adhérent à l'Association, il faut, cumulativement :
                • Etre signataire de la convention cadre du réseau Prodémial ;
                • Etre agréé par le Conseil d'Administration qui statue sans appel, lors de chacune de ses réunions, sur les demandes d'admission présentées ;
                • Avoir payé le droit d'adhésion et être à jour de sa cotisation annuelle ;
            </p>

            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 6 - Les membres
            </h3>
            <p className="text-justify">
                Sont membres Actifs, les adhérents qui s’engagent à coopérer activement à la réalisation des buts de l’Association et qui ont versé une cotisation « membre Actif ». Ils doivent justifier d’une adhésion à l’Association d’au moins un an.

                Sont membres Associés, les personnes qui s’intéressent aux travaux et à la vie de l’Association. Ils versent un droit d'adhésion et une cotisation « membre Associé ». Les membres Associés n’ont pas le droit de vote.

                Peuvent être adhérentes à l’Association, toutes personnes physiques majeures ou personnes morales. Les personnes morales ont la faculté de désigner une personne, autre que le représentant légal, pour les représenter de façon permanente à l’Association. Les personnes physiques majeures ainsi désignées doivent obligatoirement être salariées ou mandataires sociaux des personnes morales et ce pendant toute la durée de leur représentation.

                Sont membres Bienfaiteurs, et désignés comme tels par le Conseil d’Administration, ceux qui ont rendu d’importants services à l’Association sur le plan professionnel ou financier. Ils peuvent être dispensés du droit d’adhésion et de cotisations. Les membres Bienfaiteurs n’ont pas le droit de vote.
            </p>

            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 7 - Radiation
            </h3>
            <p className="text-justify">
                La qualité de membre se perd par :
                a) la démission,
                b) le décès,
                c) la résiliation de sa convention cadre d’adhésion au réseau Prodémial,
                d) l’exclusion prononcée par le Conseil d'Administration constatant qu’un membre ne remplit plus les conditions requises pour faire partie de l’Association et, notamment, pour infraction aux Statuts ou au Règlement Intérieur, pour non-paiement de la cotisation, non respect des décisions de l’assemblée générale ou du Conseil d’Administration, en particulier en matière de pratique professionnelle, d’éthique, de déontologie et formation continue, l'intéressé ayant préalablement été invité par écrit à se présenter devant le Bureau pour fournir toutes explications.
            </p>

            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 8 - Ressources
            </h3>
            <p className="text-justify">
                Les ressources de l'Association comprennent :
                a) le montant des droits d'adhésion et des cotisations,
                b) les subventions de l'Etat, des collectivités territoriales (Europe, régions, départements, communes...) et de leurs établissements publics,
                c) les dons manuels, ainsi que les dons des établissements d'utilité publique,
                d) le produit des actions, formations et manifestations qu’organise l'Association pour la poursuite de son objet social.*
            </p>

            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 9 - Conseil d'Administration
            </h3>
            <p className="text-justify">

                a) Composition - élection
                L’Association est dirigée par un Conseil d’Administration composé d’au maximum 12 représentants parmi les candidats respectant les critères précisés dans le Règlement Intérieur de l’Association.

                Le Conseil d’Administration est élu au scrutin plurinominal en Assemblée Générale Ordinaire pour une durée de 1 an.

                Les candidatures doivent être déclarées au Bureau, par courrier ou e-mail, au moins 7 jours avant la date de l’Assemblée Générale.

                Le Conseil d’Administration valide les candidatures selon les critères précités et arrête la liste des candidats au plus tard 2 jours avant l’Assemblée Générale.

                Les adhérents votent en rayant sur la liste des candidats suffisamment de noms pour atteindre un nombre maximum de 12.
                Un bulletin comportant plus de 12 noms est considéré comme nul. Un bulletin illisible est aussi considéré comme nul.
                Sont élus les candidats ayant réuni le plus de voix, dans la limite des sièges à pourvoir.
                Les membres sont rééligibles selon les mêmes conditions, sans limitation du nombre de mandats.

                Si un membre du Conseil d’Administration ne remplissait plus l’un des critères d’éligibilité, sa démission d’office du Conseil d’Administration serait alors constatée après l’avoir entendu.

                En cas de vacance d'un ou plusieurs de ses membres, le Conseil peut pourvoir provisoirement à leur remplacement parmi les membres Actifs présentant les critères d’éligibilité. Les pouvoirs des membres ainsi cooptés prennent fin à l'époque où doit normalement expirer le mandat des membres remplacés.

                b) Fonctions
                Le Conseil d'Administration a les pouvoirs les plus étendus pour assurer la gestion courante de l'Association.

                Il agit de manière indépendante.

                Le Conseil d’Administration étudie et détermine les grandes orientations de l’Association.

                Sous réserve des pouvoirs expressément attribués à l’Assemblée Générale, et dans la limite de son objet, il se saisit de toute question intéressant la bonne marche de l’Association et règle les affaires qui la concernent.

                Il examine les demandes d’adhésion sur lesquelles il statue de façon non motivée.

                Il convoque les Assemblées Générales dont il fixe l’ordre du jour et auxquelles il soumet, une fois par an, après les avoirs établis, les comptes de l’exercice écoulé.
                En vue de la convocation à l’Assemblée Générale, il détermine et arrête la composition de chaque collège dans les conditions de l’Article 6.

                Il organise les élections.

                Chaque administrateur reçoit les éléments nécessaires à l’accomplissement de sa mission et peut se faire communiquer tous les documents qu’il estime utiles.

                Le Conseil d'Administration peut également désigner un ou plusieurs membres qui peuvent assister à une séance du Conseil avec voix consultative.


                c) Bureau
                Le Conseil d'Administration élit son Président parmi ses membres, au scrutin secret et selon les règles de majorité décrites dans le Règlement Intérieur de l’Association.

                Le Président élu désigne librement 4 autres membres au sein du Conseil d'Administration pour constituer un Bureau comprenant les fonctions suivantes : Président, Trésorier, Secrétaire, Vice-Président responsable des partenariats et Vice-Président responsable des événements.

                L'Association est représentée en justice et dans tous les actes de la vie civile et associative par son Président ou, à son défaut, par un Vice-Président ou, à son défaut, par tout autre membre du Conseil d'Administration habilité à cet effet.
            </p>
            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 10 - Réunion du Conseil d'Administration et du Bureau
            </h3>
            <p className="text-justify">
                Le Conseil d'Administration se réunit au moins une fois par an et chaque fois qu'il est convoqué par son Président, ou sur la demande de plus de la moitié de ses membres.

                Le Conseil d’Administration ne délibère valablement que si la moitié au moins de ses membres est présente sur première convocation et le tiers sur seconde convocation. Les convocations au Conseil d’Administration se font par tout moyen sur première convocation et par courrier recommandé avec accusé de réception sur seconde convocation.

                Les décisions sont prises à la majorité relative, la voix de chaque membre étant pondérée par le Volume d’Affaires de son organisation - au sens du PMR Prodémial - validé comptablement lors de l’exercice précédent (sans prise en compte du VA organisation des éventuels autres membres du Conseil d’Administration faisant partie de l’organisation du membre votant).

                Tout membre du Conseil d’Administration qui, sans excuse acceptée par celui-ci, aura manqué deux séances consécutives, pourra être considéré comme démissionnaire par le Bureau.

                Il est tenu à chaque réunion une feuille de présence et un procès-verbal de séance. Les procès-verbaux sont signés par le Président et le Secrétaire. Ils sont transcrits sur un registre tenu à cet effet et diffusés par e-mail à l’ensemble des membres du Conseil d’Administration.

                Le Bureau est l’exécutif du Conseil d’Administration ; il a pour mission de veiller à la mise en œuvre et à la réalisation des grandes options de l’Association, étudiées et définies par le Conseil d’Administration.

                Les fonctions de membres du Bureau sont exercées gratuitement ; seuls leurs frais et débours sont remboursables sur justification.

                Le Bureau se réunit autant de fois que nécessaire à la demande du Président.
                Le Bureau est dénommé « Comité exécutif » ou « COMEX »..

            </p>

            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 11 - Assemblée Générale Ordinaire
            </h3>
            <p className="text-justify">
                L'Assemblée Générale Ordinaire se réunit au moins une fois par an et chaque fois que nécessaire.

                Tous les adhérents à l’Association sont convoqués à l'Assemblée Générale Ordinaire par e-mail à l’initiative du Bureau, au moins quatorze jours avant la date fixée.

                L'ordre du jour est indiqué sur la convocation. Seules les questions à l'ordre du jour peuvent être traitées lors de l'Assemblée Générale.

                Seuls les membres Actifs y détiennent un droit de vote. Les personnes rétribuées par l'Association peuvent être admises à assister, avec voix consultatives, aux Assemblées Générales.

                Le Président, assisté des membres du Bureau, préside l'Assemblée et expose la situation morale de l'Association.

                Le Trésorier rend compte de sa gestion et soumet le bilan à l'approbation de l'Assemblée.

                Elle délibère sur les rapports relatifs à la gestion du Conseil d'Administration et à la situation morale et financière de l'Association.

                Elle approuve les comptes de l'exercice clos, vote le budget de l'exercice suivant et délibère sur toutes les questions mises à l'ordre du jour.

                Tout membre Actif a la possibilité de se faire représenter aux Assemblées Générales, par un membre du même collège, pour toutes les résolutions à l’ordre du jour à l’exception de l’élection des membres du Conseil d’Administration. Le nombre de pouvoirs est limité à deux par personne.

                Les résolutions sont prises à la majorité des voix des membres présents ou représentés. Par exception, la résolution concernant l’élection des membres du Conseil d’Administration est prise à la majorité des voix des membres présents.

            </p>

            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 12 - Assemblée Générale Extraordinaire
            </h3>
            <p className="text-justify">
                Si besoin est, ou sur la demande de la moitié plus un des membres Actifs, le Président peut convoquer une Assemblée Générale Extraordinaire, suivant les mêmes modalités que pour l'Assemblée Générale Ordinaire.

                L'Assemblée Générale Extraordinaire est seule compétente pour toute modification des statuts de l'association ou pour sa dissolution.

                Les résolutions sont prises à la majorité des 2/3 des voix des membres présents ou représentés.
            </p>

            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 13 - Règlement Intérieur
            </h3>
            <p className="text-justify">
                Un règlement intérieur est établi par le Conseil d'Administration qui le fait approuver par l'Assemblée Générale Ordinaire suivante.

                Ce règlement est destiné à fixer les divers points non prévus par les statuts, notamment ceux qui ont trait à l'administration interne de l'Association.

            </p>
            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 14 - Dissolution
            </h3>
            <p className="text-justify">
                La dissolution ne peut être prononcée que par une décision d’Assemblée Générale Extraordinaire, sur proposition du Conseil d’Administration.

                Un ou plusieurs liquidateurs sont nommés par celle-ci et l'actif, s'il y a lieu, est dévolu (conformément à l'article 9 de la loi du 1er juillet 1901 et au décret du 16 août 1901) à une ou plusieurs associations poursuivant un objet similaire.

                En aucun cas les membres de l'Association ne peuvent se voir attribuer, en dehors de la reprise de leurs apports personnels, une part quelconque des biens de l'Association.
            </p>

            <h3 className='font-bold text-xl mt-8 mb-2'>
                Article 15 - Formalités administratives
            </h3>
            <p className="text-justify">
                La dissolution ne peut être prononcée que par une décision d’Assemblée Générale Extraordinaire, sur proposition du Conseil d’Administration.

                Un ou plusieurs liquidateurs sont nommés par celle-ci et l'actif, s'il y a lieu, est dévolu (conformément à l'article 9 de la loi du 1er juillet 1901 et au décret du 16 août 1901) à une ou plusieurs associations poursuivant un objet similaire.

                En aucun cas les membres de l'Association ne peuvent se voir attribuer, en dehors de la reprise de leurs apports personnels, une part quelconque des biens de l'Association.
                Le Président doit effectuer à la Préfecture les déclarations prévues à l'article 3 du décret du 16 août 1901 portant au règlement d'administration publique pour l'application de la loi du 1er juillet 1901 et concernant notamment :
                - les modifications portées aux statuts,
                - les changements de nom de l'association et transfert du siège social,
                - les changements survenus au sein du Conseil d'administration.


                Les présents statuts ont été amendés en Assemblée Générale Extraordinaire, tenue à Orsay, le 09/06/2021, sous la présidence de Cédric Payraudeau, assisté du Trésorier Damien Cardon.

                <div className="flex justify-evenly flex-auto mt-10">
                    <div className="items-center justify-center">
                        <h2 className="text-center">Le Président </h2>
                        <img className="w-30" src={sign1} alt=""/>
                    </div>
                    <div className="items-center justify-center">
                        <h2 className="text-center">Le Trésorier</h2>
                        <img className="w-44" src={sign2} alt=""/>
                    </div>
                </div>
            </p>
        </div>
    );
};

export default Status;
