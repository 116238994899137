import request from "./request";
import {create} from "apisauce";
import { REACT_APP_API_BASE_URL } from "../env";

const endpoint = "/users";

const register = (user: any) => {
    return request.post(endpoint, {
        email: user.email,
        plainPassword: user.password,
        civilite: user.civility,
        lastName: user.lastName,
        firstName: user.firstName,
        phoneNumber: user.phoneNumber,
        godFather: "/api" + endpoint + "/" + user.idGodFather,
        telPrefix: user.telPrefix,
        dynasty: user.dynasty,
        manager: user.manager
    });
};

const getUserByEmail = (email: string) => {
    return request.get(endpoint + "?email=" + email);
};

const getUserById = (id: string) => {
    return request.get(endpoint + "/" + id);
};


const getUserManager = (id: number) => {
    return create({
        baseURL: `${REACT_APP_API_BASE_URL}/`,
    }).get("user/manager/" + id);
};


const getUserByLastName = (lastName: string) => {
    return request.get(endpoint + "?lastName=" + lastName);
};

const edit = (user: any, id: Number) => {
    console.log( user.lat, user.lng)
    return request.put(endpoint + "/" + id, {
        email: user.email,
        phoneNumber: user.phoneNumber,
        firstName: user.firstName,
        lastName: user.lastName,
        birthDay: user.birthDay,
        civilite: user.civilite,
        country: user.country,
        city: user.city,
        address: user.address,
        postalCode: user.postalCode,
        credit: user.credit,
        isExpertAccountant: user.isExpertAccountant,
        addressAccess: user.addressAccess,
        lat: user.lat,
        lng: user.lng,
    });
};

const remove = (id: number) => {
    return request.delete(endpoint + "/" + id);
};

const createUser = (user: any) => {
    return request.post(endpoint, {
        roles: [user.role],
        civilite: user.civilite,
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        plainPassword: user.password,
        isExpertAccountant: user.isExpertAccountant,
    });
};

const me = () => {
    return request.get("/me");
};

const sendMailTrainingPlan = () => {
    return request.get('/training-plan/need')
}

const infos = (id: Number) => {
    return request.get(endpoint + `/${id}`);
};

const getUserByPhone = (phone: string) => {
    return request.get(endpoint + "?phoneNumber=" + phone);
};

const getUserLigne = (id: number) => {
    return create({
        baseURL: `${REACT_APP_API_BASE_URL}/`,
    }).get('users/ligne/' + id);
};

const getUserLigneTab = (id: number) => {
    return create({
        baseURL: `${REACT_APP_API_BASE_URL}/`,
    }).get('users/ligne/tab/' + id);
};

const missPassword = (phone: string) => {
    return request.put(endpoint + "/" + phone + "/miss", {});
};

const collection = () => {
    return request.get(endpoint);
};


const collectionAll = () => {
    return request.get(endpoint+"/all");
};

const collectionPaginate = (page: Number) => {
    return request.get(endpoint+"/collection?page="+page);
};

const resetPassword = (data: any) => {
    return request.put(endpoint + "/" + data.phone + "/reset", {
        code: data.code,
        password: data.password
    });
};

const advise_thematic = (user: any, id: any) => {
    return request.put(endpoint + "/" + user + "/advise_thematic", {
        ids: id
    });
};

const image = (image: File, id: Number) => {
    const data = new FormData();
    // @ts-ignore
    data.append("file", image);
    const headers = {
        "Content-Type": "multipart/form-data",
    };
    return request.post(endpoint + "/" + id + "/image", data, {
        headers
    });
};

const editNeoMan = (role: string, id: Number) => {
    if (role == "NEOMAN") {
        const date = new Date();
        return request.put(endpoint + "/" + id, {
            roles: ["ROLE_NEOMAN"],
            memberStartCreatedAt: (date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()),
            dateEndMember: ((date.getFullYear() + 1) + "-" + 12 + "-" + 31),
            adhesionPaidAt: ((date.getFullYear())  + "-" + (date.getMonth()+1) + "-" + date.getDate()),
        });
    }
};

const editRole = (role: string, id: Number) => {
    return request.put(endpoint + "/" + id, {
        roles: [role],
    });
};

const editEndCursus = (cursus: string, id: Number) => {
    return request.put(endpoint + "/" + id, {
        endCursus: cursus,
    });
};

const editJD = (JD : boolean,D:boolean,JR :boolean, id: Number) => {
    return request.put(endpoint + "/" + id, {
        isJdEnded: JD,
        isDemarrageEnded: D,
        is3JREnded: JR,
    });
};

const editCredit = (credit: number, id: Number) => {
    return request.put(endpoint + "/" + id, {
        credit: credit,
    });
};

const editIsExpertAccountant = (isExpertAccountant: number, id: Number) => {
    return request.put(endpoint + "/" + id, {
        isExpertAccountant: isExpertAccountant,
    });
};

const editManager = (manager: number, id: Number) => {
    return request.put(endpoint + "/" + id, {
        manager: "/api/users/"+manager,
    });
};

const editGodFather = (godFather: number, id: Number) => {
    return request.put(endpoint + "/" + id, {
        godFather: "/api/users/"+godFather,
    });
};

const editPilot = (pilot: boolean, id: Number) => {
    return request.put(endpoint + "/" + id, {
        isPilot: pilot,
    });
};

const adhesionUpdate = (id: Number) => {
    const date = new Date();
    return request.put(endpoint + "/" + id, {
        dateEndMember: ((date.getFullYear() + 1) + "-" + 12 + "-" + 31),
        adhesionPaidAt: ((date.getFullYear())  + "-" + (date.getMonth()+1) + "-" + date.getDate()),
    });
};

const adhesion = (id: Number) => {
    return request.get(endpoint + "/" + id);
};

const getAdherentByLastName = (lastName: string) => {
    return request.get(endpoint + "?lastName=" + lastName + "&roles[]=ROLE_XMAN&roles[]=ROLE_DXMAN&roles[]=ROLE_DEVMAN&roles[]=ROLE_MAN&roles[]=ROLE_ADMAN&roles[]=ROLE_BEMAN&roles[]=ROLE_NEOMAN");
};

const getGuests = () => {
    return request.get(endpoint + "/guests");
}

const getGuestsId = (id: number) => {
    return request.get(endpoint + "/guests/" + id);
}

const getFromBemanByEmail = (lastName: string) => {
    return request.get(endpoint + "?email=" + lastName + "&roles[]=ROLE_XMAN&roles[]=ROLE_DXMAN&roles[]=ROLE_DEVMAN&roles[]=ROLE_MAN&roles[]=ROLE_ADMAN&roles[]=ROLE_BEMAN");
};

const getFromBemanByLastName = (lastName: string) => {
    return request.get(endpoint + "?lastName=" + lastName + "&roles[]=ROLE_XMAN&roles[]=ROLE_DXMAN&roles[]=ROLE_DEVMAN&roles[]=ROLE_MAN&roles[]=ROLE_ADMAN&roles[]=ROLE_BEMAN");
};

const getAllCoords = () => {
    return request.get(endpoint + "/coords");
}

const exportedApiUsers = {
    getUserManager,
    editRole,
    editEndCursus,
    editJD,
    getFromBemanByLastName,
    adhesion,
    getUserLigneTab,
    getGuestsId,
    getGuests,
    collection,
    collectionAll,
    collectionPaginate,
    register,
    getAdherentByLastName,
    getUserByLastName,
    getFromBemanByEmail,
    getUserLigne,
    infos,
    getUserByPhone,
    me,
    missPassword,
    resetPassword,
    getUserByEmail,
    edit,
    remove,
    createUser,
    advise_thematic,
    image,
    sendMailTrainingPlan,
    editNeoMan,
    adhesionUpdate,editPilot,editCredit,
    editIsExpertAccountant,
    getAllCoords,getUserById,
    editGodFather,
    editManager
}


export default exportedApiUsers;