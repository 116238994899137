import React, { useState } from 'react';

import { Route, Routes, HashRouter, BrowserRouter } from 'react-router-dom';
import useAuth from '../auth/useAuth';
import { ModalsProvider } from '@mantine/modals';
import AuthContext from '../auth/context';
import { NotificationsProvider } from '@mantine/notifications';
import LayoutSimulation from '../components/simulation/layout/LayoutSimulation';
import AdhesionPaymentSimulation from '../components/simulation/payment/Adhesion/AdhesionPaymentSimulation';
import WorkshopPaymentSimulation from '../components/simulation/payment/Workshop/WorkshopPaymentSimulationScreen';
import FormationPaymentSimulation from '../components/simulation/payment/Formation/FormationPaymentSimulation';
import EventPaymentSimulation from '../components/simulation/payment/Event/EventPaymentSimulation';
import Home from './Home';
import RegisterPage from './Auth/RegisterPage';
import HomeConnected from './Connected/Home';
import ListingWorkshop from './Connected/Workshop/ListingWorkshop';
import ItemWorkshop from './Connected/Workshop/ItemWorkshop';
import CreateWorkshop from './Connected/Workshop/CreateWorkshop';
import EditWorkshop from './Connected/Workshop/EditWorkshop';
import ListingFormation from './Connected/Formation/ListingFormation';
import CreateFormation from './Connected/Formation/CreateFormation';
import ItemFormation from './Connected/Formation/ItemFormation';
import EditFormation from './Connected/Formation/EditFormation';
import ListingEvent from './Connected/Event/ListingEvent';
import CreateEvent from './Connected/Event/CreateEvent';
import ItemEvent from './Connected/Event/ItemEvent';
import EditEvent from './Connected/Event/EditEvent';
import ListingTeamMeeting from './Connected/TeamMeeting/ListingTeamMeeting';
import CreateTeamMeeting from './Connected/TeamMeeting/CreateTeamMeeting';
import ItemTeamMeeting from './Connected/TeamMeeting/ItemTeamMeeting';
import EditTeamMeeting from './Connected/TeamMeeting/EditTeamMeeting';
import ListingFAQ from './Connected/FAQ/ListingFAQ';
import QuestionFAQ from './Connected/FAQ/QuestionFAQ';
import HomeConnectedUser from './Connected/homepage/HomeConnectedUser';
import Account from './Connected/Account/Account';
import EduAgenda from './Connected/Account/EduAgenda';
import Goals from './Connected/Account/Goals';
import CreateNews from './Connected/News/CreateNews';
import ItemNews from './Connected/News/ItemNews';
import EditNews from './Connected/News/EditNews';
import ListingNews from './Connected/News/ListingNews';
import SuccessFull from './Auth/SuccessFull';
import ResetPassword from './ResetPassword/ResetPassword';
import Cgu from './Pages/Cgu';
import Mentions from './Pages/Mentions';
import Cnil from './Pages/Cnil';
import EvolutionScreen from './Connected/Notifications/EvolutionScreen';
import JDScreen from './Connected/Notifications/JDScreen';
import NewsScreen from './Connected/Notifications/NewsScreen';
import ObjectifsScreen from './Connected/Notifications/ObjectifsScreen';
import AdhesionScreen from './Connected/Notifications/AdhesionScreen';
import WorkshopScreen from './Connected/Notifications/WorkshopScreen';
import ContactApp from './Pages/ContactApp';
import TrainingPlan from './Connected/TrainingPlan/TrainingPlan';
import Facture from './Connected/Account/Facture';
import ItemFacture from './Connected/Account/ItemFacture';
import FacturePDF from '../components/Factures/FacturePDF';
import ItemFacturePreview from '../components/Factures/ItemFacturePreview';
import CreditHistoryPreview from '../components/Admin/CreditHistoryPreview';
import AdminSettings from '../components/Admin/AdminSettings';
import TraningPlanUser from './Connected/TrainingPlan/TrainingPlanUser';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import AdminScreen from './Connected/Admin';
import Status from "./Pages/Status";
import Contact from "./Connected/Contact";
import CategoryFaq from "./Connected/FAQ/CategoryFaq";
import Rapport from "./Connected/Rapport/Rapport";
import Out from "./Out";
import {
  Elements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { REACT_APP_PAYPAL_CLIENT_ID } from "../env";

function App() {
  useAuth().setup();
  // @ts-ignore
  const [isAuthenticated, setIsAuthenticated] = useState(
    useAuth().isAuthenticated()
  );



  /*
    const [user, setUser] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const restoreUser = async () => {
        const userToken = storage.getUser();
        console.log(userToken);
        if (userToken && storage.isAuthValid()) {
            // @ts-ignore
            const userInfoResponse = await users.infos(userToken.id);
            // @ts-ignore
            if(userInfoResponse.ok) {
                // @ts-ignore
                setUser(new User(
                    // @ts-ignore
                    userInfoResponse?.data?.id,
                    // @ts-ignore
                    userInfoResponse.data.lastName,
                    // @ts-ignore
                    userInfoResponse.data.firstName,
                    // @ts-ignore
                    userInfoResponse.data.civilite,
                    // @ts-ignore
                    userInfoResponse.data.email,
                    // @ts-ignore
                    userInfoResponse.data.phoneNumber,
                    // @ts-ignore
                    userInfoResponse.data.godFather
                ));
            }
        }
    };

    if(!isReady) {
        restoreUser().then(() => setIsReady(true));
    }
*/
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <NotificationsProvider autoClose={7000} position="top-right">
  
        <PayPalScriptProvider
          options={{
            'client-id': REACT_APP_PAYPAL_CLIENT_ID,
            components: 'buttons',
            currency: 'EUR',
           "disable-funding": "credit,card"
          }}
        >
          <ModalsProvider>
            <HashRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/auth/cgu" element={<Cgu />} />
                <Route path="/cnil" element={<Cnil />} />
                <Route path="/mentions-legales" element={<Mentions />} />
                <Route path="/contact" element={<ContactApp />} />
                <Route path="/reset/password" element={<ResetPassword />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/register/success" element={<SuccessFull />} />
                <Route path="/simulation" element={<LayoutSimulation />} >
                  <Route index element={<AdhesionPaymentSimulation />} />
                  <Route path="/simulation/adhesion" element={<AdhesionPaymentSimulation />} />
                  <Route path="/simulation/atelier/:id" element={<WorkshopPaymentSimulation />} />
                  <Route path="/simulation/formation/:id" element={<FormationPaymentSimulation />} />
                  <Route path="/simulation/evenement/:id" element={<EventPaymentSimulation />} />
                </Route>
                <Route path="/auth" element={<HomeConnected />} >
                  <Route path="/auth/home" element={<HomeConnectedUser />} />
                  <Route path="/auth/status" element={<Status />} />
                  <Route path="/auth/admin" element={<AdminScreen />} />
                  <Route path="/auth/admin/user/:user_id/avoirs/:year" element={<CreditHistoryPreview />} />
                  <Route path="/auth/admin/user/:user_id/facture/:facture_id" element={<ItemFacturePreview />} />
                  <Route path="/auth/expert-comptable" element={<AdminScreen />} />
                  <Route path="/auth/expert-comptable/user/:user_id/avoirs/:year" element={<CreditHistoryPreview />} />
                  <Route path="/auth/expert-comptable/user/:user_id/facture/:facture_id" element={<ItemFacturePreview />} />
                  <Route path="/auth/workshop" element={<ListingWorkshop />} />
                  <Route
                    path="/auth/formation"
                    element={<ListingFormation />}
                  />
                  <Route path="/auth/event" element={<ListingEvent />} />
                  <Route path="/auth/news" element={<ListingNews />} />
                  <Route path="/auth/evolution" element={<EvolutionScreen />} />
                  <Route path="/auth/FAQ" element={<ListingFAQ />} />
                  <Route
                    path="/auth/TeamMeeting"
                    element={<ListingTeamMeeting />}
                  />
                  <Route
                    path="/auth/FAQ/issue/:id"
                    element={<QuestionFAQ />}
                  />
                  <Route
                      path="/auth/FAQ/category/:id"
                      element={<CategoryFaq />}
                  />
                  <Route
                    path="/auth/workshop/create"
                    element={<CreateWorkshop />}
                  />
                  <Route path="/auth/news/create" element={<CreateNews />} />
                  <Route
                    path="/auth/formation/create"
                    element={<CreateFormation />}
                  />
                  <Route path="/auth/event/Create" element={<CreateEvent />} />
                  <Route
                    path="/auth/TeamMeeting/Create"
                    element={<CreateTeamMeeting />}
                  />
                  <Route
                    path="/auth/formation/detail/:id"
                    element={<ItemFormation />}
                  />
                  <Route
                    path="/auth/event/detail/:id"
                    element={<ItemEvent />}
                  />
                  <Route
                    path="/auth/workshop/detail/:id"
                    element={<ItemWorkshop />}
                  />
                  <Route path="/auth/news/detail/:id" element={<ItemNews />} />
                  <Route
                    path="/auth/TeamMeeting/detail/:id"
                    element={<ItemTeamMeeting />}
                  />
                  <Route
                    path="/auth/workshop/edit/:id"
                    element={<EditWorkshop />}
                  />
                  <Route
                    path="/auth/formation/edit/:id"
                    element={<EditFormation />}
                  />
                  <Route path="/auth/event/edit/:id" element={<EditEvent />} />
                  <Route path="/auth/news/edit/:id" element={<EditNews />} />
                  <Route
                    path="/auth/TeamMeeting/edit/:id"
                    element={<EditTeamMeeting />}
                  />
                  <Route
                    path="/auth/adherents/profil/:id"
                    element={<Account />}
                  />
                  <Route
                      path="/auth/adherents/profil/rapports"
                      element={<Rapport />}
                  />
                  <Route
                    path="/auth/adherents/profil/agenda"
                    element={<EduAgenda />}
                  />
                  <Route
                    path="/auth/adherents/profil/goals/:id"
                    element={<Goals />}
                  />
                  <Route path="/auth/ContactApp" element={<Contact />} />
                  <Route path="/auth/JDs" element={<JDScreen />} />
                  <Route
                    path="/auth/notifications/news"
                    element={<NewsScreen />}
                  />
                  <Route
                    path="/auth/notifications/objectifs"
                    element={<ObjectifsScreen />}
                  />
                  <Route
                    path="/auth/notifications/adhesion"
                    element={<AdhesionScreen />}
                  />
                  <Route
                    path="/auth/notifications/workshop"
                    element={<WorkshopScreen />}
                  />
                  <Route
                    path="/auth/TrainingPlan/TrainingPlan/:id"
                    element={<TrainingPlan />}
                  />
                  <Route
                    path="/auth/adherents/profil/factures"
                    element={<Facture />}
                  />
                  <Route
                    path="/auth/adherents/profil/facture/:id"
                    element={<ItemFacture />}
                  />
                  <Route
                    path="/auth/adherents/profil/facture/:id/pdf"
                    element={<FacturePDF />}
                  />
                  <Route
                    path="/auth/adherents/profil/facture/AdminTva"
                    element={<AdminSettings />}
                  />
                  <Route
                    path="/auth/TrainingPlanUser/:id"
                    element={<TraningPlanUser />}
                  />
                  <Route
                    path="/auth/adherents/profil/:id"
                    element={<Account />}
                  />
                </Route>
              </Routes>
            </HashRouter>
          </ModalsProvider>
        </PayPalScriptProvider>
      
      </NotificationsProvider>
    </AuthContext.Provider>
  );
}

export default App;
