import React, { useEffect, useState } from 'react';
import Card from './Card';
import HomeCardEvent from '../../event/HomeCardEvent';
import { Link } from 'react-router-dom';
import {Button, SimpleGrid, Text} from '@mantine/core';
import useApi from '../../../hooks/useApi';
import workshop from '../../../api/workshop';
import thematic from '../../../api/thematic';
import HomeCardWorkshop from '../../workshop/HomeCardWorkshop';
import users from '../../../api/users';
import storage from '../../../auth/storage';
import {useModals} from "@mantine/modals";
import HomeAdviceWorkshop from "../../workshop/HomeAdviceWorkshop";
interface IWorkshopEventSectionProps {
  adviseThematics: any[];
  dataWokshop: any[];
  dataEvent: any[];
}

const WorkshopEventSection = (props: IWorkshopEventSectionProps) => {
  const [isSend, setIsSend] = useState<Boolean>(false);
  const modals = useModals();
  const { request } = useApi(workshop.getWorkshopByTitle);
  const [workshopAdvise, setWorkshopAdvise] = useState<any[]>([]);
  const [workshops, setWorkshops] = useState<any[]>([]);
  const { request: requestSendMail } = useApi(users.sendMailTrainingPlan);
  useEffect(() => {
    setWorkshops(props.dataWokshop.slice(0, 6));
  }, [props.dataWokshop]);

  useEffect(() => {
    const tab: any[] = [];
    if (props.adviseThematics.length > 0) {
      props.adviseThematics.forEach((adviseThematic: any) => {
        request(adviseThematic.wording).then((data: any) => {
          if (data?.data.length > 0 ) {
            tab.push(data.data[0]);
          }
        });
      });
      setWorkshopAdvise(tab);
    }
  },[props.adviseThematics])

  const handleCLick = () => {
    storage.store('send', 'send');
    requestSendMail();
    setIsSend(true);
  };

  const confirm = () => {
    const id = modals.openModal({
      title: "Plan de formation personnalisé",
      transition: "fade",
      transitionDuration: 600,
      transitionTimingFunction: "ease",
      children: (
          <>
            <Text size="sm" className="my-5">
              Vous êtes sur le point de demander un plan de formation à votre parrain.
              Il sera prévenu avec un email.
            </Text>
            <SimpleGrid cols={2}>
              <Button
                  color="dark"
                  fullWidth
                  onClick={() => modals.closeModal(id)}
              >
                Annuler
              </Button>
              <Button
                  variant="default"
                  fullWidth
                  onClick={() => {
                    handleCLick();
                    modals.closeModal(id);
                  }}
              >
                Confirmer
              </Button>
            </SimpleGrid>
          </>
      ),
    });
  };

  return (
    <>
      {props && props.adviseThematics.length == 0 ? (
        <div className="w-full mt-4 md:mt-0">
          <h2 className="text-s21 uppercase text-left mb-5">
            Prochains ateliers
          </h2>
          <div
            className="overflow-hidden "
            style={{ boxShadow: '0px 1px 6px #8E8E8E59' }}
          >
            {workshops.length ? (
              workshops.map((workshop: any) => (
                <HomeCardWorkshop data={workshop} />
              ))
            ) : (
              <div className="w-full flex items-center p-4">
                <div className="w-full text-left text-sm font-medium text-gray-600">
                  Aucun ateliers trouvé
                </div>
              </div>
            )}
          </div>
          <div className="flex md:flex-col lg:flex-row justify-between my-5">
            <Link to="">
              {isSend === false ? (
                <Button
                  onClick={confirm}
                  color="blue"
                  size="xs"
                  disabled={!!storage.get('send')}
                  className="text-ten mb-2 lg:mb-0"
                  style={{ background: '#256094' }}
                >
                  Plan de formation personnalisé
                </Button>
              ) : (
                <Button
                  disabled
                  color="gray"
                  size="xs"
                  className="text-ten mb-2 lg:mb-0"
                  style={{ background: '#808080' }}
                >
                  En Attente Plan de formation personnalisé
                </Button>
              )}
            </Link>
            <Link to="/auth/workshop">
              <Button color="gray" size="xs" className="text-ten">
                Voir tous les ateliers
              </Button>
            </Link>
          </div>
          <h2 className="text-s21 text-left mt-10 uppercase mb-5">Évenements</h2>
          <div className="rounded-lg" style={{ boxShadow: '0px 1px 6px #8E8E8E59' }}>
            {props.dataEvent.length ? (
              props.dataEvent
                .slice(0, 2)
                .map((event: any) => <HomeCardEvent data={event} />)
            ) : (
              <div className="w-full flex items-center p-4">
                <div className="w-full text-left text-sm font-medium text-gray-600">
                  Aucun évènement trouvé
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end my-5">
            <Link to="/auth/event">
              <Button
                onClick={handleCLick}
                color="gray"
                size="xs"
                className="text-ten"
              >
                Voir tous les évenements
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="w-full mt-4 md:mt-0">
          <h2 className=" text-s21 uppercase text-left mb-5">
            Ateliers recommandés
          </h2>
          <div
            className="rounded overflow-hidden"
            style={{ boxShadow: '0px 1px 6px #8E8E8E59' }}
          >
            {workshopAdvise.length ? (
              workshopAdvise.map((workshop: any) => (
                <HomeAdviceWorkshop data={workshop} />
              ))
            ) : (
              <div className="w-full flex items-center p-4">
                <div className="w-full text-left text-sm font-medium text-gray-600">
                  Aucun ateliers trouvé
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end my-5">
            <Link to="/auth/workshop">
              <Button color="gray" size="xs" className="text-ten">
                Voir tous les ateliers
              </Button>
            </Link>
          </div>
          <h2 className=" text-s21 uppercase text-left mt-10 mb-5">Évenements</h2>
          <div
            className=" rounded"
            style={{ boxShadow: '0px 1px 6px #8E8E8E59' }}
          >
            {props.dataEvent.length ? (
              props.dataEvent
                .slice(0, 2)
                .map((event: any) => <HomeCardEvent data={event} />)
            ) : (
              <div className="w-full flex items-center p-4">
                <div className="w-full text-left text-sm font-medium text-gray-600">
                  Aucun évènement trouvé
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end my-5">
            <Link to="/auth/event">
              <Button color="gray" size="xs" className="text-ten">
                Voir tous les évenements
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkshopEventSection;
