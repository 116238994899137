import React from "react";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Checkbox,
  MultiSelect,
  Select,
  Table,
} from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import settings from "../../api/settings";
import { useEffect, useState } from "react";
import transactions from "../../api/transactions";
import { ImCross, BsPlusLg } from "react-icons/all";
import { AiOutlineDownload } from "react-icons/ai";
import { IoEyeSharp } from "react-icons/io5";
import FacturePDF from "./FacturePDF";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  Table as TablePDF,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";
import isAdhesionPayment from "../../utility/isAdhesionPayment";
import AdminSettings from "../Admin/AdminSettings";
import Pagination from "@mui/material/Pagination";
import users from "../../api/users";
import storage from "../../auth/storage";
import getSiret from "../../utility/getSiret";
import {
    getPriceHTBase,
    getPriceTVABase,
    getNumberTVA,
} from "../../utility/tva";
import { format_dddd_d_MM_YYYY, format_ddMMYYYY } from "../../utility/dateUtility";
import { REACT_APP_API_BASE_URL } from "../../env";

const FactureCard = () => {
  const user: any = storage.getUser();
  const navigation = useNavigate();
  const { data: dataUsers, request: requestDataUsers } = useApi(users.getUserById);
  // @ts-ignore
  const { request: transactionGetDetails } = useApi(transactions.getDetails);
  // @ts-ignore
  console.log({transactionGetDetails, transactions})
  const { data: dataTransaction, request: requestTransaction } = useApi(
    transactions.getAllByUser,
  );
  const [loading, setLoading] = useState(false);
  const [dateFactureSelected, setDateFactureSelect] = useState("");
  const [dateFactureSelectedYear, setDateFactureSelectYear] = useState("");
  const { data: dataTVA, request: requestTva } = useApi(settings.getTva);
  const idTva = 1;
  const [activePage, setPage] = useState(1);
  const perPage = 10;
  const [dataDoc, setDataDoc] = useState<any>();
  const [docIndex, setDocIndex] = useState(0);
  // @ts-ignore
  const [transactionDetails, settransactionDetails] = useState({});
  let filter = dataTransaction;

  filter = dataTransaction.filter((w: any) => {
    if (
      (dateFactureSelected
        ? new Date(w.createAt).getMonth() === parseInt(dateFactureSelected)
        : true) &&
      (dateFactureSelectedYear
        ? new Date(w.createAt).getFullYear() ===
          parseInt(dateFactureSelectedYear)
        : true)
    ) {
      return w;
    }
  });

  useEffect(() => {
    requestDataUsers(user.id).then((r) => {
        console.log(r)
      if (r.data.id != user.id) {
        navigation("/");
      } else {
        requestTransaction(user.id);
        requestTva(idTva);
      }
    });
  }, []);

  const getPriceHT = (price: string, tva: number, itemName: string) => {
    if (isAdhesionPayment(itemName)) {
      return parseInt(price);
    }
    return getPriceHTBase(price, tva);
  };

  const getPriceTVA = (price: string, tva: number, itemName: string) => {
    if (isAdhesionPayment(itemName)) {
      return 0;
    }
    return getPriceTVABase(price, tva);
  };
  // const MyDocPDF =
/*
    {dataTransaction?.itemName != "renouvellement cotisation Forman"? dataTransaction.workshop ?"N°"+dataTransaction?.workshop?.id +","+ dataTransaction?.itemName + " le " + new Date(dataTransaction?.workshop?.startDate).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    }) ?? "" :"N°"+ dataTransaction?.formation?.id +","+ dataTransaction?.itemName + " le " + new Date(dataTransaction?.formation?.startDate).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    }) ?? "" : "renouvellement cotisation Forman"}
    {dataTransaction?.itemName != "Adhésion Forman"? dataTransaction.workshop ?"N°"+dataTransaction?.workshop?.id +","+ dataTransaction?.itemName + " le " + new Date(dataTransaction?.workshop?.startDate).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    }) ?? "" :"N°"+ dataTransaction?.formation?.id +","+ dataTransaction?.itemName + " le " + new Date(dataTransaction?.formation?.startDate).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    }) ?? "" : "Adhésion Forman"}
    */


  //     <Document>
  //         {console.log("pdf :",dataDoc)}
  //             <Page size='A4'  style={{backgroundColor:'#E8E8E8',display:'flex', flexDirection:'column'}}>
  //                 <View style={{marginTop:'10px',marginLeft:'10px'}} >
  //                     <Text>
  //                         FACTURE N° {dataDoc?.id}
  //                     </Text>
  //                     <Text style={{fontSize:'15'}}>
  //                         {new Date(dataDoc?.createAt).toLocaleDateString('fr-FR', {
  //                             year: 'numeric',
  //                             month:'numeric',
  //                             day: 'numeric',
  //                         })}

  //                     </Text>
  //                 </View>

  //                 <View  style={{marginTop:'10px',marginLeft:'10px', fontSize:'10px'}}>
  //                     <Text>Association Forman 1901</Text>
  //                     <Text>62 rue de L'Europe</Text>
  //                     <Text>78140 Vélizy-VillaCoublay1</Text>
  //                 </View>
  //                 <View style={{textAlign:'right', fontSize:'10px' , marginRight:'10px'}}>
  //                         <Text>Adresse de facturation Client :</Text>
  //                         <Text>{dataUsers?.lastName} {dataUsers?.firstName}{dataUsers?.society && " "+dataUsers?.society}</Text>
  //                         <Text>{dataUsers?.address}</Text>
  //                         <Text>{dataUsers?.city} {dataUsers?.postalCode}</Text>
  //                 </View>
  //                 <View style={{marginTop:'100', marginLeft:'10px', marginRight:'10px', backgroundColor:'gray', fontSize:'2px'}}>
  //                     <TablePDF >
  //                         <TableHeader textAlign='center' fontSize='12px' >
  //                             <TableCell textAlign='center'>Quantité</TableCell>
  //                             <TableCell textAlign='center'>Description</TableCell>
  //                             <TableCell textAlign='center'>Prix Unitaire (euros HT)</TableCell>
  //                             <TableCell textAlign='center'>Montant (euros HT)</TableCell>
  //                         </TableHeader>
  //                     </TablePDF>
  //                 </View>
  //                 <View style={{marginLeft:'10px', marginRight:'10px'}}>
  //                     <TablePDF>
  //                     <TableHeader textAlign='center' fontSize='12px'>
  //                             <TableCell style={{paddingTop:'5',paddingBottom:'5'}} >{dataDoc?.quantity}</TableCell>
  //                             <TableCell style={{paddingTop:'5',paddingBottom:'5'}} >{dataDoc?.itemName}</TableCell>
  //                             <TableCell style={{paddingTop:'5',paddingBottom:'5'}} >{getPriceHT(dataDoc?.total,dataTVA?.valueTVA)}</TableCell>
  //                             <TableCell style={{paddingTop:'5',paddingBottom:'5'}} >{getPriceHT(dataDoc?.total,dataTVA?.valueTVA)}</TableCell>
  //                     </TableHeader>
  //                     </TablePDF>
  //                     <TablePDF>
  //                     <TableHeader textAlign='center' fontSize='12px'>
  //                             <TableCell></TableCell>
  //                             <TableCell></TableCell>
  //                             <TableCell style={{paddingTop:'5',paddingBottom:'5'}} >TVA</TableCell>
  //                             <TableCell style={{paddingTop:'5',paddingBottom:'5'}} >{dataTVA.valueTVA + " %"}</TableCell>
  //                     </TableHeader>
  //                     </TablePDF>
  //                     <TablePDF>
  //                     <TableHeader textAlign='center' fontSize='12px'>
  //                             <TableCell></TableCell>
  //                             <TableCell></TableCell>
  //                             <TableCell style={{paddingTop:'5',paddingBottom:'5'}}>TOTAL (TTC) </TableCell>
  //                             <TableCell style={{paddingTop:'5',paddingBottom:'5'}}>{dataDoc?.total}</TableCell>
  //                     </TableHeader>
  //                     </TablePDF>
  //                     <View style={{fontSize:'10px',marginTop:'10px'}}>
  //                         <Text> TVA non applicable,article 261 du CGI</Text>
  //                         <Text> indemnité forfaitaire pour frais de recouvrement de 40 euros</Text>
  //                     </View>
  //                 </View>
  //             </Page>
  //     </Document>


  // @ts-ignore
  useEffect(() => {
    // @ts-ignore
    let ids = []
    // @ts-ignore
    if(filter.length){
      // @ts-ignore
      ids = filter.slice(activePage * perPage - perPage, perPage * activePage).map((item) =>{
        // @ts-ignore
        return item.id
      })
    }
    // @ts-ignore
    if(ids.length){
      // @ts-ignore
      transactionGetDetails({transactionIds:ids}).then((r) => {
        console.log({r})
        // @ts-ignore
        settransactionDetails(r?.data ? r.data : {})
      });
    }
  }, [activePage, dataTransaction]);

  // affiché seulement les facture ou moi = value du Select
  const rows = filter.length ? (
    filter
      .slice(activePage * perPage - perPage, perPage * activePage)
      .map((item: any, index:number) => {

          const paymentMethod = (item?.paymentMethod == 'PAYMENT_BY_CREDIT') ? '\n(Paiement par crédit)' : ''
          const startDate = format_dddd_d_MM_YYYY(item?.workshop ? item?.workshop.startDate : item?.formation ? item?.formation.startDate : new Date())
          return (
              <tr key={index} className="hover:bg-white cursor-pointer">
                  <td>
                      <Checkbox/>
                  </td>
                  <td>
                      {format_ddMMYYYY(item.createAt)}
                  </td>
                  <td>{item.id}</td>
                  <td>{item.total}
                  </td>
                  <td>
                      <div className=" flex flex-row bg-transparent justify-end items-center text-2xl">
                        {item.chorusProId ? (
                          <a href={`${REACT_APP_API_BASE_URL}/api/chorus-pro/get-invoice/${item.id}`}>
                              <button>
                                  <AiOutlineDownload/>
                              </button>
                          </a>
                        ) : (
                          <PDFDownloadLink
                              document={
                                  <Document>
                                      <Page
                                          size="A4"
                                          style={{
                                              backgroundColor: "#FFF",
                                              display: "flex",
                                              flexDirection: "column",
                                          }}
                                      >
                                          <View style={{marginTop: 10, marginLeft: 10}}>
                                              <Text>FACTURE N° {item?.id}</Text>
                                              <Text style={{fontSize: 15}}>
                                                  {format_ddMMYYYY(item?.createAt)}
                                              </Text>
                                          </View>

                                          <View
                                              style={{
                                                  marginTop: 10,
                                                  marginLeft: 10,
                                                  fontSize: 10,
                                              }}
                                          >
                                              <Text>Association Forman </Text>
                                              <Text>2 bis rue Léon Blum </Text>
                                              <Text>78350 JOUY-EN-JOSAS</Text>
                                          </View>

                                          <View
                                              style={{
                                                  textAlign: "right",
                                                  fontSize: 10,
                                                  marginRight: 10,
                                                  marginBottom: 10,
                                              }}
                                          >
                                              <Text>Identifiant Client :</Text>
                                              <Text>{dataUsers?.id}</Text>
                                          </View>

                                          <View
                                              style={{
                                                  textAlign: "right",
                                                  fontSize: 10,
                                                  marginRight: 10,
                                              }}
                                          >
                                              <Text>Adresse de facturation Client :</Text>
                                              <Text>
                                                  {dataUsers?.userInformations?.societyName ? dataUsers?.userInformations?.societyName : dataUsers?.lastName + " " + dataUsers?.firstName}
                                              </Text>
                                              <Text>{dataUsers?.address}</Text>
                                              <Text>
                                                  {dataUsers?.city} {dataUsers?.postalCode}
                                              </Text>
                                          </View>
                                          <View
                                              style={{
                                                  marginTop: 100,
                                                  marginLeft: 10,
                                                  marginRight: 10,
                                                  backgroundColor: "gray",
                                                  fontSize: 12,
                                              }}
                                          >
                                              <TablePDF>
                                                  <TableHeader textAlign="center" fontSize="12">
                                                      <TableCell textAlign="center">Quantité</TableCell>
                                                      <TableCell textAlign="center">
                                                          Description
                                                      </TableCell>
                                                      <TableCell textAlign="center">
                                                          Prix Unitaire (euros HT)
                                                      </TableCell>
                                                      <TableCell textAlign="center">
                                                          Montant (euros HT)
                                                      </TableCell>
                                                  </TableHeader>
                                              </TablePDF>
                                          </View>
                                          <View style={{marginLeft: 10, marginRight: 10}}>
                                              <TablePDF>
                                                  <TableHeader textAlign="center" fontSize="12">
                                                      <TableCell
                                                          style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                          {item.quantity}
                                                      </TableCell>
                                                      <TableCell
                                                          style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                          {(item.itemName ?? "" + startDate) + paymentMethod}
                                                      </TableCell>
                                                      <TableCell
                                                          style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                          {getPriceHT(item?.total, dataTVA?.valueTVA, item?.itemName)}
                                                      </TableCell>
                                                      <TableCell
                                                          style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                          {getPriceHT(item?.total, dataTVA?.valueTVA, item?.itemName)}
                                                      </TableCell>
                                                  </TableHeader>
                                              </TablePDF>
                                              <TablePDF>
                                                  <TableHeader textAlign="center" fontSize="12">
                                                      <TableCell></TableCell>
                                                      <TableCell
                                                          style={{paddingTop: 5, paddingBottom: 5}}
                                                      >{startDate}</TableCell>
                                                      <TableCell
                                                          style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                          <Text> {"TVA"} ({isAdhesionPayment(item?.itemName) ? 0 : dataTVA.valueTVA ?? 0}{" %"})</Text>
                                                      </TableCell>
                                                      <TableCell
                                                          style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                          <Text> {getPriceTVA(item?.total, dataTVA?.valueTVA, item?.itemName)}</Text>
                                                      </TableCell>
                                                  </TableHeader>
                                              </TablePDF>
                                              <TablePDF>
                                                  <TableHeader textAlign="center" fontSize="12">
                                                      <TableCell
                                                        style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                        Date de paiement
                                                      </TableCell>
                                                      <TableCell
                                                        style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                        {new Date(item?.createAt).toLocaleDateString(
                                                            "fr-FR",
                                                            {
                                                                year: "numeric",
                                                                month: "numeric",
                                                                day: "numeric",
                                                            },
                                                        )}
                                                      </TableCell>
                                                      <TableCell
                                                          style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                          <Text> {"TOTAL (TTC)"}</Text>
                                                      </TableCell>
                                                      <TableCell
                                                          style={{paddingTop: 5, paddingBottom: 5}}
                                                      >
                                                          <Text>{item?.total}</Text>
                                                      </TableCell>
                                                  </TableHeader>
                                              </TablePDF>
                                              <View style={{fontSize: 10, marginTop: 10}}>
                                                  {isAdhesionPayment(item?.itemName) ? <Text>{" TVA non applicable,article 261 du CGI"}</Text> : <></>}
                                                  <Text>

                                                      {"indemnité forfaitaire pour frais de recouvrement de 40 euros"}
                                                  </Text>


                                                  {(() =>{
                                                    // @ts-ignore
                                                    return Object.keys(transactionDetails).map((id, index) =>{
                                                      // @ts-ignore
                                                      if(id == item.id){
                                                        // @ts-ignore
                                                        let credits = transactionDetails[id]
                                                        // @ts-ignore
                                                        let r = []
                                                        // @ts-ignore
                                                        if(credits.length){
                                                          // @ts-ignore
                                                          r.push(
                                                            <Text style={{fontSize: 14}} key={`credit-${id}-00-${index}`}>
                                                              Avoirs : 
                                                            </Text>
                                                          )
                                                        }
                                                        // @ts-ignore
                                                        r.push(credits.map((credit, index) =>{
                                                          // @ts-ignore
                                                          var { amount, credit_id } = credit;
                                                          // @ts-ignore
                                                          return (
                                                            <Text style={{fontSize: 13}} key={`credit-${id}-${credit_id}-${index}`}>
                                                              - N° {credit_id} :  {amount} euro{amount > 1 ? 's' : ''}
                                                            </Text>
                                                          )
                                                        }))
                                                        return r
                                                      }
                                                      return null;
                                                    })
                                                  })()}
                                              </View>
                                          </View>
                                          <View style={{fontSize: 10, width: "100%", textAlign: "center", position: "absolute", left: 0, bottom: 10}}>
                                              <Text>Facture n° {item?.id} - Association Forman - Siret : {getSiret()} - TVA n° : {getNumberTVA()}</Text>
                                          </View>
                                      </Page>
                                  </Document>
                              }
                              fileName={`Facture Forman n°${item.id}.pdf`}
                          >
                              {({blob, url, loading, error}) =>
                                  loading ? (
                                      <button>Loading PDF.. </button>
                                  ) : (
                                      <button>
                                          <AiOutlineDownload/>
                                      </button>
                                  )
                              }
                          </PDFDownloadLink>
                          )}
                          <Link to={`/auth/adherents/profil/facture/${item.id}`}>
                              <Button
                                  className="bg-transparent text-2xl hover:bg-blue-200"
                                  radius="xl"
                              >
                                  <IoEyeSharp style={{color: "#1e3a8a"}}/>
                              </Button>
                          </Link>
                      </div>
                  </td>
              </tr>
          )}
      )
  ) : (
    <div className="p-3">
        <td>Aucune Facture </td>
    </div>
  );

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Mon Profil", href: `/auth/adherents/profil/${user.id}` },
    { title: "Mes Factures", href: `/auth/adherents/profil/factures` },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));
  return (
    <>
      <div className="w-screen md:w-auto px-6 py-2 md:p-5">
        <div className="ml-7">
            <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
                {items}
            </Breadcrumbs>
        </div>
      </div>
      <h1 className="mt-4 ml-11 md:mt-10 text-3xl uppercase">Mes factures</h1>
      <div className="w-full px-6 py-2 md:p-10 justify-items-center">
        <div className="flex flex-row gap-2 justify-end mb-6">
          Filtrez :
          <Select
            data={[
              { value: "", label: "tous les mois" },
              { value: "0", label: "Janvier" },
              { value: "1", label: "Février" },
              { value: "2", label: "Mars" },
              { value: "3", label: "Avril" },
              { value: "4", label: "Mai" },
              { value: "5", label: "Juin" },
              { value: "6", label: "Juillet" },
              { value: "7", label: "Aout" },
              { value: "8", label: "Septembre" },
              { value: "9", label: "Octobre" },
              { value: "10", label: "Novrembre" },
              { value: "11", label: "Decembre" },
            ]}
            value={dateFactureSelected}
            clearable
            onChange={(value: string) =>
              setDateFactureSelect(value === null ? "" : value)
            }
          />
          <Select
            data={[
              { value: "", label: "Toutes les Années" },
              { value: "2020", label: "2020" },
              { value: "2021", label: "2021" },
              { value: "2022", label: "2022" },
              { value: "2023", label: "2023" },
              { value: "2024", label: "2024" },
              { value: "2025", label: "2025" },
              { value: "2026", label: "2026" },
              { value: "2027", label: "2027" },
              { value: "2028", label: "2028" },
              { value: "2029", label: "2029" },
              { value: "2030", label: "2030" },
              { value: "2031", label: "2031" },
              { value: "2032", label: "2032" },
              { value: "2033", label: "2033" },
              { value: "2034", label: "2034" },
              { value: "2035", label: "2035" },
              { value: "2036", label: "2036" },
              { value: "2037", label: "2037" },
              { value: "2038", label: "2038" },
              { value: "2039", label: "2039" },
              { value: "2040", label: "2040" },
              { value: "2041", label: "2041" },
              { value: "2042", label: "2042" },
              { value: "2043", label: "2043" },
              { value: "2044", label: "2044" },
              { value: "2045", label: "2045" },
              { value: "2046", label: "2046" },
              { value: "2047", label: "2047" },
              { value: "2048", label: "2048" },
              { value: "2049", label: "2049" },
              { value: "2050", label: "2050" },
              { value: "2051", label: "2051" },
              { value: "2052", label: "2052" },
              { value: "2053", label: "2053" },
              { value: "2054", label: "2054" },
              { value: "2055", label: "2055" },
              { value: "2056", label: "2056" },
              { value: "2057", label: "2057" },
              { value: "2058", label: "2058" },
              { value: "2058", label: "2058" },
              { value: "2060", label: "2060" },
            ]}
            value={dateFactureSelectedYear}
            onChange={(value: string) => {
              setDateFactureSelectYear(value === null ? "" : value);
            }}
            clearable
          />
          <Button>
            <AiOutlineDownload />
          </Button>
        </div>
        <div>
          <Table
            horizontalSpacing="md"
            verticalSpacing="md"
            className="text-3xl bg-white rounded shadow-xl "
            style={{ backgroundColor: "#FFF" }}
          >
            <thead className="text-3xl  border-b-4">
              <tr className="border align-middle">
                <th></th>
                <th>Date</th>
                <th>Numéro Facture</th>
                <th>Prix TTC</th>
              </tr>
            </thead>
            <tbody className="text-3xl">{rows}</tbody>
          </Table>
        </div>
        {filter.length > perPage ? (
          <div className="flex justify-center md:justify-end p-4">
            <Pagination
              count={Math.ceil(filter.length / perPage)}
              page={activePage}
              onChange={(event, value) => setPage(value)}
              shape="rounded"
              color="primary"
              hidePrevButton
              hideNextButton
              showFirstButton
              showLastButton
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default FactureCard;
