import request from "./request";

const endpoint = "/sub_categories";
const endpoint_section = "/sections";
const endpoint_issue = "/issues";

const getCategorie = (id:number) => {
    return request.get(endpoint+"/"+id);
}

const getSections = () => {
    return request.get(endpoint_section);
}

const getIssue = (id: Number) => {
    return request.get(endpoint_issue+"/"+id);
}

const exportedApiFAQ = {
    getCategorie,getIssue,getSections
}

export default exportedApiFAQ;