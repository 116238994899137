import React, { useEffect, useState } from 'react';
import storage from "../../auth/storage";
import {User} from "../../entity/User";
import useApi from "../../hooks/useApi";
import users from "../../api/users";

interface Props {
    role: Array<string>;
    children: React.ReactNode | null;
    checkUser?: null | boolean;
    checkIsAuthor?: null | boolean;
    datas?: any | null;
}

const HasRoles = (props : Props) => {
    const {request:requestUser} = useApi(users.me)
    const [user,setUser] = useState<any>()
    useEffect(() => {
        requestUser().then((r) => {
            setUser(r?.data)
        })
    },[])

    if(props.checkIsAuthor){
        if(props.datas?.former && props.datas?.former.id == user?.id) return <React.Fragment>{props.children}</React.Fragment>;
        if(user?.id == 3) return <React.Fragment>{props.children}</React.Fragment>;
    }else{
        if(props.role.includes(user?.roles[0]))return <React.Fragment>{props.children}</React.Fragment>;
        if(user?.isPilot) return <React.Fragment>{props.children}</React.Fragment>;
        if(props.datas?.former && props.datas?.former.id == user?.id) return <React.Fragment>{props.children}</React.Fragment>;
    }

    return null;
};

export default HasRoles;
