import React, {useRef, useState} from 'react';
import {Stepper, Button, Group, TextInput, PasswordInput} from '@mantine/core';
import {useForm} from "@mantine/hooks";
import useApi from "../../hooks/useApi";
import usersApi from "../../api/users";
import {Link, useNavigate} from "react-router-dom";

const ResetPassword = () => {
    const {request} = useApi(
        usersApi.missPassword
    );
    const {request: requestReset} = useApi(
        usersApi.resetPassword
    );
    const buttonResendRef = useRef<HTMLButtonElement>(null);
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadingFinal, setLoadingFinal] = useState(false);
    const [code, setCode] = useState("");
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorFinal, setErrorFinal] = useState(false);
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const formPhone = useForm({
        initialValues: {
            phoneNumber: '',
        },

        validationRules: {
            phoneNumber: (value) => /^\d+$/.test(value) && value.length === 10,
        },
    });

    const formPass = useForm({
        initialValues: {
            phoneNumber: '',
            code: '',
            password: '',
            confirm_password: '',
        },

        validationRules: {
            password: (password, values) => password === values?.confirm_password,
            confirm_password: (confirm_password, values) => confirm_password === values?.password,
        },
    });

    const handleSubmitPhone = async (values: any) => {
        setLoading(true);
        console.log(values);
        await request(
            values.phoneNumber
        ).then(response => {
            if (response.ok) {
                setLoading(false);
                setErrorPhone(false)
                nextStep()
            }
            if (!response.ok) {
                setLoading(false);
                setErrorPhone(true)
                formPhone.setFieldError('phoneNumber', true)
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const handleSubmitPass = async () => {
        const check = formPass.validate();
        if(!check){
            formPass.setFieldError('password', true);
            formPass.setFieldError('confirm_password', true);
            return;
        }
        setLoadingFinal(true);
        await requestReset(
            {password: formPass.values.password, code: code, phone: formPhone.values.phoneNumber}
        ).then(response => {
            if (response.status === 200) {
                setLoadingFinal(false);
                nextStep();
                navigation('/', {replace: true})
            }
            if (response.status === 404) {
                setLoadingFinal(false);
                setErrorFinal(true)
            }
            setLoadingFinal(false);
        }).catch(() => {
            setLoadingFinal(false);
        });

    };

    const handleResend = async () => {
        await request(
            formPhone.values.phoneNumber
        ).then(response => {
            if (response.ok) {
                setLoading(false);
                setErrorPhone(false)
                // @ts-ignore
                buttonResendRef.current.disabled = true;
            }
        }).catch(() => {
            setLoading(false);
            // @ts-ignore
            buttonResendRef.current.disabled = true;
        });
    }

    return (
        <div className="h-screen items-center justify-center flex">
            <div className="container mx-auto shadow-lg px-10 p-10 my-10">
                <Link to="/">
                    <Button className="uppercase mb-5" color="gray" size="xs" variant="filled">
                        Retour
                    </Button>
                </Link>
                <Stepper active={active} breakpoint="sm">
                    <Stepper.Step color={errorPhone ? "red" : "blue"} loading={loading} label="Étape 1"
                                  description="Numéro de téléphone">
                        <TextInput className="col-span-2"
                                   required
                                   label="Téléphone"
                                   error={formPhone.errors.phoneNumber && 'Téléphone invalide !'}
                                   value={formPhone.values.phoneNumber}
                                   onChange={(event) => formPhone.setFieldValue('phoneNumber', event.currentTarget.value)}
                        />
                        <div className="mx-auto flex justify-center">
                            <Button disabled={formPhone.values.phoneNumber.length !== 10}
                                    className="mt-7 mx-auto px-10 justify-center" color="blue"
                                    onClick={() => handleSubmitPhone(formPhone.values)}
                            >Suivant</Button>
                        </div>
                    </Stepper.Step>
                    <Stepper.Step label="Étape 2" description="Code SMS">
                        <TextInput className="col-span-2"
                                   required
                                   label="Code reçu par SMS"
                                   value={code}
                                   onChange={(event) => setCode(event.currentTarget.value)}
                        />
                        <Button
                            ref={buttonResendRef}
                            className="mt-7 mx-auto px-10 justify-center" color="dark"
                            onClick={handleResend}>Renvoyez le sms</Button>
                        <div className="mx-auto flex justify-center">
                            <Button loading={loading} disabled={code.length !== 6}
                                    className="mt-7 mx-auto px-10 justify-center" color="blue"
                                    onClick={nextStep}>Suivant</Button>
                        </div>
                    </Stepper.Step>
                    <Stepper.Step loading={loadingFinal} color={errorFinal ? "red" : "blue"} label="Étape 3"
                                  description="Nouveau mot de passe">
                        <form onSubmit={formPass.onSubmit(() => handleSubmitPass())}>
                            <div className="grid grid-cols-2 gap-4">
                                <PasswordInput
                                    className="col-span-2"
                                    label="Mot de passe"
                                    error={formPass.errors.password && 'Mot de passe invalide !'}
                                    value={formPass.values.password}
                                    onChange={(event) => formPass.setFieldValue('password', event.currentTarget.value)}
                                    required
                                />
                                <PasswordInput
                                    className="col-span-2 "
                                    label="Confirmer Mot de passe"
                                    error={formPass.errors.confirm_password && 'Mot de passe invalide !'}
                                    value={formPass.values.confirm_password}
                                    onChange={(event) => formPass.setFieldValue('confirm_password', event.currentTarget.value)}
                                    required
                                />
                            </div>
                            <div className="mx-auto flex justify-center">
                                <Button loading={loadingFinal}
                                        disabled={formPass.values.password.length < 8 && formPass.values.confirm_password.length < 8}
                                        className="mt-7 mx-auto px-10 justify-center" color="pink"
                                        type="submit"
                                        onClick={() => handleSubmitPass()}
                                >Valider</Button>
                            </div>
                        </form>
                    </Stepper.Step>
                </Stepper>
            </div>
        </div>
    );
};

export default ResetPassword;
