import React, {useEffect} from 'react';

const Cnil = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container mx-auto p-10">
            <h1 className="text-center font-bold">
                COMMISSION NATIONAL D'INFORMATIQUE & LIBERTÉS
            </h1>
        </div>
    );
};

export default Cnil;
