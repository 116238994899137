import request from "./request";

const endpoint = "/news";

const post = (news: any) => {
  return request.post(endpoint, {
    title: news.title,
    category: news.category,
    destined: news.destined,
    description: news.description,
    author: "/api/users/" + news.id,
    publishAt: news.date,
    isValidate: news.isValidate,
    taggedUsers: news.taggedUsers,
  });
};

const edit = (news: any, id: Number) => {
  return request.put(endpoint + "/" + id, {
    title: news.title,
    category: news.category,
    destined: news.destined,
    description: news.description,
    publishAt: news.date,
    isValidate: news.isValidate,
    taggedUsers: news.taggedUsers,
  });
};

const validate = (id: Number) => {
  return request.put(endpoint + "/" + id, {
    isValidate: true,
  });
};

const notValidate = (id: Number) => {
  return request.put(endpoint + "/" + id, {
    isValidate: false,
  });
};

const collection = (role: string) => {
  return request.get(endpoint + "?destined=" + role + "&isValidate=1");
};

const get = (id: Number) => {
  return request.get(endpoint + "/" + id);
};

const remove = (id: Number) => {
  return request.delete(endpoint + "/" + id);
};

const image = (image: File, id: Number) => {
  const data = new FormData();
  // @ts-ignore
  data.append("file", image);
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return request.post(endpoint + "/" + id + "/image", data, {
    headers,
  });
};

const like = (id: Number) => {
  return request.post(endpoint + "/" + id + "/like");
};

const dislike = (id: Number) => {
  return request.post(endpoint + "/" + id + "/dislike");
};

const getNotValidate = () => {
  return request.get(endpoint + "?isValidate=0");
};

const exportedApiUsers = {
  post,
  collection,
  get,
  image,
  edit,
  validate,
  like,
  dislike,
  getNotValidate,
  notValidate,
  remove,
};

export default exportedApiUsers;
