import React, { useEffect, useState } from "react";
import { Anchor, Breadcrumbs, LoadingOverlay } from "@mantine/core";
import Item from "../../../components/Evolutions/Item";
import { Link } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import notifications from "../../../api/notifications";
import workshop from "../../../api/workshop";
import storage from "../../../auth/storage";
import ItemJd from "../../../components/JD/ItemJd";

const items = [
  { title: "Accueil", href: "/auth/home" },
  { title: "Ateliers", href: "/auth/workshop" },
  { title: "Journée Découverte", href: `/auth/JDS` },
  { title: "Notifications", href: `/auth/home` },
].map((item, index) => (
  <Anchor
    component={Link}
    to={item.href}
    key={index}
    style={{ color: "#333", fontSize: 14 }}
  >
    {item.title}
  </Anchor>
));

const JDScreen = () => {
  const user : any = storage.getUser() ?? null;
  console.log(user);
  const { id } = user; 
  const { data, request } = useApi(workshop.getWorkshopJourneeDecouverte);
  const [JD, setJD] = useState<any[]>([]);

  useEffect(() => {
    request(id).then((res: any) => {
      setJD(res.data);
    });
  }, []);

  return (
    <>
      <LoadingOverlay visible={false} />
      <div className="w-screen md:w-auto px-6 py-2 md:p-12 mb-28">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl ">
          <span className="font-bold text-xl">NOTIFICATIONS</span>
          <br />
          <span className="text-3xl">JOURNÉE DÉCOUVERTE</span>
        </div>
        <div className="mt-6 md:mt-10 text-xl ">
          <span className=" text-2xl">
            LISTE DES JOURNÉE DÉCOUVERTE AUXQUELLES VOUS DEVEZ PARTICIPER
          </span>
        </div>
        <div className="mt-3 md:mt-3 text-xl ">
          {JD.length > 0 &&
          JD.filter(
            (item: any) =>
              item.guests.filter((guest: any) => guest.godFather?.id === id)
                .length > 0,
          ).length > 0 ? (
            JD
            .map((workshop: any) => <ItemJd workshop={workshop} />)
          ) : (
            <span className="text-sm font-bold pt-36">
              Aucune journée découverte disponible
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default JDScreen;
