const title = "title"
const titleF = "titleF"
const place = "place"
const placeF = "placeF"
const placeTM = "placeTM"
const placeEvent = "placeEvent"
const date1 = "date1"
const date2 = "date2"
const date1TM = "date1TM"
const date2TM = "date2TM"
const date1F = "date1F"
const date2F = "date2F"
const date1Event = "date1Event"
const date2Event = "date2Event"

type storage = {
    date1: string,
    date2: string
}

const storeTitle = (props : string) => {
    try {
        window.localStorage.setItem(title, props);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}
const storeTitleF = (props : string) => {
    try {
        window.localStorage.setItem(titleF, props);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}
const storePlace = (props: string) => {
    try {
        window.localStorage.setItem(place, props);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}
const storePlaceF = (props: string) => {
    try {
        window.localStorage.setItem(placeF, props);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}
const storeDate = (props: storage) => {
    try {
        window.localStorage.setItem(date1, props.date1);
        window.localStorage.setItem(date2, props.date2);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}
const storeDateF = (props: storage) => {
    try {
        window.localStorage.setItem(date1F, props.date1);
        window.localStorage.setItem(date2F, props.date2);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}

const storePlaceTM = (props: string) => {
    try {
        window.localStorage.setItem(placeTM, props);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}

const storeDateTM = (props: storage) => {
    try {
        window.localStorage.setItem(date1TM, props.date1);
        window.localStorage.setItem(date2TM, props.date2);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}

const storePlaceEvent = (props: string) => {
    try {
        window.localStorage.setItem(placeEvent, props);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}

const storeDateEvent = (props: storage) => {
    try {
        window.localStorage.setItem(date1Event, props.date1);
        window.localStorage.setItem(date2Event, props.date2);
    } catch (error) {
        console.log('Erreur pendant la mise en mémoire des tokens', error);
    }
}


const get =  () => {
    const titleValue = window.localStorage.getItem(title);
    const placeValue = window.localStorage.getItem(place);
    const dateValue1 = window.localStorage.getItem(date1);
    const dateValue2 = window.localStorage.getItem(date2);
    const storeObject = {
        title: titleValue,
        place: placeValue,
        date1: dateValue1,
        date2: dateValue2
    }
    if (typeof storeObject === 'undefined') return null;
    return storeObject;
}

const getF =  () => {
    const titleValue = window.localStorage.getItem(titleF);
    const placeValue = window.localStorage.getItem(placeF);
    const dateValue1 = window.localStorage.getItem(date1F);
    const dateValue2 = window.localStorage.getItem(date2F);
    const storeObject = {
        title: titleValue,
        place: placeValue,
        date1: dateValue1,
        date2: dateValue2
    }
    if (typeof storeObject === 'undefined') return null;
    return storeObject;
}


const getTM =  () => {
    const placeValue = window.localStorage.getItem(placeTM);
    const dateValue1 = window.localStorage.getItem(date1TM);
    const dateValue2 = window.localStorage.getItem(date2TM);
    const storeObject = {
        place: placeValue,
        date1: dateValue1,
        date2: dateValue2
    }
    if (typeof storeObject === 'undefined') return null;
    return storeObject;
}

const getEvent =  () => {
    const placeValue = window.localStorage.getItem(placeEvent);
    const dateValue1 = window.localStorage.getItem(date1Event);
    const dateValue2 = window.localStorage.getItem(date2Event);
    const storeObject = {
        place: placeValue,
        date1: dateValue1,
        date2: dateValue2
    }
    if (typeof storeObject === 'undefined') return null;
    return storeObject;
}

const removeTitle = () => {
    try {
        window.localStorage.removeItem(title);

    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}
const removePlace = () => {
    try {

        window.localStorage.removeItem(place);

    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}
const removeDate = () => {
    try {
        window.localStorage.removeItem(date1);
        window.localStorage.removeItem(date2);
    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}
const removeTitleF = () => {
    try {
        window.localStorage.removeItem(titleF);

    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}
const removePlaceF = () => {
    try {

        window.localStorage.removeItem(placeF);

    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}
const removeDateF = () => {
    try {
        window.localStorage.removeItem(date1F);
        window.localStorage.removeItem(date2F);
    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}

const removePlaceTM = () => {
    try {

        window.localStorage.removeItem(placeTM);

    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}
const removeDateTM = () => {
    try {
        window.localStorage.removeItem(date1TM);
        window.localStorage.removeItem(date2TM);
    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}

const removePlaceEvent = () => {
    try {

        window.localStorage.removeItem(placeEvent);

    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}
const removeDateEvent = () => {
    try {
        window.localStorage.removeItem(date1Event);
        window.localStorage.removeItem(date2Event);
    } catch (error) {
        console.log('Erreur pendant la suppression du token', error);
    }
}




const exportedStorageFilters = {
    get,
    getTM,
    storeTitle,
    storePlace,
    storePlaceTM,
    storeDate,
    storeDateTM,
    removeTitle,
    removePlace,
    removeDate,
    removePlaceTM,
    removeDateTM,
    getEvent,
    storePlaceEvent,
    storeDateEvent,
    removePlaceEvent,
    removeDateEvent,
    getF,
    storeTitleF,
    storePlaceF,
    storeDateF,
    removeTitleF,
    removePlaceF,
    removeDateF
}

export default exportedStorageFilters;