import React, {useEffect, useRef, useState} from "react";
import {
    Anchor,
    Breadcrumbs,
    Button,
    NumberInput,
    Group,
    Modal,
    TextInput,
    Select,
    LoadingOverlay,
    Tooltip,
    ActionIcon,
    MultiSelect,
    Badge,
    ScrollArea,
    Switch,
} from "@mantine/core";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FaCalendar, FaFileInvoice, FaUser, FaUserEdit} from "react-icons/fa";
import {
    AiOutlineRight,
    BsFillCalendarCheckFill,
    FaUsers,
    FiTarget,
    IoBarChartSharp,
    AiFillStar,
    MdGroups,
} from "react-icons/all";
import useApi from "../../../hooks/useApi";
import users from "../../../api/users";
import userInfos from "../../../api/userInformations";
import RoleCardAccount from "../../../components/account/RoleCardAccount";
import InfosCountCardAccount from "../../../components/account/InfosCountCardAccount";
import goals from "../../../api/goals";
import profilePictureTest from "../../../assets/images/profile-picture-test.png";
import Footer from "../../Footer";
import {IoIosCopy} from "react-icons/all";
import {useClipboard, useForm} from "@mantine/hooks";
import evolution from "../../../api/evolution";
import ParcoursItem from "../../../components/account/ParcoursItem";
import {ROLE_WITHOUT_TOUS} from "../../../constants/roles";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import {URL2} from "../../../constants/URL";
import finishedGoals from "../../../api/finishedGoals";
import {Dropzone, IMAGE_MIME_TYPE} from "@mantine/dropzone";
import {
    HABILITATION, HABILITATION_USER,
    HABILITATION_WITHOUT_TOUS,
} from "../../../constants/habilitation";
import storage from "../../../auth/storage";
import dynasty from "../../../api/dynasty";
import CardLevelBefore from "../../../components/account/CardLevelBefore";
import CardLevelAfter from "../../../components/account/CardLevelAfter";
import logoAccount from '../../../assets/images/SVG/account.svg';
import logoUser from '../../../assets/images/SVG/user.svg';
import logoPilote from '../../../assets/images/SVG/pilote.svg';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId,getLatLng } from 'react-places-autocomplete';
import { REACT_APP_GOOGLE_MAP_API_KEY } from "../../../env";
import { format_ddMMYYYY } from "../../../utility/dateUtility";

const Account = () => {
    const {id}: any = useParams();
    const {id: idUserInterne,role}: any = storage.getUser();
    const [valueAddressGoogle, setValueAddressGoogle] = useState(null);
    const [valuePlaceId, setValuePlaceId] = useState(null);
    const [lat1, setLat1] = useState("");
    const [lng1, setLng1] = useState("");
    const [isUp, setIsUp] = useState(false);
    const {request: requestEdit} = useApi(users.edit);
    const date = new Date();
    const [isRenouveled, setIsRenouveled] = useState(false);
    const StorageId: any = storage.getUser();
    const navigation = useNavigate();
    const [isInOrganization, setIsInOrganization] = useState(false);
    const [dataDynasty, setDataDynasty] = useState<any>();
    const [isHabitError, setIsHabitError] = useState<boolean>(false);
    const [habilitation, setHabilitation] = useState([]);
    const [loading, setLoading] = useState(false);
    const { data: dataLigneTab, request: requestLigneTab } = useApi(users.getUserLigneTab);

    const {data, loading: loadingUser, request} = useApi(users.infos);
    const {data:ligne, loading: loadingLigne, request: requestLigne} = useApi(users.getUserLigne);
    const {data:ligne2, loading: loadingLigne2, request: requestLigne2} = useApi(users.getUserLigne);
    const {
        data: dataALLGoals,
        loading: loadingALLGoals,
        request: requestALLGoals,
    } = useApi(goals.getAll);
    const {
        data: dataParcours,
        loading: loadingParcours,
        request: requestParcours,
    } = useApi(evolution.collectionByUserEnded);
    const {
        data: dataGoals,
        loading: loadingGoals,
        request: requestGoal,
    } = useApi(goals.getByLevel);
    const [userInfo, setUserInfo] = useState<any>(null);
    const [opened2, setOpened2] = useState(false);
    const clipboard = useClipboard({timeout: 500});
    const {
        data: dataFiniGoals,
        loading: loadingFiniGoals,
        request: requestFiniGoal,
    } = useApi(finishedGoals.getByGoalIdAndUserId);

    useEffect(() => {
        // @ts-ignore
        if(valueAddressGoogle?.value?.place_id && valueAddressGoogle?.value?.terms){
            // @ts-ignore
            // @ts-ignore
            setValuePlaceId(valueAddressGoogle?.value?.place_id)
            // @ts-ignore
            geocodeByPlaceId(valueAddressGoogle?.value?.place_id)
                // @ts-ignore
                .then(results =>  {
                    // @ts-ignore
                    getLatLng(results[0]).then(({ lat, lng }) => {
                        // lat to string

                        setLat1(lat.toString());
                        setLng1(lng.toString());
                    });
                    form.setValues({
                        ...form.values,
                        // @ts-ignore
                        country: valueAddressGoogle?.value?.terms[3].value, city: valueAddressGoogle.value?.terms[2].value, address: valueAddressGoogle.value?.terms[0].value + " " + valueAddressGoogle.value?.terms[1].value, postalCode: results[0].address_components[6].long_name
                    });
                })
                // @ts-ignore

        }
    },[valueAddressGoogle])

    useEffect(() => {
        if(idUserInterne != id){
            setIsInOrganization(false);
            requestLigneTab(idUserInterne).then((res: any) => {
                console.log(res.data.ligne,idUserInterne)
                res.data.ligne.find((item: any) => {
                    if(item == id){
                        console.log("ID IS IN ORGANIZATION")
                        setIsInOrganization(true);
                    }
                })
            })
        }else {
            setIsInOrganization(true);
        }

        if(idUserInterne != id){
            requestLigne2(idUserInterne).then((res: any) => {
               if(res.data && res.data.ligneAval){
                   res.data.ligneAval.find((item: any) => {
                       if(item.id == id){
                           setIsUp(true);
                       }
                   })
               }
            })
        }else {
            setIsUp(false);
        }

    },[id])


    const {request: requestPostUserInfos} = useApi(userInfos.post);
    const {request: requestEditUserInfos} = useApi(userInfos.editUserInfos);

    const RôleScrollView = useRef<HTMLDivElement>(null);
    const RôleView = useRef<HTMLDivElement>(null);

    useEffect(() => {
        requestLigne(id).then((res) => console.log(res))
        window.scrollTo(0, 0);
        request(id).then((r) => {
            form.setValues({
                picture: r?.data?.fileUrl,
                email: r?.data?.email,
                phoneNumber: r?.data?.phoneNumber,
                firstName: r?.data?.firstName,
                lastName: r?.data?.lastName,
                birthDay: dayjs(r?.data?.birthDay ?? new Date()).toDate(),
                civilite: r?.data?.civilite,
                country: r?.data?.country,
                city: r?.data?.city,
                address: r?.data?.address,
                postalCode: r?.data?.postalCode,
                addressAccess: r?.data?.addressAccess,
            });
            formUserInfos.setValues({
                habilitation: r?.data?.userInformations?.habilitation,
                societyName: r?.data?.userInformations?.societyName,
                societySiret: r?.data?.userInformations?.societySiret,
                societyAddress: r?.data?.userInformations?.societyAddress,
                country: r?.data?.userInformations?.country,
                city: r?.data?.userInformations?.city,
                postalCode: r?.data?.userInformations?.postalCode,
            });
            dynasty.getDynasty(r?.data?.dynasty && r?.data?.dynasty.id).then((r) => {
                setDataDynasty(r?.data);
            });
            //@ts-ignore
            RôleScrollView?.current?.scrollTo({left: RôleView?.current?.offsetLeft - RôleScrollView?.current?.offsetWidth / 2 + RôleView?.current?.offsetWidth / 2,});
        });

        requestALLGoals();
        requestParcours(id).then((res) => {
            //  console.log('response',res)
        });
    }, [id]);

    useEffect(() => {
        setUserInfo(data);
        requestGoal(data?.roles ? data.roles[0] : "");
        requestFiniGoal(data?.roles ? data.roles[0] : "", data?.id);
        data?.dateEndMember != null && data?.dateEndMember > date && setIsRenouveled(true);
    }, [data]);

    const idRole = ROLE_WITHOUT_TOUS.indexOf(
        userInfo?.roles && userInfo?.roles[0],
    );
    //console.log('userinfo',userInfo);

    const items = [
        {title: "Accueil", href: "/auth/home"},
        {title: userInfo?.firstName, href: `/auth/adherents/profil/${id}`},
    ].map((item, index) => (
        <Anchor
            component={Link}
            to={item.href}
            key={index}
            style={{color: "#333", fontSize: 14, marginBottom:"auto",marginTop:"auto"}}
        >
            {item.title}
        </Anchor>
    ));

    const [opened, setOpened] = useState(false);

    // button list
    const [alignment, setAlignment] = React.useState("module");
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
    };

    //hide and show levels
    const [showLevel2, setShowLevel2] = useState(false);
    const [showLevel3, setShowLevel3] = useState(false);
    const [showLevel4, setShowLevel4] = useState(false);

    const [currentLigne, setCurrentLigne] = useState(false);

    const handleLevels = () => {
        setShowLevel2(!showLevel2);
        setShowLevel3(!showLevel3);
        setShowLevel4(!showLevel4);
    };

    const form = useForm({
        initialValues: {
            picture: [],
            email: "",
            phoneNumber: "",
            firstName: "",
            lastName: "",
            birthDay: new Date(),
            civilite: "",
            country: "",
            city: "",
            address: "",
            postalCode: "",
            addressAccess: false,
        },

        validationRules: {
            email: (value) => /^\S+@\S+$/.test(value),
        },
        errorMessages: {
            email: "Format de l'email invalide",
        },
    });

    const formUserInfos = useForm({
        initialValues: {
            habilitation: [],
            societyName: "",
            societySiret: "",
            societyAddress: "",
            country: "",
            city: "",
            postalCode: "",
        },
    });

    useEffect(() => {

        //@ts-ignore
        if (formUserInfos?.values?.habilitation?.includes("CIF") && formUserInfos?.values?.habilitation?.includes("AGENT")) {
            //@ts-ignore
            setIsHabitError(true)
        }else{
            setIsHabitError(false)
        }

    }, [formUserInfos.values.habilitation]);

    // console.log('userinfos',userInfo.userInformations.habilitation)
    const handleSubmit = (values: any) => {
        setLoading(true);
        // @ts-ignore
        const newValues = {
            ...values,
            lat: lat1,
            lng: lng1,
        }
        requestEdit(newValues, id)
            .catch((data) => {
                // console.log(data)
            })
            .then(async () => {
                if (values.picture != null) {
                    await users.image(values.picture[0], id);
                }
                if (userInfo.userInformations == null) {
                    await requestPostUserInfos(userInfo.id);
                }

                requestEditUserInfos(
                    {
                        habilitation: formUserInfos.values.habilitation,
                        societyName: formUserInfos.values.societyName,
                        societySiret: formUserInfos.values.societySiret,
                        societyAddress: formUserInfos.values.societyAddress,
                        country: formUserInfos.values.country,
                        city: formUserInfos.values.city,
                        postalCode: formUserInfos.values.postalCode,
                    },
                    userInfo.userInformations.id,
                );
            })
            .finally(() => {
                setLoading(false);
                request(id).then((r) => {
                    form.setValues({
                        picture: r?.data?.fileUrl,
                        email: r?.data?.email,
                        phoneNumber: r?.data?.phoneNumber,
                        firstName: r?.data?.firstName,
                        lastName: r?.data?.lastName,
                        birthDay: dayjs(r?.data?.birthDay ?? new Date()).toDate(),
                        civilite: r?.data?.civilite,
                        country: r?.data?.country,
                        city: r?.data?.city,
                        address: r?.data?.address,
                        postalCode: r?.data?.postalCode,
                        addressAccess: r?.data?.addressAccess,
                    });
                    formUserInfos.setValues({
                        habilitation: r?.data?.userInformations?.habilitation,
                        societyName: r?.data?.userInformations?.societyName,
                        societySiret: r?.data?.userInformations?.societySiret,
                        societyAddress: r?.data?.userInformations?.societyAddress,
                        country: r?.data?.userInformations?.country,
                        city: r?.data?.userInformations?.city,
                        postalCode: r?.data?.userInformations?.postalCode,

                    });
                });
                setUserInfo(data);
            });
        setOpened(false);
    };

    function UpdateImage(value: []) {
        form.setFieldValue("picture", value);
        // @ts-ignore
        const UrlTest = URL.createObjectURL(value[0]);
        // @ts-ignore
        document.getElementById("imgOut").src = UrlTest;
        // @ts-ignore
        document.getElementById("ImageError").innerHTML = "";
    }

    function RejectImage(value: []) {
        // @ts-ignore
        //console.log("Rejected file : ", value[0]);
        // @ts-ignore
        document.getElementById("ImageError").innerHTML = "Image type invalide ou image trop volumineuse.";
    }


    let civGF = userInfo?.godFather?.civilite;
    return (
        <>
            <LoadingOverlay visible={loading}/>
            <div className="mx-4 mb-6 mt-2 md:px-4 sm:px-6 lg:px-8">
                <div className="flex gap-x-5">
                    {/* <button onClick={() => navigation(-1)}>Retour</button> */}
                    <Button
                        className="text-sm hover:text-gray-600 border-0 w-auto text-gray-400"
                        color="lime"
                        size="xs"
                        variant="outline"
                        onClick={() => navigation(-1)}
                    >
                        Retour
                    </Button>
                    <Breadcrumbs className="flex-none" separator="/" styles={{separator: {color: "#333"}}}>
                        {items}
                    </Breadcrumbs>
                </div>
            </div>
            <div
                className="w-screen md:w-full shadow-md md:flex md:justify-evenly mt-2 md:mt-0 bg-white bg-opacity-70 p-4 ml-0 text-center md:text-left">
                <img
                    className="rounded-md m-auto w-2/5 md:w-2/12 xl:h-48 xl:w-auto xl:mx-0"
                    src={
                        userInfo?.fileUrl ? URL2 + userInfo?.fileUrl : profilePictureTest
                    }
                    alt="photo profil"
                />
                <div className="md:w-7/12">
                    <div className="py-2">
                        {userInfo?.credit > 0 && StorageId?.id == id && (
                            <h2 className="text-thirteen font-extralight text-gray-900 float-right">
                                Crédit : {userInfo?.credit}{" "}
                            </h2>
                        )}
                        <h6 className="text-s32 leading-6 font-extralight text-gray-900">
                            {userInfo?.firstName} {userInfo?.lastName}
                        </h6>
                        <div>
                            <span className="text-thirteen leading-3 flex-1 text-black">
                                {userInfo?.phoneNumber?.replace(/\d{2}(?=.)/g, "$& ")}
                            </span>
                            {userInfo?.addressAccess && (
                                <span className="text-thirteen leading-3 float-right flex-1 ">
                                    {userInfo?.address} {userInfo?.postalCode} {userInfo?.city}
                                </span>
                            )}
                            <p className="text-center text-thirteen md:text-left lowercase">
                                {userInfo?.email}
                                <Tooltip
                                    wrapLines
                                    withArrow
                                    transition="fade"
                                    transitionDuration={200}
                                    label="Copier l'email"
                                >
                                    <Tooltip
                                        delay={500}
                                        position="right"
                                        label="Copié !"
                                        opened={opened2}
                                    >
                                        <ActionIcon
                                            component="button"
                                            onClick={() => {
                                                clipboard.copy(data.email);
                                                setOpened2((o) => !o);
                                            }}
                                        >
                                            <IoIosCopy size={12}/>
                                        </ActionIcon>
                                    </Tooltip>
                                </Tooltip>
                            </p>
                        </div>
                        {StorageId?.id == id && (
                            <div className="mt-5 flex flex-wrap gap-2">
                                {userInfo?.roles && userInfo?.roles[0] != "ROLE_GUEST" && (
                                <Button
                                    component={Link}
                                    to={"/auth/adherents/profil/goals/" + id}
                                    size="xs"
                                    className="text-xs border-0 bg-white rounded-md hover:bg-buttonAccount hover:text-white flex-1"
                                    leftIcon={<FiTarget size={14}/>}
                                    variant="default"
                                >
                                    Objectifs
                                </Button>
                                )}
                                <Button
                                    component={Link}
                                    to="/auth/adherents/profil/agenda"
                                    size="xs"
                                    className="text-xs border-0 bg-white rounded-md hover:bg-buttonAccount hover:text-white flex-1"
                                    leftIcon={<BsFillCalendarCheckFill size={14}/>}
                                    variant="default"
                                >
                                    Mon Agenda
                                </Button>
                                <Button
                                    onClick={() =>
                                        //@ts-ignore
                                        window.scrollTo(0, document.getElementById("organisation")?.offsetTop,)
                                    }
                                    size="xs"
                                    className="text-xs border-0 bg-white rounded-md hover:bg-buttonAccount hover:text-white flex-1"
                                    leftIcon={<FaUsers size={14}/>}
                                    variant="default"
                                >
                                    Organisation
                                </Button>
                                <Button
                                    size="xs"
                                    className="text-xs border-0 bg-white rounded-md hover:bg-buttonAccount hover:text-white flex-1"
                                    component={Link}
                                    to="/auth/adherents/profil/factures"
                                    leftIcon={<FaFileInvoice size={14}/>}
                                    variant="default"
                                >
                                    Factures
                                </Button>
                                <Button
                                    size="xs"
                                    component={Link}
                                    className="text-xs border-0 bg-white rounded-md hover:bg-buttonAccount hover:text-white flex-1"
                                    leftIcon={<IoBarChartSharp size={14}/>}
                                    variant="default"
                                    to="/auth/adherents/profil/rapports"
                                >
                                    Rapports
                                </Button>
                                <Button
                                    onClick={() => setOpened(true)}
                                    size="xs"
                                    className="text-xs border-0 bg-white rounded-md hover:bg-buttonAccount hover:text-white flex-1"
                                    leftIcon={<FaUserEdit size={14}/>}
                                    variant="default"
                                >
                                    Modifier mon profil
                                </Button>
                                <Modal
                                    opened={opened}
                                    onClose={() => setOpened(false)}
                                    title={"Modifier mon profil"}
                                    // overflow="inside"
                                >
                                    <form
                                        onSubmit={form.onSubmit((values) => handleSubmit(values))}
                                    >
                                        <Dropzone
                                            className=""
                                            onDrop={(value: []) => UpdateImage(value)}
                                            multiple={false}
                                            maxSize={3 * 1024 ** 2}
                                            accept={IMAGE_MIME_TYPE || "image/jpeg"}
                                            onReject={(value: []) => RejectImage(value)}
                                        >
                                            {() => (
                                                <Group
                                                    position="center"
                                                    spacing="xl"
                                                    style={{minHeight: 50, pointerEvents: "none"}}
                                                >
                                                    <div className="flex items-center">
                                                        <img
                                                            id="imgOut"
                                                            className="w-40 mr-5"
                                                            src={
                                                                form.values.picture &&
                                                                URL2 + "/" + form.values.picture
                                                            }
                                                            alt=""
                                                        />
                                                        <div className="text-sm">
                                                            Faites glisser ou cliquez pour sélectionner une
                                                            image à mettre en avant, l'image ne doit pas
                                                            dépasser 5 Mo
                                                            <p id="ImageError"></p>
                                                        </div>
                                                    </div>
                                                </Group>
                                            )}
                                        </Dropzone>
                                        <Select
                                            required
                                            label="Civilité"
                                            data={[
                                                {value: "Monsieur", label: "Monsieur"},
                                                {value: "Madame", label: "Madame"},
                                            ]}
                                            {...form.getInputProps("civilite")}
                                        />
                                        <TextInput
                                            required
                                            label="Prénom"
                                            {...form.getInputProps("firstName")}
                                        />
                                        <TextInput
                                            required
                                            label="Nom"
                                            {...form.getInputProps("lastName")}
                                        />
                                        <TextInput
                                            required
                                            label="Email"
                                            {...form.getInputProps("email")}
                                        />
                                        <TextInput
                                            required
                                            label="Téléphone"
                                            {...form.getInputProps("phoneNumber")}
                                        />
                                        <DatePicker
                                            labelFormat="DD/MM/YYYY"
                                            inputFormat="DD/MM/YYYY"
                                            label="Anniversaire"
                                            {...form.getInputProps("birthDay")}
                                        />

                                        <div className={"my-2"}>
                                            <GooglePlacesAutocomplete
                                                selectProps={{
                                                    valueAddressGoogle,
                                                    onChange: setValueAddressGoogle,
                                                }}
                                                apiKey={REACT_APP_GOOGLE_MAP_API_KEY}
                                            />
                                        </div>
                                        <TextInput
                                            label="Adresse"
                                            {...form.getInputProps("address")}
                                        />
                                        <TextInput
                                            label="Code Postal"
                                            {...form.getInputProps("postalCode")}
                                        />
                                        <TextInput label="Ville" {...form.getInputProps("city")} />
                                        <TextInput
                                            label="Pays"
                                            {...form.getInputProps("country")}
                                        />
                                        <Switch
                                            className="my-3"
                                            label="Rendre son adresse visible pour tous"
                                            checked={form.values.addressAccess}
                                            onChange={(event) =>
                                                form.setFieldValue(
                                                    "addressAccess",
                                                    event.currentTarget.checked,
                                                )
                                            }
                                        />
                                        {/* <NumberInput
                                    label="Code Postal"
                                    {...form.getInputProps('postalCode')}
                                    hideControls
                                /> */}
                                        <MultiSelect
                                            label="Habilitations"
                                            data={HABILITATION_USER}
                                            error={isHabitError && 'Il n\'est pas possible d\'être CIF et agent lié en même temps !'}
                                            value={formUserInfos.values.habilitation}
                                            onChange={(event: []) =>
                                                formUserInfos.setFieldValue("habilitation", event)
                                            }
                                        />
                                        <TextInput
                                            label="Nom société"
                                            {...formUserInfos.getInputProps("societyName")}
                                        />
                                        <TextInput
                                            label="Siret société"
                                            {...formUserInfos.getInputProps("societySiret")}
                                        />
                                        <TextInput
                                            label="Adresse société"
                                            {...formUserInfos.getInputProps("societyAddress")}
                                        />
                                        <TextInput
                                            label="Code postal société"
                                            {...formUserInfos.getInputProps("postalCode")}
                                        />
                                        <TextInput
                                            label="Ville société"
                                            {...formUserInfos.getInputProps("city")}
                                        />
                                        <TextInput
                                            label="Pays société"
                                            {...formUserInfos.getInputProps("country")}
                                        />
                                        <Button
                                            onClick={() => handleSubmit(form.values)}
                                            className="mt-3"
                                            disabled={isHabitError}
                                            type="submit"
                                        >
                                            Valider
                                        </Button>
                                    </form>
                                </Modal>
                            </div>
                        )}
                    </div>
                </div>

                <div className=" my-auto mt-14 mb:mt-0 ml-2 mr-3">
                    <p className="flex flex-wrap text-right gap-x-1 text-thirteen">

                        {(civGF === "Homme" || civGF === "Monsieur" || civGF === "H" || civGF === "M.") ? "Parrain" : "Marraine"} :


                        <p className="font-bold">
                            {userInfo?.godFather?.email
                                ? userInfo?.godFather?.firstName +
                                " " +
                                userInfo?.godFather?.lastName
                                : "Aucun"}
                        </p>
                    </p>
                    <p className="flex mt-3 flex-wrap text-right gap-x-1 text-thirteen">
                        Manager :
                        <p className="font-bold">
                            {" "}
                            {userInfo?.manager?.email
                                ? userInfo?.manager?.firstName +
                                " " +
                                userInfo?.manager?.lastName
                                : "Aucun"}
                        </p>
                    </p>
                </div>
            </div>
            <div className="w-screen md:w-full px-4 xl:px-8 py-2 md:pb-12">
                <ScrollArea viewportRef={RôleScrollView} className={"mx-auto"} style={{maxWidth: 1100}}>
                    <div className="flex justify-around items-center my-10 ">
                        <div
                            ref={
                                userInfo?.roles && userInfo?.roles.includes("ROLE_NEOMAN")
                                    ? RôleView
                                    : null
                            }
                        >
                            <RoleCardAccount
                                role="Neoman"
                                Goals={dataALLGoals.filter(function (item: any) {
                                    if (item.level === "ROLE_NEOMAN") {
                                        return item;
                                    }
                                })}
                                date={
                                    userInfo?.userRoleInformation
                                        ? userInfo?.userRoleInformation?.neomanCreatedAt
                                        : null
                                }
                                isActive={
                                    userInfo?.roles
                                        ? userInfo?.roles.includes("ROLE_NEOMAN")
                                        : false
                                }
                                isRole={
                                    idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_NEOMAN") &&
                                    userInfo?.id == StorageId.id
                                        ? false
                                        : true
                                }
                                goalFinish={dataGoals.length}
                                goalAtFinish={dataFiniGoals.length}
                            />
                        </div>
                        <div className="flex-none justify-center text-center my-5">
                            <AiOutlineRight size={25}/>
                        </div>
                        <div
                            ref={
                                userInfo?.roles && userInfo?.roles.includes("ROLE_BEMAN")
                                    ? RôleView
                                    : null
                            }
                        >
                            <RoleCardAccount
                                role="Beman"
                                Goals={dataALLGoals.filter(function (item: any) {
                                    if (item.level === "ROLE_BEMAN") {
                                        return item;
                                    }
                                })}
                                date={
                                    userInfo?.userRoleInformation
                                        ? userInfo?.userRoleInformation?.bemanCreatedAt
                                        : null
                                }
                                isActive={
                                    userInfo?.roles
                                        ? userInfo?.roles.includes("ROLE_BEMAN")
                                        : false
                                }
                                isRole={
                                    idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_BEMAN") &&
                                    userInfo?.id == StorageId.id
                                        ? false
                                        : true
                                }
                                goalFinish={dataGoals.length}
                                goalAtFinish={dataFiniGoals.length}
                            />
                        </div>
                        <div className="flex-none justify-center text-center my-5">
                            <AiOutlineRight size={25}/>
                        </div>
                        <div
                            ref={
                                userInfo?.roles && userInfo?.roles.includes("ROLE_ADMAN")
                                    ? RôleView
                                    : null
                            }
                        >
                            <RoleCardAccount
                                role="Adman"
                                Goals={dataALLGoals.filter(function (item: any) {
                                    if (item.level === "ROLE_ADMAN") {
                                        return item;
                                    }
                                })}
                                date={
                                    userInfo?.userRoleInformation
                                        ? userInfo?.userRoleInformation?.AdmanCreatedAt
                                        : null
                                }
                                isActive={
                                    userInfo?.roles
                                        ? userInfo?.roles.includes("ROLE_ADMAN")
                                        : false
                                }
                                isRole={
                                    idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_ADMAN") &&
                                    userInfo?.id == StorageId.id
                                        ? false
                                        : true
                                }
                                goalFinish={dataGoals.length}
                                goalAtFinish={dataFiniGoals.length}
                            />
                        </div>
                        <div className="flex-none justify-center text-center my-5">
                            <AiOutlineRight size={25}/>
                        </div>
                        <div
                            ref={
                                userInfo?.roles && userInfo?.roles.includes("ROLE_MAN")
                                    ? RôleView
                                    : null
                            }
                        >
                            <RoleCardAccount
                                role="Man"
                                Goals={dataALLGoals.filter(function (item: any) {
                                    if (item.level === "ROLE_MAN") {
                                        return item;
                                    }
                                })}
                                date={
                                    userInfo?.userRoleInformation
                                        ? userInfo?.userRoleInformation?.manCreatedAt
                                        : null
                                }
                                isActive={
                                    userInfo?.roles ? userInfo?.roles.includes("ROLE_MAN") : false
                                }
                                isRole={
                                    idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_MAN") &&
                                    userInfo?.id == StorageId.id
                                        ? false
                                        : true
                                }
                                goalFinish={dataGoals.length}
                                goalAtFinish={dataFiniGoals.length}
                            />
                        </div>
                        <div className="flex-none justify-center text-center my-5">
                            <AiOutlineRight size={25}/>
                        </div>
                        <div
                            ref={
                                userInfo?.roles && userInfo?.roles.includes("ROLE_DEVMAN")
                                    ? RôleView
                                    : null
                            }
                        >
                            <RoleCardAccount
                                role="Devman"
                                Goals={dataALLGoals.filter(function (item: any) {
                                    if (item.level === "ROLE_DEVMAN") {
                                        return item;
                                    }
                                })}
                                date={
                                    userInfo?.userRoleInformation
                                        ? userInfo?.userRoleInformation?.devmanCreatedAt
                                        : null
                                }
                                isActive={
                                    userInfo?.roles
                                        ? userInfo?.roles.includes("ROLE_DEVMAN")
                                        : false
                                }
                                isRole={
                                    idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_DEVMAN") &&
                                    userInfo?.id == StorageId.id
                                        ? false
                                        : true
                                }
                                goalFinish={dataGoals.length}
                                goalAtFinish={dataFiniGoals.length}
                            />
                        </div>
                        <div className="flex-none justify-center text-center my-5">
                            <AiOutlineRight size={25}/>
                        </div>
                        <div
                            ref={
                                userInfo?.roles && userInfo?.roles.includes("ROLE_DXMAN")
                                    ? RôleView
                                    : null
                            }
                        >
                            <RoleCardAccount
                                role="DXman"
                                Goals={dataALLGoals.filter(function (item: any) {
                                    if (item.level === "ROLE_DXMAN") {
                                        return item;
                                    }
                                })}
                                date={
                                    userInfo?.userRoleInformation
                                        ? userInfo?.userRoleInformation?.dxmanCreatedAt
                                        : null
                                }
                                isActive={
                                    userInfo?.roles
                                        ? userInfo?.roles.includes("ROLE_DXMAN")
                                        : false
                                }
                                isRole={
                                    idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_DXMAN") &&
                                    userInfo?.id == StorageId.id
                                        ? false
                                        : true
                                }
                                goalFinish={dataGoals.length}
                                goalAtFinish={dataFiniGoals.length}
                            />
                        </div>
                        <div className="flex-none justify-center text-center my-5">
                            <AiOutlineRight size={25}/>
                        </div>
                        <div
                            ref={
                                userInfo?.roles && userInfo?.roles.includes("ROLE_XMAN")
                                    ? RôleView
                                    : null
                            }
                        >
                            <RoleCardAccount
                                role="Xman"
                                Goals={dataALLGoals.filter(function (item: any) {
                                    if (item.level === "ROLE_XMAN") {
                                        return item;
                                    }
                                })}
                                date={
                                    userInfo?.userRoleInformation
                                        ? userInfo?.userRoleInformation?.xmanCreatedAt
                                        : null
                                }
                                isActive={
                                    userInfo?.roles
                                        ? userInfo?.roles.includes("ROLE_XMAN")
                                        : false
                                }
                                isRole={
                                    idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_XMAN") &&
                                    userInfo?.id == StorageId.id
                                        ? false
                                        : true
                                }
                                goalFinish={dataGoals.length}
                                goalAtFinish={dataFiniGoals.length}
                            />
                        </div>
                    </div>
                </ScrollArea>

                {userInfo?.createdAt && (
                    <div>
                        <h2 className="text-s21">FORMAN</h2>
                        <div className="mt-3 py-10 px-8 my-auto bg-white rounded-md flex flex-wrap bg-white shadow justify-between">
                            {userInfo
                                ? userInfo?.createdAt && (

                                <div className="flex  items-center  flex-wrap">
                                    <div
                                        className="  mr-3 flex mx-auto items-center justify-center rounded-full flex-shrink-0">
                                        {/* <FaCalendar color="gray" size={30}/> */}
                                        <img className="w-14 h-14" src={logoAccount} alt="" />
                                    </div>
                                    <div className="flex items-center pt-3">
                                        <div>
                                            <h2 className="text-gray-400 text-s13 title-font block">
                                                {userInfo?.roles && userInfo?.roles[0] != "ROLE_GUEST" ? "Inscrit" : "Inscrit"} depuis
                                            </h2>
                                            <span className="block text-gray-800 text-xs title-font font-bold">
                            <span className="text-s13 font-bold text-gray-600 uppercase">
                              {format_ddMMYYYY(userInfo?.createdAt)}
                            </span>
                          </span>
                                        </div>
                                    </div>
                                </div>

                            ): null}
                            {userInfo
                                ? userInfo?.isPilot && (
                                <div className="flex items-center flex-wrap">
                                    <div
                                        className="  mr-3 flex mx-auto items-center justify-center rounded-full flex-shrink-0">
                                        {/* <FaUsers color="gray" size={60}/> */}
                                        <img className="w-12 h-12" src={logoPilote} alt="" />
                                    </div>
                                    <div className="flex items-center text-center">
                                        <h2 className="text-gray-600 text-lg title-font block">
                                            Pilote de formation / atelier
                                        </h2>
                                    </div>
                                </div>
                            ): null}
                            {userInfo?.userInformations
                                ? userInfo?.userInformations?.isTreasurer && (
                                    <div className="flex items-center flex-wrap">
                                        <div
                                            className="  mr-3 inline-flex items-center justify-center rounded-full flex-shrink-0">
                                            {/* <FaUser color="gray" size={30}/> */}
                                            <img className="w-12 h-12" src={logoUser} alt="" />
                                        </div>
                                        <div>
                                            <h2 className="text-gray-600 text-lg title-font block">
                                                Trésorier
                                            </h2>
                                            <span className="block text-gray-400 text-xs title-font font-medium">
                            de l'association
                          </span>
                                        </div>
                                    </div>
                            ): null}
                            {userInfo?.userInformations
                                ? userInfo?.userInformations?.isMemberExe && (

                                    <div className="flex items-center flex-wrap">
                                        <div
                                            className="  mr-3 inline-flex items-center justify-center rounded-full flex-shrink-0">
                                            {/* <FaUser color="gray" size={30}/> */}
                                            <img className="w-12 h-12" src={logoUser} alt="" />
                                        </div>
                                        <div>
                                            <h2 className="text-gray-600 text-lg title-font block">
                                                Membre
                                            </h2>
                                            <span className="block text-gray-400 text-xs title-font font-medium">
                            du Comité Executif
                          </span>
                                        </div>
                                    </div>
                            ) : null}
                        </div>
                    </div>
                )}

                <div className="my-10">
                    <div className="text-s21 items-center justify-center">
                        FORMATIONS & ATELIERS{" "}
                        <span className="text-xs text-gray-400 bg-white font-bold my-auto ml-5 p-1 mb-2">Sur les 12 derniers mois</span>
                    </div>
                    <section className=" bg-coolGray-100 text-coolGray-800">
                        <div className="container grid grid-cols-1 gap-6 mx-auto sm:grid-cols-2 xl:grid-cols-2">
                            <InfosCountCardAccount
                                title="SUIVIS"
                                number={
                                    userInfo?.totalFormationGuestCount +
                                    userInfo?.totalWorkshopGuestCount
                                }
                            />
                            <InfosCountCardAccount
                                title="PILOTES & COPILOTÉS"
                                number={
                                    userInfo?.totalWorkshopCount +
                                    userInfo?.totalFormationCount +
                                    userInfo?.totalWorkshopCopiloteCount +
                                    userInfo?.totalFormationsCopiloteCount
                                }
                            />
                        </div>
                    </section>
                </div>

                <div className="md:flex">
                    <div className="md:w-1/2 w-full md:mr-4 mb-10 md:mb-0">
                        <h3 className="text-s21 text-left leading-6 uppercase mb-4">
                            Parcours{" "}
                            {userInfo?.roles && userInfo?.roles.length > 0
                                ? userInfo?.roles[0].slice(5)
                                : ""}
                        </h3>
                        <ul className="bg-white rounded-md shadow">
                            {dataParcours.length > 0 ? (
                                dataParcours.slice(0, 5).map((parcours: any) => {
                                    return <ParcoursItem parcours={parcours}/>;
                                })
                            ) : (
                                <span>
                  <li className="border-b">
                    <div className="flex justify-between px-4 pt-4 pb-2 text-gray-600 text-thirteen mb-3">
                      <span>Aucun parcours disponible</span>
                    </div>
                  </li>
                </span>
                            )}
                        </ul>
                        <div className="flex justify-between mt-1">
                            {dataParcours.length > 5 && (
                                <Anchor className="text-gray-600 font-medium text-left text-thirteen">
                                    <span> Voir l'historique complet </span>
                                </Anchor>
                            )}
                            <Link to={"/auth/adherents/profil/goals/" + id}>
                            <Anchor className="text-gray-600 font-medium text-right text-thirteen">
                                <span> Voir les objectifs de *{userInfo?.roles && userInfo?.roles[0].slice(5)}*</span>
                            </Anchor>
                            </Link>
                        </div>
                        <div className=" mt-10">
                            <div className="mb-2">
                                <h3 className="text-s21 text-gray-900 uppercase">MON ADHÉSION</h3>
                            </div>
                            <div
                                className="flex w-full justify-between mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800">
                                <div className="my-auto">
                                    <div className="mx-3">
                                        <p className="text-thirteen text-gray-600 dark:text-gray-200">
                                            Adhésion :
                                        </p>
                                        <p className="font-bold text-gray-600 text-thirteen">
                                            {isRenouveled
                                                ? "À JOUR"
                                                : "A RENOUVELER"}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center p-4 justify-center bg-buttonAccount">
                                <span className="text-white">
                                  <span className="text-base text-white  uppercase">
                                    {format_ddMMYYYY(userInfo?.dateEndMember)}
                                  </span>
                                </span>
                                </div>
                            </div>
                            <span className="text-thirteen text-gray-600">
              L'appel de cotisation pour l'année {date.getFullYear() + 1}{" "}
                                commencera le 1er décembre {date.getFullYear()}
            </span>
                            {date.getMonth() > 1 || date.getMonth() < 12 &&(
                                <div className="flex justify-center md:block">
                                    <Button
                                        mt={20}
                                        mr={5}
                                        className="px-20"
                                        size="md"
                                        color="pink"
                                        onClick={() => {
                                            navigation("/auth/notifications/adhesion");
                                        }}
                                        disabled={date.getMonth() > 1 || date.getMonth() < 12}
                                    >
                                        Je paie mon adhésion
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 md:ml-4">
                        <div>
                            <h3 className="text-s21 text-left leading-6 uppercase mb-4">
                                PRODÉMIAL
                            </h3>
                            <ul className="bg-white rounded-md shadow">
                                <li className="border-b">
                                    <div className="flex justify-between px-4 pt-3 pb-2 text-gray-600 text-sm">
                                        <p>QUALIFICATION</p>
                                        {userInfo?.prodemialQualification && (
                                            <p className="flex font-bold text-blue-800">
                                                {userInfo?.prodemialQualification && userInfo?.prodemialQualification}
                                                {" "} {" "}
                                                {userInfo?.prodemialQualificationStars > 1 && userInfo?.prodemialQualificationStars}
                                                {userInfo?.prodemialQualificationStars > 1 && <AiFillStar className="ml-1"/>}
                                            </p>
                                        )}
                                    </div>
                                </li>
                                <li className="border-b">
                                    <div className="flex justify-between px-4 pt-3 pb-2 text-gray-600 text-sm">
                                        <p>TITRE</p>
                                        {userInfo?.prodemialTitre && (
                                            <p className="flex font-bold text-blue-800">
                                                {userInfo?.prodemialTitre && userInfo?.prodemialTitre}
                                                {" " + " "}
                                                {
                                                    userInfo?.prodemialTitreStars && userInfo?.prodemialTitreStars > 1 && userInfo?.prodemialTitreStars}
                                                { userInfo?.prodemialTitreStars && userInfo?.prodemialTitreStars > 1 && <AiFillStar className="ml-1"/>}
                                            </p>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-10">
                            <h3 className="text-s21 text-left leading-6 uppercase mb-4">
                                HABILITATIONS
                            </h3>
                            <div className="bg-white rounded-md shadow p-3">
                                {!formUserInfos.values.habilitation ||
                                    (formUserInfos.values.habilitation.length == 0 && (
                                            <div className="flex justify-between items-center">
                                                <p className="text-thirteen"> Vous n'avez aucune Habilitation </p>
                                                <Button
                                                onClick={() => setOpened(true)}
                                                size="xs"
                                                className="text-thirteen border-0"
                                                variant="default"
                                                >
                                                Modifier mon profil
                                            </Button>
                                            </div>
                                    ))}
                                {formUserInfos.values.habilitation &&
                                    formUserInfos.values.habilitation.map(
                                        (name: string, index: Number) => (
                                            <Badge
                                                className="mx-1 md:rounded md:bg-gray-500 bg-white text-black md:text-white"
                                                variant="filled"
                                            >
                                                {name}
                                            </Badge>
                                        ),
                                    )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="md:flex mt-8 md:mt-16">
                    {userInfo?.userInformations
                        ? userInfo?.userInformations?.societyName && (
                        <div className="md:mr-3 md:w-1/2">
                            <div className="mb-2">
                                <h3 className="text-s21 text-left text-gray-900 break-words uppercase">
                                    SOCIÉTÉ
                                </h3>
                            </div>
                            <ul>
                                <li className="border-gray-400 mb-2">
                                    <div className="shadow bg-white rounded-md p-4 space-y-2">
                                        <p className="font-bold text-lg text-gray-600 break-words">
                                            {userInfo?.userInformations?.societyName}
                                        </p>
                                        <p className="break-words text-gray-600">
                                            {userInfo?.userInformations?.societyAddress} {userInfo?.userInformations?.postalCode} {userInfo?.userInformations?.city} {userInfo?.userInformations?.country}
                                        </p>
                                        <p className="text-gray-600 break-words">
                                            SIRET {userInfo?.userInformations?.societySiret}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )
                        : null}
                </div>


                {id != idUserInterne && role == "ROLE_GUEST" ? (<></>) : ( isInOrganization && (
                <div id="organisation" className="my-10">
                    <div className="px-4 mt-3 py-5 sm:px-6 dark:bg-gray-800 mb-2 rounded-md">
                        <h3 className="text-s21 text-left leading-6 font-medium text-gray-900 uppercase">
                            Organisation
                        </h3>
                    </div>

                    <div className="flex gap-2">
                        <button onClick={() => setCurrentLigne(false)}
                            className={` flex justify-between w-1/2 md:mr-4 px-1 md:px-4 py-2 ${!currentLigne ? " bg-buttonAccount  text-white md:w-2/3  " : "group bg-white bg-opacity-70 hover:bg-opacity-100 "} shadow rounded-md text-left md:text-xl`}>

                            <span className={`${!currentLigne ? "" : "group-hover:text-white"}`}>LIGNÉE AVAL</span>
                            <span className={`flex rounded ${!currentLigne ? " bg-navbarEnd" : "bg-white group-hover:text-white group-hover:bg-navbarEnd"} px-1 md:px-2 pt-1 font-bold text-sm`}>
                <MdGroups size={28} className="mr-2 -mt-1 leading-none"/>
                                {ligne?.totalAmont}
              </span>
                        </button>
                        <button onClick={() => setCurrentLigne(true)}
                                className={` flex justify-between w-1/2 md:mr-4 px-1 md:px-4 py-2 ${currentLigne ? " bg-buttonAccount  text-white md:w-2/3  " : "group bg-white bg-opacity-70 hover:bg-opacity-100  md:w-1/3 "} shadow rounded-md text-left md:text-xl`}>
                            <span className={`${currentLigne ? "" : "group-hover:text-white"}`}>LIGNÉE AMONT</span>
                            <span className={`flex rounded ${currentLigne ? " bg-navbarEnd" : "bg-white group-hover:text-white group-hover:bg-navbarEnd"} px-1 md:px-2 pt-1 font-bold text-sm`}>
                <MdGroups size={28} className="mr-2 -mt-1 leading-none"/>{ligne?.totalAval}
              </span>
                        </button>
                    </div>


                    {!isUp && !currentLigne && ligne && ligne.ligneAmont && ligne.ligneAmont.map((el: any, key : number) => (
                        <CardLevelAfter el={el} key={key} myKey={key} />
                        ))}



                    {!isUp && currentLigne && ligne && ligne.ligneAval && ligne.ligneAval.map((el: any, key :number) => (
                        <>
                        <CardLevelBefore el={el} key={key} myKey={key} />
                        </>
                    ))}





                    {/* {showLevel4 ? <div className='flex flex-wrap justify-center md:justify-between mt-4 md:w-4/6'>
                        <div className='my-2 mx-1 md:mx-6'>
                            <img className='mx-auto w-24 h-24 rounded' src={profilePictureTest} alt="profile"/>
                            <p className='text-gray-600 text-xs text-center mt-1'>Prénom Nom</p>
                        </div>
                    </div> : null} */}
                </div>)
                )}
                <Footer/>
            </div>
        </>
    );
};

export default Account;

/*


<div className="flex flex-row flex-wrap">
                        {ligne &&
                            ligne.length > 0 &&
                            dataDynasty?.components.map((el: any, key: number) => (
                                <div className="flex flex-nowrap justify-start mt-2">
                                    <div className="md:w-150 w-full bg-white rounded shadow p-2 m-2">
                                        <div className="flex">
                                            <img
                                                className="w-20 h-20 rounded"
                                                src={profilePictureTest}
                                                alt="profile"
                                            />
                                            <div className="px-2">
                                                <p className="font-bold text-gray-600 text-md mb-2">
                                                    {el.firstName}
                                                    <span
                                                        className="bg-gray-500 rounded px-2 ml-1 text-white text-center text-xxs">
                            {el.roles[0].slice(5)}
                          </span>
                                                </p>
                                                <p className="text-sm text-gray-500">
                                                    {el.phoneNumber}
                                                </p>
                                                <p className="text-sm text-gray-500">{el.email}</p>
                                            </div>
                                        </div>
                                        {/* <div className='flex justify-between'>
                                <div className='flex py-1'>
                                    <div
                                        className="flex bg-gray-100 rounded px-2 pt-1 mt-2 mx-1 text-xs text-gray-500 font-bold">
                                        <MdGroups size={24} className='mr-2 -mt-1 leading-none'/>3
                                    </div>
                                    <div
                                        className="flex bg-gray-100 rounded px-2 pt-1 mt-2 mx-1 text-xs text-gray-500 font-bold">
                                        <MdGroups size={24} className='mr-2 -mt-1 leading-none'/>12
                                    </div>
                                </div>
                                <div className='flex py-1'>
                                    <div className="flex bg-gray-100 rounded px-2 pt-1 mt-2"><HiOutlineArrowSmUp
                                        size={24} className='-mt-1 leading-none'/></div>
                                    <div className="flex bg-gray-100 rounded px-2 pt-1 mx-1 mt-2"><HiOutlineArrowSmDown
                                        size={24} className='-mt-1 leading-none'/></div>
                                </div>
                            </div>
</div>
</div>
))}
</div>
<div
                        className="flex justify-between mt-10 w-full mr-4 px-4 py-1 rounded bg-white text-xs md:text-xl text-gray-600 shadow">
                        <div className='flex py-1'>
                            NIVEAU 2<span
                            className="flex rounded bg-gray-100 px-2 md:pt-1 ml-2 font-bold text-sm"><MdGroups size={28} className='hidden md:block mr-2 -mt-1 leading-none'/>
                            32</span>

                        </div>
                        <div className='flex'>
                            <button onClick={() => setShowLevel2(!showLevel2)}
                                    className='flex px-2 md:py-2 text-gray-600'>{showLevel2 ?
                                <MdKeyboardArrowUp size={24} className='mr-1'/> :
                                <MdKeyboardArrowDown size={24} className='mr-1'/>}</button>
                        </div>
                    </div>
                    {showLevel2 ? <div className='flex flex-wrap justify-center md:justify-between mt-4 md:w-4/6'>
                        <div className='my-2 mx-1 md:mx-6'>
                            <img className='mx-auto w-24 h-24 rounded' src={profilePictureTest} alt="profile"/>
                            <p className='text-gray-600 text-xs text-center mt-1'>Prénom Nom</p>
                        </div>
                    </div> : null}

                    <div
                        className="flex justify-between mt-10 w-full mr-4 px-4 py-1 rounded bg-white text-xs md:text-xl text-gray-600 shadow">
                        <div className='flex py-1'>
                            NIVEAU 3<span
                            className="flex rounded bg-gray-100 px-2 md:pt-1 ml-2 font-bold text-sm"><MdGroups size={28}
                                                                                                               className='hidden md:block mr-2 -mt-1 leading-none'/>45</span>
                        </div>
                        <div className='flex'>
                            <button onClick={() => setShowLevel3(!showLevel3)}
                                    className='flex px-2 md:py-2 text-gray-600'>{showLevel3 ?
                                <MdKeyboardArrowUp size={24} className='mr-1'/> :
                                <MdKeyboardArrowDown size={24} className='mr-1'/>}</button>
                        </div>
                    </div>
                    {showLevel3 ? <div className='flex flex-wrap justify-center md:justify-between mt-4 md:w-4/6'>
                        <div className='my-2 mx-1 md:mx-6'>
                            <img className='mx-auto w-24 h-24 rounded' src={profilePictureTest} alt="profile"/>
                            <p className='text-gray-600 text-xs text-center mt-1'>Prénom Nom</p>
                        </div>
                    </div> : null}

                    <div
                        className="flex justify-between mt-10 w-full mr-4 px-4 py-1 rounded bg-white text-xs md:text-xl text-gray-600 shadow">
                        <div className='flex py-1'>
                            NIVEAU 4<span
                            className="flex rounded bg-gray-100 px-2 md:pt-1 ml-2 font-bold text-sm"><MdGroups size={28} className='hidden md:block mr-2 -mt-1 leading-none'/>
                            67</span>

                        </div>
                        <div className='flex'>
                            <button onClick={() => setShowLevel4(!showLevel4)}
                                    className='flex px-2 md:py-2 text-gray-600'>{showLevel4 ?
                                <MdKeyboardArrowUp size={24} className='mr-1'/> :
                                <MdKeyboardArrowDown size={24} className='mr-1'/>}</button>
                        </div>
                    </div>

                    */
