import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Table, Button, Anchor, Breadcrumbs } from "@mantine/core";
import { AiOutlineDownload } from "react-icons/ai";
import transactions from "../../api/transactions";
import useApi from "../../hooks/useApi";
import Footer from "../../templates/Footer";
import users from "../../api/users";
import {
  Document,
  Page,
  View,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  Table as TablePDF,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";

import isAdhesionPayment from "../../utility/isAdhesionPayment";
import FacturePDF from "./FacturePDF";
import settings from "../../api/settings";
import { BsFillFileEarmarkCheckFill, BsPrinterFill } from "react-icons/bs";
import { TableBody } from "@mui/material";
import { TableRow } from "@david.kucsai/react-pdf-table/lib/TableRow";
import storage from "../../auth/storage";
import userInformations from "../../api/userInformations";
import workshop from "../../api/workshop";
import getSiret from "../../utility/getSiret";
import {
    getPriceHTBase,
    getPriceTVABase,
    getNumberTVA
} from "../../utility/tva";
import { format_dddd_d_MM_YYYY, format_ddMMYYYY } from "../../utility/dateUtility";

const ItemFactureCard = () => {
  const { id } = useParams();
  const user: any = storage.getUser();
  const navigation = useNavigate();
  const { data: dataTransaction, request: requestTransaction } = useApi(
    transactions.getTransactionById,
  );
  // @ts-ignore
  const { request: transactionGetDetails } = useApi(transactions.getDetails);
  const { data, request } = useApi(users.getUserById);
  const { data: dataTVA, request: requestTVA } = useApi(settings.getTva);
  const idTVA = 1;
  // @ts-ignore
  const [transactionDetails, settransactionDetails] = useState({});
  useEffect(() => {
    request(user.id).then((r) => {
      if (r.data.id != user.id) {
        navigation("/");
      } else {
        requestTVA(idTVA);
        requestTransaction(id).then((res) => {
            console.log(res.data);
        });
      }
    });

  }, []);
  const getPriceHT = (price: string, tva: number) => {
    if (isAdhesionPayment(dataTransaction?.itemName)) {
      return parseInt(price);
    }
    return getPriceHTBase(price, tva);
  };
  const getPriceTVA = (price: string, tva: number) => {
    if (isAdhesionPayment(dataTransaction?.itemName)) {
      return 0;
    }
    return getPriceTVABase(price, tva);
  };


  console.log(dataTransaction?.workshop)


  const foot = (
    <>
      <tr>
        <th></th>
        <th></th>
        <th style={{ color: "white" }}>TOTAL (TTC) </th>
        <th style={{ color: "white" }}>{dataTransaction?.total}</th>
      </tr>
    </>
  );
  const row = (
    <>
      <tr>
        <td>{dataTransaction.quantity ?? 0}</td>
        <td className="text-center leading-6"> {dataTransaction?.itemName ?? ""}
          {dataTransaction?.workshop && " du " + format_dddd_d_MM_YYYY(dataTransaction?.workshop?.startDate) }
          {dataTransaction?.formation && " du " + format_dddd_d_MM_YYYY(dataTransaction?.formation?.startDate) }
          <div>{dataTransaction?.paymentMethod == 'PAYMENT_BY_CREDIT' && '(Paiement par crédit)'}</div>
        </td>
        <td>{getPriceHT(dataTransaction.total, dataTVA.valueTVA)?? 0}</td>
        <td>{getPriceHT(dataTransaction.total, dataTVA.valueTVA) ?? 0}</td>
      </tr>
      <tr>
        <td>Date de paiement</td>
        <td className="text-center">{new Date(dataTransaction.createAt).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}</td>
        <td>TVA ({isAdhesionPayment(dataTransaction.itemName) ? 0 : dataTVA.valueTVA ?? 0} %)</td>
        <td>{getPriceTVA(dataTransaction.total, dataTVA.valueTVA) ?? 0}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td>TOTAL (euros HT) </td>
        <td>{getPriceHT(dataTransaction.total, dataTVA.valueTVA) ?? 0}</td>
      </tr>
    </>
  );

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Mon Profile", href: `/auth/adherents/profil/${id}` },
    { title: "Mes Factures", href: "/auth/adherents/profil/Factures" },
    {
      title: `Facture ${dataTransaction.id} `,
      href: `/auth/adherents/profil/facture/${id}`,
    },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  // @ts-ignore
  useEffect(() => {
    // @ts-ignore
    let ids = [dataTransaction.id]
    // @ts-ignore
    if(ids.length){
      // @ts-ignore
      transactionGetDetails({transactionIds:ids}).then((r) => {
        console.log({r})
        // @ts-ignore
        settransactionDetails(r?.data ? r.data : {})
      });
    }
  }, [dataTransaction]);

  return (
    <>
      <div className="m-2">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
      </div>
      <div className="pb-12 pt-6 md:pt12 px-10 text-gray-600">
        <div className="flex flex-row justify-between">
          <h1 className="text-xl">Mes Factures</h1>
          <p className=" p-3  bg-white text-yellow-500 rounded font-bold flex flex-row">
            {" "}
            <BsPrinterFill className="text-2xl mr-1" /> Merci
            d'imprimer cette facture
          </p>
        </div>
        <div className="flex flex-row  gap-2">
          <h1 className="uppercase text-3xl"> FACTURE {dataTransaction.id}</h1>
        </div>
        <div className=" text-xl">
          {format_ddMMYYYY(dataTransaction.createAt)}
        </div>
        <div className="flex flex-row justify-between mt-3 ">
          <div>
            <div>Association Forman</div>
            <div>2 bis rue Léon Blum</div>
            <div>78350 JOUY-EN-JOSAS</div>
          </div>
          <div className="text-right">
            <div className="mb-3">
              <div className="text-right">Identifiant client:</div>
              <div>
                {data?.id}
              </div>
            </div>
            <div>
              <div>Adresse Facturation client:</div>
              <div>
                {data?.userInformations?.societyName ? data?.userInformations?.societyName : data?.lastName + " " + data?.firstName}
              </div>
              <div>{data?.address}</div>
              <div>
                {data?.city} {data?.postalCode}
              </div>
            </div>
          </div>
        </div>
        <Table
          horizontalSpacing="md"
          verticalSpacing="md"
          className="text-3xl bg-white rounded shadow-xl mt-4 "
          style={{ backgroundColor: "#FFFEF7" }}
        >
          <thead>
            <tr>
              <th>Quantité</th>
              <th>Description</th>
              <th>Prix Unitaire (euros HT)</th>
              <th>Montant (euros HT)</th>
            </tr>
          </thead>
          <tbody>{row}</tbody>
          <tfoot style={{ backgroundColor: "#006098" }}>{foot}</tfoot>
        </Table>
        <div className="flex justify-between mt-4">
          <div className="text-xs flex flex-col">
            {isAdhesionPayment(dataTransaction?.itemName) ? <div>TVA non applicable,article 261 du CGI</div> : <></>}
            <div>
              indemnité forfaitaire pour frais de recouvrement de 40 euros
            </div>

            {(() =>{
              // @ts-ignore
              return Object.keys(transactionDetails).map((id, index) =>{
                // @ts-ignore
                if(id == dataTransaction.id){
                  // @ts-ignore
                  let credits = transactionDetails[id]
                  // @ts-ignore
                  let r = []
                  // @ts-ignore
                  if(credits.length){
                    // @ts-ignore
                    r.push(
                      <div style={{fontSize: 19, lineHeight: '35px'}} key={`credit-${id}-00-${index}`}>
                        Avoirs : 
                      </div>
                    )
                  }
                  // @ts-ignore
                  r.push(credits.map((credit, index) =>{
                    // @ts-ignore
                    var { amount, credit_id } = credit;
                    // @ts-ignore
                    return (
                      <div style={{fontSize: 17, lineHeight: '25px'}} key={`credit-${id}-${credit_id}-${index}`}>
                        - N° {credit_id} :  {amount} euro{amount > 1 ? 's' : ''}
                      </div>
                    )
                  }))
                  return r
                }
                return null;
              })
            })()}
          </div>
          <div className="text-xs font-bold bg-white text-green-500 p-3  rounded flex flex-row">
            {" "}
            <BsFillFileEarmarkCheckFill className="text-2xl" /> Facture Acquité
            le{" "}
            {format_ddMMYYYY(dataTransaction.createAt)}
          </div>
        </div>
        <div className="mt-5 pt-5">
          <div className="text-xs text-center pt-5">
            Facture n° {dataTransaction?.id} - Association Forman - Siret : {getSiret()} - TVA n° : {getNumberTVA()}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ItemFactureCard;
