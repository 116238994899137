export const STATUS = [
    'EN ATTENTE',
    'VALIDÉ',
    'REFUSÉ',
];

export const LEVEL = [
    'ROLE_GUEST',
    'ROLE_ONEMAN',
    'ROLE_NEOMAN',
    'ROLE_BEMAN',
    'ROLE_ADMAN',
    'ROLE_MAN',
    'ROLE_DEVMAN',
    'ROLE_DXMAN',
    'ROLE_XMAN',
]