import dayjs from "dayjs";

export function format_MMMM_YYYY(
  date: Date | string | null,
  defaultValue: string = "",
): string {
  if (!date) {
    return defaultValue;
  }

  // eg : septembre 2023

  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric", // eg: 2023
    month: "long", // eg: septembre
  });
}

export function format_d_MMMM_YYYY(
  date: Date | string | null,
  defaultValue: string = "",
): string {
  if (!date) {
    return defaultValue;
  }

  // eg : 4 septembre 2023

  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric", // eg: 2023
    month: "long", // eg: septembre
    day: "numeric", // eg: 4
  });
}

export function format_dddd_d_MMMM_YYYY(
  date: Date | string | null,
  defaultValue: string = "",
): string {
  if (!date) {
    return defaultValue;
  }

  // eg : lundi 4 septembre 2023

  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric", // eg: 2023
    month: "long", // eg: septembre
    day: "numeric", // eg: 4
    weekday: "long", // eg: lundi
  });
}

export function format_d_MM_YYYY(
  date: Date | string | null,
  defaultValue: string = "",
): string {
  if (!date) {
    return defaultValue;
  }

  // eg : 4 sept 2023

  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric", // eg: 2023
    month: "short", // eg: sept
    day: "numeric", // eg: 4
  });
}

export function format_dddd_d_MM_YYYY(
  date: Date | string | null,
  defaultValue: string = "",
): string {
  if (!date) {
    return defaultValue;
  }

  // eg : lundi 4 sept 2023

  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric", // eg: 2023
    month: "short", // eg: sept
    day: "numeric", // eg: 4
    weekday: "long", // eg: lundi
  });
}

export function format_ddMMYYYY(
  date: Date | string | null,
  defaultValue: string = "",
): string {
  if (!date) {
    return defaultValue;
  }

  // eg : 04/09/2023

  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric", // eg: 2029
    month: "numeric", // eg: 09
    day: "numeric", // eg: 04
  });
}

export function formatPeriodeDate(
  date: Date | string | null,
  defaultValue: string = "",
): string {
  if (!date) {
    return defaultValue;
  }

  return (
    dayjs(date).locale("fr").format("DD MMMM YYYY").charAt(0).toUpperCase() +
    dayjs(date).locale("fr").format("DD MMMM YYYY").slice(1)
  );
}

export function formatPeriodeDate2(
  date: Date | string | null,
  defaultValue: string = "",
): string {
  if (!date) {
    return defaultValue;
  }

  return (
    dayjs(date).locale("fr").format("DD ") +
    dayjs(date).locale("fr").format("MMMM YYYY").charAt(0).toUpperCase() +
    dayjs(date).locale("fr").format("MMMM YYYY").slice(1)
  );
}

export function formatElementStartAndEndDate(
  element: any,
  defaultValue: string = "",
): string {
  if (!element) {
    return defaultValue;
  }

  if (element.startDate) {
    return (
      format_d_MMMM_YYYY(element.startDate) +
      " - " +
      format_d_MMMM_YYYY(element.endDate)
    );
  } else {
    return defaultValue;
  }
}
