import React, {useEffect, useState} from "react";
import {
    Anchor,
    Breadcrumbs,
    Button,
    Checkbox,
    LoadingOverlay,
    Select,
} from "@mantine/core";
import {Link} from "react-router-dom";
import HasRoles from "../../../components/Security/hasRoles";
import {ROLE_XMAN} from "../../../constants/roles";
import {DateRangePicker} from "@mantine/dates";
import CardCollection from "../../../components/workshop/CardCollection";
import useApi from "../../../hooks/useApi";
import news from "../../../api/news";
import ItemListNews from "../../../components/News/ItemListNews";
import {ImCross, BsCardList, BsPlusLg} from "react-icons/all";
import Pagination from "@mui/material/Pagination";
import Footer from "../../Footer";
import storage from "../../../auth/storage";
import users from "../../../api/users";

const ListingNews = () => {
    const {role, id}: any = storage.getUser();
    const {data, loading, request} = useApi(news.collection);
    const [search, setSearch] = useState<string>("");
    const [date, setDate] = useState<any>(null);
    const [filters, setFilters] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [finalDate, setFinalDate] = useState<any>([0, 9999999999999]);
    const {data: dataLigne, request: requestLigne} = useApi(users.getUserLigneTab);
    const [isLigne, setIsLigne] = useState(false);

    const perPage = 10;
    useEffect(() => {
        requestLigne(id).then(r => console.log("LIGNE", r));
        request(role);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (date !== null && date[0] !== null && date[1] !== null) {
            setFinalDate([date[0].getTime(), date[1].getTime()]);
        }
        if (date && date[0] == null && date[1] == null) {
            setFinalDate([0, 9999999999999]);
        }
    }, [date]);

    //filter workshops by thematic
    const filteredNews = data.filter(function (w: any) {
        return (
            w &&
            w.category.toLowerCase().includes(search.toLowerCase()) &&
            w &&
            new Date(w.publishAt).getTime() >= finalDate[0] &&
            new Date(w.publishAt).getTime() <= new Date(finalDate[1]).setHours(23, 59)
        );
    });

    const filteredNewsLigne = filteredNews.filter(function (n: any) {
        if (isLigne) {
            if(dataLigne.ligne){
                if (dataLigne.ligne.find((ligne: any) => ligne == n.author.id)) {
                    return n;
                }
            }
        } else {
            return n;
        }
    });

    const items = [
        {title: "Accueil", href: "/auth/home"},
        {title: "Actualités", href: "/auth/news"},
    ].map((item, index) => (
        <Anchor
            component={Link}
            to={item.href}
            key={index}
            style={{color: "#333", fontSize: 14}}
        >
            {item.title}
        </Anchor>
    ));

    return (
        <>
            <LoadingOverlay visible={loading}/>
            <div className="w-screen md:w-auto px-6 py-2 md:p-12">
                <Breadcrumbs separator="/" styles={{separator: {color: "#333"}}}>
                    {items}
                </Breadcrumbs>
                <div className="mt-6 md:mt-10 text-3xl">
                    {filteredNewsLigne.length < 2 ? "ACTUALITÉ" : "ACTUALITÉS"}
                    <Link to="/auth/news/create">
                        <HasRoles role={["ROLE_XMAN"]}>
                            <Button className="ml-5 bg-gray-500 rounded" size="sm" compact>
                                Créer
                            </Button>
                        </HasRoles>
                    </Link>
                </div>
                <div className="md:max-w-3xl mt-3 md:my-6 relative">
                    <p className="text-sm md:text-md mr-6">
                        Bienvenue sur la page Actualités. L'association vous permet de poster différentes actualités
                        liées à la vie de l’association et de ses membres
                    </p>
                </div>

                {/* MOBILE FILTER */}
                {filters ? (
                    <div className="bg-gray-50 p-2 shadow-md mt-2 mb-4 rounded md:hidden">
                        <div className="flex-1 m-2 relative">
                            <Select
                                clearable
                                placeholder="Toutes les catégories"
                                data={[
                                    {value: "PARTENAIRES", label: "PARTENAIRES"},
                                    {
                                        value: "VIE DE L'ASSOCIATION",
                                        label: "VIE DE L'ASSOCIATION",
                                    },
                                    {value: "PRODUITS", label: "PRODUITS"},
                                    {value: "FISCALITE", label: "FISCALITE"},
                                    {value: "LOI", label: "LOI"},
                                    {value: "FORMATION", label: "FORMATION"},
                                    {value: "EVENEMENT", label: "EVENEMENT"},
                                ]}
                                value={search}
                                onChange={(value) => setSearch(value === null ? "" : value)}
                            />
                        </div>
                        <div className="flex-1 m-2">
                            <DateRangePicker
                                inputFormat="DD/MM/YYYY"
                                labelFormat="MM/YYYY"
                                locale="fr"
                                clearable
                                placeholder="Sélectonner une période"
                                value={date}
                                onChange={(value) => setDate(value)}
                            />
                        </div>
                        <div className="flex justify-end px-2">
                            <button className="bg-gray-500 text-white text-xs rounded my-3 px-2 py-2">
                                Tout afficher
                            </button>
                        </div>
                    </div>
                ) : null}
                {/* END MOBILE FILTER */}

                {/* DESKTOP FILTER */}
                <div className="md:flex justify-between hidden">
                    <div className="flex flex-wrap p-3 pr-0">
                        <div className="flex">
                            <div className="m-2">
                                <Select
                                    clearable
                                    placeholder="Sélectionner une catégorie"
                                    data={[
                                        {value: "PARTENAIRES", label: "PARTENAIRES"},
                                        {
                                            value: "VIE DE L'ASSOCIATION",
                                            label: "VIE DE L'ASSOCIATION",
                                        },
                                        {value: "PRODUITS", label: "PRODUITS"},
                                        {value: "FISCALITE", label: "FISCALITE"},
                                        {value: "LOI", label: "LOI"},
                                        {value: "FORMATION", label: "FORMATION"},
                                        {value: "EVENEMENT", label: "EVENEMENT"},
                                    ]}
                                    value={search}
                                    onChange={(value) => setSearch(value === null ? "" : value)}
                                />
                            </div>
                        </div>
                        <div className="m-2">
                            <DateRangePicker
                                inputFormat="DD/MM/YYYY"
                                labelFormat="MM/YYYY"
                                locale="fr"
                                clearable
                                placeholder="Sélectionner une période"
                                value={date}
                                onChange={(value) => setDate(value)}
                            />
                        </div>
                    </div>
                    <div className="m-2">
                        <button onClick={() => setIsLigne(!isLigne)} className="bg-gray-500 text-white text-xs rounded my-3 px-2 py-2">
                            {isLigne ? "Toutes les actualités" : "Afficher ma lignée"}
                        </button>
                    </div>
                </div>
                {/* END OF DESKTOP FILTER */}
                <div className="m-2">
                    {filteredNewsLigne.length ? (
                        filteredNewsLigne
                            .slice(page * perPage - perPage, perPage * page)
                            .map((news: any) => <ItemListNews news={news}/>)
                    ) : (
                        <span>Aucune Actualité n'a été trouvé</span>
                    )}
                </div>
                {filteredNewsLigne.length > 0 ? (
                    <div className="flex justify-center md:justify-end p-4">
                        <Pagination
                            count={Math.ceil(filteredNewsLigne.length / perPage)}
                            page={page}
                            onChange={(event, value) => setPage(value)}
                            shape="rounded"
                            color="primary"
                            hidePrevButton
                            hideNextButton
                            showFirstButton
                            showLastButton
                        />
                    </div>
                ) : null}
                <Footer/>
            </div>
        </>
    );
};

export default ListingNews;
