import {ROLE_WITHOUT_TOUS} from "../constants/roles";

const getMonthProbatoire = (currentRole: string) => {
    const index = indexOfMonth(currentRole);
    if(index >= 6){
        return 12;
    }else{
        return 6;
    }
}

const indexOfMonth = (currentRole: string) => {
    return ROLE_WITHOUT_TOUS.indexOf(currentRole);
}

const getDateProbatoire = (currentRole: string, date : Date) => {
    const month = getMonthProbatoire(currentRole);
    return date.setMonth(date.getMonth() + month);
}

const ROLE_DATE = [
    'guestCreatedAt',
    'onemanCreatedAt',
    'neomanCreatedAt',
    'bemanCreatedAt',
    'AdmanCreatedAt',
    'manCreatedAt',
    'devmanCreatedAt',
    'dxmanCreatedAt',
    'xmanCreatedAt'
];

export default {
    getMonthProbatoire, getDateProbatoire, indexOfMonth, ROLE_DATE }