import { create } from "apisauce";
import storage from "../auth/storage";
import { AuthToken } from "../entity/AuthToken";
import authStorage from "../auth/storage";
import auth from "./auth";
import useAuth from "../auth/useAuth";
import { useNavigate } from "react-router-dom";
import { REACT_APP_API_BASE_URL } from "../env";

const request = create({
   baseURL: `${REACT_APP_API_BASE_URL}/api`,
});

let isAlreadyFetchingAccessToken = false
request.axiosInstance.interceptors.response.use(response => response, async error => {
    const status = error.response ? error.response.status : null
    if (status === 401) {
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true
            if (!useAuth().isAuthenticated()) {
                useAuth().logout()
                const navigate = useNavigate()
                navigate("/")
            }
            /*const refreshToken = storage.getRefreshToken()
            const authToken = await storage.getToken();
            if (!authToken) return;
            request.headers["Authorization"] = "Bearer " + authToken;
            return request.post('/token/refresh', {refresh_token: refreshToken})
                .then((data) => {
                    console.log(data)
                    // @ts-ignore
                    const {token, refresh_token} = data.data;
                    if(!token || !refresh_token) {
                        authStorage.removeToken();
                        authStorage.removeRefreshToken();
                    }
                    request.addAsyncRequestTransform(request => async () => {
                        if (!data.data) return;
                        // @ts-ignore
                        request.headers["Authorization"] = "Bearer " + data.data['token'];
                    });
                    storage.storeToken(new AuthToken(token, refresh_token));
                    isAlreadyFetchingAccessToken = false
                });*/
        }
    }
    return Promise.reject(error);
});


request.addAsyncRequestTransform(request => async () => {
    console.log(request.url)
    if(request.url?.slice(0,19).includes('/users?phoneNumber=')) return;
    if(request.url?.slice(0,6).includes('/users') && request.method == "post") return;
    if(request.url?.includes('/login_check') && request.method == "post") return;
    const authToken = await storage.getToken();
    if (!authToken) return;
    request.headers["Authorization"] = "Bearer " + authToken;
});

export default request;