import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  Button,
  LoadingOverlay,
} from "@mantine/core";
import { Link } from "react-router-dom";
import {
  AiOutlineSearch,
  BsArrowRight,
  FaUserAlt,
  ImArrowDownRight2,
  ImArrowRight2,
  ImArrowUpRight2,
} from "react-icons/all";
import { GearIcon } from "@modulz/radix-icons";
import Item from "../../../components/Evolutions/Item";
import useApi from "../../../hooks/useApi";
import workshop from "../../../api/workshop";
import evolution from "../../../api/evolution";
import storage from "../../../auth/storage";

const items = [
  { title: "Accueil", href: "/auth/home" },
  { title: "Évolution", href: "/auth/evolution" },
  { title: "Notifications", href: `/auth/notifications` },
].map((item, index) => (
  <Anchor
    component={Link}
    to={item.href}
    key={index}
    style={{ color: "#333", fontSize: 14 }}
  >
    {item.title}
  </Anchor>
));

const EvolutionScreen = () => {
  const user: any = storage.getUser();
  const { data, loading, request } = useApi(evolution.collectionNotifs);
  const [evolutions, setEvolutions] = useState<any[]>([]);

  useEffect(() => {
    request(user?.id).then((res) => {
      console.log(res);
    });
  }, []);
  

  useEffect(() => {
    if(data && data.length > 0) {

      setEvolutions(data);
    }
  }, [data]);

  

  return (
    <>
      <LoadingOverlay visible={false} />
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl ">
          <span className="font-bold text-xl">NOTIFICATIONS</span>
          <br />
          <span className="text-3xl">EVOLUTION</span>
        </div>
        <div className="mt-6 md:mt-10 text-xl ">
          <span className=" text-2xl">
            VOTER POUR LES PASSAGES DE GROUPE DE VOS FILLEULS
          </span>
        </div>
        <div className="mt-3 md:mt-3 text-xl ">
          {evolutions.length > 0 ? (
            evolutions.map((evolution: any) => (
              <Item
                onEnd={(id: number) =>
                  setEvolutions(
                    evolutions.filter((evolution: any) => evolution.id !== id),
                  )
                }
                user={user}
                evolution={evolution}
              />
            ))
          ) : (
            <span className="text-sm font-bold pt-36">
              Aucune évolution disponible
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default EvolutionScreen;
