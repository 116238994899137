import React, {useEffect} from "react";
import GoogleMapReact from 'google-map-react';
import {Marker} from "google-maps-react";
import {FaMapMarkerAlt} from "react-icons/all";
import useApi from "../../hooks/useApi";
import users from "../../api/users";
import { REACT_APP_GOOGLE_MAP_API_KEY } from "../../env";


const AnyReactComponent = ({text}: any) => <div><FaMapMarkerAlt className="mx-auto" size="10" color={"red"} /></div>;


const AppMap = () => {

    const {data: userData, loading: userLoading, request: userRequest,} = useApi(users.getAllCoords);

    useEffect(() => {
        userRequest();
    }, []);

    useEffect(() => {
        console.log("coords",userData)
    }, [userData]);

    return (
        <div style={{height: '50%', width: '100%'}}>
            <GoogleMapReact
                bootstrapURLKeys={{key: REACT_APP_GOOGLE_MAP_API_KEY}}
                defaultCenter={{
                    lat: 48.864716,
                    lng: 2.349014 
                }}
                defaultZoom={5}
            >
            {userData && userData.map((user: any) => (
                <AnyReactComponent
                    lat={user.lat}
                    lng={user.lng}
                    text="1978"
                />))}
            </GoogleMapReact>
        </div>
    )
}

export default AppMap
