export const prefixTelephone =[
    '+33',
    '+49',
    '+44',
    '+34',
    '+39',
    '+41',
    '+351',
    '+31',
    '+32',
    '+212',
    '+216',
    '+213',
    '+353',
    '+225',
    '+223',
    '+45',
    '+7',
    '+86',
    '+52',
    '+420',
    '+373',
    '+356',
    '+352',
    '+387',
    '+375',
    '+47',
    '+359',
    '+380',
    '+421',
];
