import React, { useEffect, useState } from "react";
import {
  Anchor,
  Breadcrumbs,
  Textarea,
  TextInput,
  Button,
  LoadingOverlay,
  Transition,
  NumberInput,
  Group,
} from "@mantine/core";
import useApi from "../../hooks/useApi";
import users from "../../api/users";
import contact from "../../api/contact";
import Footer from "../Footer";
import { useForm } from "@mantine/hooks";
import RichTextEditor from "@mantine/rte";
import "dayjs/locale/fr";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useNotifications } from "@mantine/notifications";
import {EmailService} from "../../service/email";
import {
  Dropzone,
  IMAGE_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
} from "@mantine/dropzone";
import { MdUploadFile } from "react-icons/md";
import { 
  REACT_APP_CONTACT_DESTINATION_NAME,
  REACT_APP_CONTACT_DESTINATION_EMAIL 
} from "../../env";

const Contact = () => {
  const [value, setValue] = useState<[Date, Date]>();
  const [userInfo, setUserInfo] = useState<any>(null);
  const { data, request } = useApi(users.me);
  const { request: requestContact } = useApi(contact.send);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const notifications = useNotifications();
  const [iconColor, seticonColor] = useState("gray");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    setUserInfo(data);
  }, [data]);

  // console.log(userInfo)
  const emailService = new EmailService();

  const form = useForm({
    initialValues: {
      UserId: userInfo?.id,
      title: "",
      message: "",
      file: [],
    },

    // validationRules: {

    // },
  });

  const handleSubmit = async (values: any) => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
    if(form.values.file.length>0){
      // @ts-ignore
      const type = form.values.file[0].type.split('/').pop();
      emailService
          .sendEmail({
            htmlContent: '<p>' + form.values.message + '</p>',
            sender: { name: userInfo?.lastName, email: userInfo?.email },
            to: [{ name: REACT_APP_CONTACT_DESTINATION_NAME, email: REACT_APP_CONTACT_DESTINATION_EMAIL }],
            subject: 'Message Application Forman',
            attachment: [{name: type, content: await toBase64(form.values.file[0])}]
          }).then(() => {
          setIsValid(true)
      })

    }else{
      emailService
          .sendEmail({
            htmlContent: '<p>' + form.values.message + '</p>',
            sender: { name: userInfo?.lastName, email: userInfo?.email },
            to: [{ name: REACT_APP_CONTACT_DESTINATION_NAME, email: REACT_APP_CONTACT_DESTINATION_EMAIL }],
            subject: 'Message Application Forman',
          }).then(() => {
        setIsValid(true)
      })
    }
  };

  function UpdateFile(value: []) {
    form.setFieldValue("file", value);
    seticonColor("green");
  }

  function RejectFile(value: []) {
    console.log("Erreur le fichier n'a pas pu s'upload : ", value);
    seticonColor("red");
  }

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Contact", href: "/auth/ContactApp" },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  return (
    <>
      {/* <LoadingOverlay visible={loading} /> */}
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl">CONTACTEZ-NOUS</div>
        {isValid && (<div className="mt-6 text-green-500 text-xl">Message envoyé avec succès</div>)}
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <div className="flex flex-col gap-3 mt-3 flex-wrap">
            <TextInput
              className="w-52"
              value={userInfo && userInfo.firstName}
              disabled
            ></TextInput>
            <TextInput
              className="w-52"
              value={userInfo && userInfo.lastName}
              disabled
            ></TextInput>
            <TextInput
              className="w-52"
              value={userInfo && userInfo.email}
              disabled
            ></TextInput>
            <TextInput
              className="w-52"
              value={userInfo && userInfo.phoneNumber}
              disabled
            ></TextInput>
          </div>
          <TextInput
            className="w-96 my-4"
            placeholder="Titre du message"
            required
            value={form.values.title}
            onChange={(event) =>
              form.setFieldValue("title", event.currentTarget.value)
            }
          ></TextInput>
          <h3>Votre message :</h3>
          <RichTextEditor
            className="h-64"
            placeholder="Votre message"
            value={form.values.message}
            onChange={(event) => form.setFieldValue("message", event)}
          />
          <Dropzone
            className="mt-6"
            onDrop={(value: []) => UpdateFile(value)}
            multiple={false}
            maxSize={5 * 1024 ** 2}
            accept={IMAGE_MIME_TYPE || PDF_MIME_TYPE || MS_WORD_MIME_TYPE}
            onReject={(value: []) => RejectFile(value)}
          >
            {() => (
              <Group
                position="center"
                spacing="xl"
                style={{ minHeight: 50, pointerEvents: "none" }}
              >
                <div className="flex items-center gap-5">
                  <MdUploadFile size="40" color={iconColor} />
                  <div className="text-sm">
                    Faites glisser ou cliquez pour joindre une image ou un
                    fichier, ne dépassant pas 5 Mo
                    <p id="ImageError"></p>
                  </div>
                </div>
              </Group>
            )}
          </Dropzone>
          <Button
            mt={20}
            mr={5}
            className="mr-auto px-5 mb-5"
            size="md"
            color="pink"
            type="submit"
          >
            Envoyer
          </Button>
        </form>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
