import React, { useEffect } from "react";
import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  LoadingOverlay,
  ScrollArea,
  Table,
} from "@mantine/core";
import ItemJd from "../../../components/JD/ItemJd";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import workshop from "../../../api/workshop";
import news from "../../../api/news";
import { GearIcon } from "@modulz/radix-icons";
import { AiOutlineSearch, FaCheck, FaTimes } from "react-icons/all";
import { format_ddMMYYYY } from "../../../utility/dateUtility";

const items = [
  { title: "Accueil", href: "/auth/home" },
  { title: "Actualités", href: `/auth/news` },
  { title: "Notifications", href: `/auth/home` },
].map((item, index) => (
  <Anchor
    component={Link}
    to={item.href}
    key={index}
    style={{ color: "#333", fontSize: 14 }}
  >
    {item.title}
  </Anchor>
));

const NewsScreen = () => {
  const { data, request } = useApi(news.getNotValidate);
  const { request: requestValidate } = useApi(news.validate);
  const { request: requestNotValidate } = useApi(news.notValidate);

  useEffect(() => {
    request();
  }, []);
  const navigation = useNavigate();

  const handleValidate = (id: number) => {
    requestValidate(id).then((value) => {
      request(id).then(() => {
        request();
      });
    });
  };

  const handleNotValidate = (id: number) => {
    requestValidate(id).then((value) => {
      request(id).then(() => {
        request();
      });
    });
  };

  const rows = data.map((element: any) => (
    <tr key={element.id}>
      <td>
        {format_ddMMYYYY(element.publishAt)}
      </td>
      <td>{element.title}</td>
      <td>{element.description.slice(0, 130)}</td>
      <td>
        <div className="flex">
          <ActionIcon
            onClick={() =>
              navigation("/auth/news/detail/" + element.id, { replace: true })
            }
            className="bg-blue-800"
            color="blue"
            variant="filled"
          >
            <AiOutlineSearch />
          </ActionIcon>
          <ActionIcon
            onClick={() => handleValidate(element.id)}
            className="ml-10"
            color="green"
            variant="filled"
          >
            <FaCheck />
          </ActionIcon>
          <ActionIcon
            onClick={() => handleNotValidate(element.id)}
            className="ml-3"
            color="red"
            variant="filled"
          >
            <FaTimes />
          </ActionIcon>
        </div>
      </td>
    </tr>
  ));

  return (
    <>
      <LoadingOverlay visible={false} />
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl ">
          <span className="font-bold text-xl">NOTIFICATIONS</span>
          <br />
          <span className="text-3xl">ACTUALITÉS</span>
        </div>
        <div className="mt-6 md:mt-10 text-xl ">
          <span className=" text-2xl">
            LISTE DES ACTUALITÉS ATTENDANT UNE VALIDATION
          </span>
        </div>
        <ScrollArea type="always">
          <div className="mt-3 md:mt-3 text-xl" style={{ minWidth: 900 }}>
            <Table striped>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Titre</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        </ScrollArea>
      </div>
    </>
  );
};

export default NewsScreen;
