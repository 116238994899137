import React, { Fragment } from 'react';
import logo from "../../assets/images/picto_FM.png";
import tri from "../../assets/images/illus-header1-2.png";
import tri2 from "../../assets/images/illus-header1-1.png";
import { CheckIcon } from "@heroicons/react/solid";
import { CalendarIcon } from "@modulz/radix-icons";
import { Button } from '@mantine/core';
import { FaCalendarAlt, FaRegCalendarAlt, FaWalking } from "react-icons/fa";
import { CgCalendar } from "react-icons/all";
import illus_3 from "../../assets/images/illus-header1-3.png";
import logo_fm_white from "../../assets/images/logo_FM-white.png";
import { Link } from "react-router-dom";
import "./local_successFull.css";
import { useWindowScroll } from '@mantine/hooks';
import { GrLinkTop } from "react-icons/gr";

const SuccessFull = () => {
    const [scroll, scrollTo] = useWindowScroll();

    return (
        <Fragment>
            <div className='w-screen md:w-auto'>

                <div className="fullContainer text-center flex content-center flex-col justify-items-center mx-auto">

                    <img src={tri} className="hidden lg:block sm:absolute right-6 top-40 openingIllustration" alt="" />
                    <img src={tri2} className="hidden lg:block sm:absolute left-6 w-48 bottom-20 openingIllustration" alt="" />

                    <div className="formanLogoTop"><img src={logo} alt="FORMAN (LOGO)" /></div>

                    <div className="titleContainer">
                        <h3 className="mainTitle uppercase">VOTRE INSCRIPTION S'EST PARFAITEMENT DÉROULÉE.</h3>
                    </div>

                    <div className="successIcon flex flex-col items-center rounded-full">
                        <CheckIcon className="flex m-1 forceColor-succes-green rounded-full" color="#fff" />
                    </div>

                    <div className="successMessageContainer">
                        <h4 className="successMessageTitle uppercase">VOUS ÊTES MAINTENANT CONNECTÉ</h4>
                        <p className="successMessageText">
                            La personne qui vous a invité a été prévenue <br />
                            de votre inscription. Il ne vous reste plus qu'à <br />
                            choisir la date de votre réunion d'information.
                        </p>
                    </div>

                    <div className="my-5">
                        <Button color="pink" component={Link} to="/auth/workshop" compact size="lg" className="border-none shadow text-white h-32" variant="filled">
                            <span><FaRegCalendarAlt className="mx-auto opacity-30" size={40} />
                                <span className="text-sm block mt-3 font-bold">Sélectionner votre réunion d'information</span></span>
                        </Button>
                    </div>

                    <GrLinkTop onClick={() => scrollTo({ y: 0 })} color='blue' className='mx-auto text-3xl mt-9 mb-8' />

                </div>
                <div className="bg-gray-500 relative">
                    <img src={illus_3} className="absolute top-0 transform left-0 w-1/12 opacity-10" alt="" />
                    <img src={illus_3} className="absolute bottom-10 transform rotate-90 right-48 w-1/12 opacity-10" alt="" />
                    <div className="container mx-auto p-5">
                        <div className="text-center md:flex py-10">
                            <div className="md:flex-1">
                                <img className="w-1/2 mx-auto mb-16 md:mb-0" src={logo_fm_white} alt="logo-forman" />
                            </div>
                            <div className="md:flex-1 mb-10 md:mb-0">
                                <span className="text-white text-3xl font-bold">500 +</span>
                                <br />
                                <span className="text-gray-200 text-sm">Adhérents</span>
                            </div>
                            <div className="md:flex-1 mb-10 md:mb-0">
                                <span className="text-white text-3xl font-bold">6000 +</span>
                                <br />
                                <span className="text-gray-200 text-sm">Clients</span>
                            </div>
                            <div className="md:flex-1">
                                <span className="text-white text-3xl font-bold">12</span>
                                <br />
                                <span className="text-gray-200 text-sm">Ans</span>
                            </div>
                        </div>
                        <div className="md:flex my-3 md:justify-center">
                            <div className="md:flex md:mx-1 text-center mb-4 md:mb-0">
                                <Link className="text-gray-100" to="/">
                                    Contactez-nous
                                </Link>
                            </div>
                            <div className="md:flex md:mx-1 text-center mb-4 md:mb-0">
                                <Link className="text-gray-100" to="/">
                                    CGU
                                </Link>
                            </div>
                            <div className="md:flex md:mx-1 text-center mb-4 md:mb-0">
                                <Link className="text-gray-100" to="/">
                                    CNIL
                                </Link>
                            </div>
                            <div className="md:flex md:mx-1 text-center mb-4 md:mb-0">
                                <Link className="text-gray-100" to="/">
                                    Mentions Légales
                                </Link></div>
                        </div>
                        <span
                            className=" flex justify-center text-gray-200 text-xs">Développé par Appyness et designé par Caléis</span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SuccessFull;
