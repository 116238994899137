import request from './request';

const endpoint = '/thematics';

const collection = (type: string) => {
  return request.get(endpoint + '?type=' + type);
};

const all = () => {
  return request.get(endpoint);
};

const create = (thematic: any) => {
  return request.post(endpoint, {
    wording: thematic.wording,
    prerequisite: thematic.prerequisite,
    formerWords: thematic.formerWords,
    destined: thematic.destined,
    habilitation: thematic.habilitation,
    place: thematic.place,
    placeZoom: thematic.placeZoom,
    theme: thematic.theme,
    type: thematic.type,
  });
};

const edit = (thematic: any, id: number) => {
  return request.put(endpoint + '/' + id, {
    wording: thematic.wording,
    prerequisite: thematic.prerequisite,
    formerWords: thematic.formerWords,
    destined: thematic.destined,
    habilitation: thematic.habilitation,
    place: thematic.place,
    placeZoom: thematic.placeZoom,
    theme: thematic.theme,
    type: thematic.type,
  });
};

const remove = (id: number) => {
  return request.put(endpoint + '/' + id, {
    wording: 'Thematic supprimé',
  });
};

export default { collection, all, create, edit, remove };
