import React from 'react';
import {ActionIcon, Button} from "@mantine/core";
import {AiOutlineSearch, FaCheck, FaTimes, GiTicket} from "react-icons/all";
import {useNavigate} from "react-router-dom";
import storage from "../../auth/storage";
import workshop from "../../api/workshop";


interface IWorkshopCardNotifItemProps {
    workshop: any;
    onValidate: any;
    onDelete: any;
}

const WorkshopCardNotifItem = (props: IWorkshopCardNotifItemProps) => {
    const navigation = useNavigate();
    const {id}: any = storage.getUser();
    return (
        <div key={props.workshop.id} className="flex flex-wrap bg-white rounded mt-5 shadow gap-x-16 lg:gap-x-80 gap-y-4 p-5">
            <div className="flex flex-col gap-2">
                <div className="">
                    <span className="uppercase">{props.workshop.title}</span>
                </div>
                <div className="flex flex-wrap">
                    <span className="text-gray-500 text-sm mr-1">
                        Le <span className="font-bold uppercase">{props.workshop.date}</span>
                    </span>
                    <span className="text-gray-500 text-sm">
                        à <span className="font-bold uppercase">{props.workshop.location}</span>
                    </span>
                </div>
            </div>
            <div className="text-center">
                <h2 className="font-bold text-sm text-gray-800 block">CANDIDATS COPILOTE</h2>
                <div className="flex flex-col">
                    {props.workshop.users.map((guest: any) => (
                        <div key={guest.id} className=" text-left inline-flex mt-2">
                            <span className="text-gray-500 text-sm">{guest.firstName} {guest.lastName} <span className="ml-2 p-1 bg-gray-500 rounded-md text-xs text-white">{guest.role.slice(5)}</span></span>
                            <div className="ml-auto flex-row flex">
                                <ActionIcon onClick={() => props.onValidate(props.workshop.id, guest.id)} className="ml-3" size={22} color="green" variant="filled">
                                    <FaCheck size={10} />
                                </ActionIcon>
                                <ActionIcon onClick={() => props.onDelete(props.workshop.id, guest.id)} className="ml-3" size={22} color="red" variant="filled">
                                    <FaTimes size={10} />
                                </ActionIcon>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WorkshopCardNotifItem;
