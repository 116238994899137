import {ActionIcon} from '@mantine/core';
import React, {Fragment, useEffect, useState} from 'react';
import {URL} from "../../constants/URL";
import {GearIcon} from "@modulz/radix-icons";
import {BiLike} from "react-icons/all";
import {Link} from "react-router-dom";
import useApi from "../../hooks/useApi";
import news from "../../api/news";
import storage from "../../auth/storage";
import logo from "../../assets/images/logo_FM.png"
import { format_d_MMMM_YYYY } from "../../utility/dateUtility";

interface Props {
    news: any;
}

const ItemListNews = (props: Props) => {
    const [isLiked, setIsLiked] = useState(false);
    const {request: requestLike} = useApi(news.like);
    const {request: requestDisLike} = useApi(news.dislike);
    const user: any = storage.getUser();

    useEffect(() => {
        console.log(!!props.news?.likes.find((guest: any) => guest.id === user?.id));
        setIsLiked(!!props.news?.likes.find((guest: any) => guest.id === user?.id))
    }, [props?.news])


    const like = () => {
        requestLike(props?.news?.id).then((value) => {
            setIsLiked(true);
        });
    }

    const dislike = () => {
        requestDisLike(props?.news?.id).then((value) => {
            setIsLiked(false);
        });
    }

    return (
        <>
            {window.innerWidth > 768 ?
                (
                    <div className="md:flex md:flex-wrap mx-auto mb-5 shadow overflow-hidden bg-white rounded-lg dark:bg-gray-800">
                        <Link to={'/auth/news/detail/' + props?.news?.id} className="w-10/12 md:flex md:flex-wrap">
                            {props?.news?.fileUrl ? (
                                <div className="md:w-2/12 justify-center">
                                    <img className="justify-center w-full" src={URL + props?.news?.fileUrl} alt=""/>
                                </div>
                            ) :
                                <div className="md:w-2/12 justify-center">
                                <img className="justify-center w-full" src={logo} alt=""/>
                            </div>}
                            <div className="ml-6 md:p-4 p-2 w-9/12">
                                <h3 className="text-gray-600 font-bold text-xs">{props?.news?.category}</h3>
                                <h1 className="text-xl uppercase text-gray-800 dark:text-white">{props?.news?.title}</h1>
                            </div>
                        </Link>

                        <div className="md:w-2/12 px-2 md:p-4">
                            <h1 className="text-sm text-right text-gray-500 dark:text-white md:mb-3">{props?.news?.author?.firstName} {props?.news?.author?.lastName}</h1>
                            <h4
                                className="text-sm text-gray-500 text-right md:mb-3">{format_d_MMMM_YYYY(props?.news?.publishAt)}</h4>
                            <div className='float-right'>
                                {!isLiked ? (
                                    <ActionIcon onClick={() => like()} size="lg"
                                                className="justify-center mx-auto bg-gray-500 hover:bg-green-500">
                                        <BiLike size="20" color="white"/>
                                    </ActionIcon>
                                ) : (
                                    <ActionIcon onClick={() => dislike()} size="lg"
                                                className="justify-center mx-auto bg-green-500 hover:bg-green-700">
                                        <BiLike size="20" color="white"/>
                                    </ActionIcon>
                                )}
                            </div>
                        </div>
                    </div>)
                : (
                    <div className="flex mx-auto my-5 shadow overflow-hidden bg-white rounded-lg dark:bg-gray-800 p-3">
                        {props?.news?.fileUrl ? (
                            <Link to={'/auth/news/detail/' + props?.news?.id}>
                                <img className="w-full" src={URL + props?.news?.fileUrl} alt=""/>
                            </Link>
                        ) : null}
                        <Link to={'/auth/news/detail/' + props?.news?.id} className='flex flex-col w-11/12'>
                            <h3 className="text-gray-600 font-bold mb-1 text-xs">{props?.news?.category}</h3>
                            <h1 className="text-2xl mb-1 uppercase dark:text-white break-words">{props?.news?.title}</h1>
                            <div className='flex'>
                                <h1 className="text-sm text-gray-500 dark:text-white mr-4">{props?.news?.author?.firstName} {props?.news?.author?.lastName}</h1>
                                <h4
                                    className="text-sm text-gray-500">{format_d_MMMM_YYYY(props?.news?.publishAt)}</h4>
                            </div>
                            <div
                                dangerouslySetInnerHTML={{
                                __html: props?.news?.description.slice(0, 300) + '...'
                            }}/>
                        </Link>
                        <div className='w-1/12 mt-2 ml-2'>
                            {!isLiked ? (
                                <ActionIcon onClick={() => like()} size="lg"
                                            className="justify-center mx-auto bg-gray-500 hover:bg-green-500">
                                    <BiLike size="20" color="white"/>
                                </ActionIcon>
                            ) : (
                                <ActionIcon onClick={() => dislike()} size="lg"
                                            className="justify-center mx-auto bg-green-500 hover:bg-green-700">
                                    <BiLike size="20" color="white"/>
                                </ActionIcon>
                            )}
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default ItemListNews;
