export const ROLES = [
    {value: 'All', label: "TOUS"},
    {value: 'ROLE_GUEST', label: 'INVITÉS'},
    {value: 'ROLE_ONEMAN', label: 'ONEMAN'},
    {value: 'ROLE_NEOMAN', label: 'NEOMAN'},
    {value: 'ROLE_BEMAN', label: 'BEMAN'},
    {value: 'ROLE_ADMAN', label: 'ADMAN'},
    {value: 'ROLE_MAN', label: 'MAN'},
    {value: 'ROLE_DEVMAN', label: 'DEVMAN'},
    {value: 'ROLE_DXMAN', label: 'DXMAN'},
    {value: 'ROLE_XMAN', label: 'XMAN'},
];

export const ROLES_WOUT_GUEST = [
    {value: 'All', label: "TOUS"},
    {value: 'ROLE_ONEMAN', label: 'ONEMAN'},
    {value: 'ROLE_NEOMAN', label: 'NEOMAN'},
    {value: 'ROLE_BEMAN', label: 'BEMAN'},
    {value: 'ROLE_ADMAN', label: 'ADMAN'},
    {value: 'ROLE_MAN', label: 'MAN'},
    {value: 'ROLE_DEVMAN', label: 'DEVMAN'},
    {value: 'ROLE_DXMAN', label: 'DXMAN'},
    {value: 'ROLE_XMAN', label: 'XMAN'},
];

    export const ROLES_WOUT_GUEST_TOUS = [
    {value: 'ROLE_ONEMAN', label: 'ONEMAN'},
    {value: 'ROLE_GUEST', label: 'INVITE'},
    {value: 'ROLE_NEOMAN', label: 'NEOMAN'},
    {value: 'ROLE_BEMAN', label: 'BEMAN'},
    {value: 'ROLE_ADMAN', label: 'ADMAN'},
    {value: 'ROLE_MAN', label: 'MAN'},
    {value: 'ROLE_DEVMAN', label: 'DEVMAN'},
    {value: 'ROLE_DXMAN', label: 'DXMAN'},
    {value: 'ROLE_XMAN', label: 'XMAN'},
];

export const ROLES_WITH_EXPERT_TOUS = [
    {value: 'ROLE_ONEMAN', label: 'ONEMAN'},
    {value: 'ROLE_GUEST', label: 'INVITE'},
    {value: 'ROLE_NEOMAN', label: 'NEOMAN'},
    {value: 'ROLE_BEMAN', label: 'BEMAN'},
    {value: 'ROLE_ADMAN', label: 'ADMAN'},
    {value: 'ROLE_MAN', label: 'MAN'},
    {value: 'ROLE_DEVMAN', label: 'DEVMAN'},
    {value: 'ROLE_DXMAN', label: 'DXMAN'},
    {value: 'ROLE_XMAN', label: 'XMAN'},
    {value: 'ROLE_EXPERT_ACCOUNTANT', label: 'EXPERT COMPTABLE'},
];

export const ROLE_WITHOUT_TOUS = [
    'ROLE_GUEST',
    'ROLE_ONEMAN',
    'ROLE_NEOMAN',
    'ROLE_BEMAN',
    'ROLE_ADMAN',
    'ROLE_MAN',
    'ROLE_DEVMAN',
    'ROLE_DXMAN',
    'ROLE_XMAN',
    
];

export const ROLE_WITHOUT_TOUS_NEWS = [
    'ROLE_ONEMAN',
    'ROLE_NEOMAN',
    'ROLE_BEMAN',
    'ROLE_ADMAN',
    'ROLE_MAN',
    'ROLE_DEVMAN',
    'ROLE_DXMAN',
    'ROLE_XMAN',

];

export const ROLE_GUEST = [
    'ROLE_GUEST'
];

export const ROLE_ONEMAN = [
    'ROLE_ONEMAN'
];

export const ROLE_NEOMAN = [
    'ROLE_NEOMAN'
];

export const ROLE_BEMAN = [
    'ROLE_BEMAN'
];

export const ROLE_ADMAN = [
    'ROLE_ADMAN'
];

export const ROLE_MAN = [
    'ROLE_MAN'
];

export const ROLE_DEVMAN = [
    'ROLE_DEVMAN'
];

export const ROLE_DXMAN = [
    'ROLE_DXMAN'
];

export const ROLE_DXMAN_MORE = [
    'ROLE_DXMAN',  'ROLE_XMAN'
];

export const ROLE_XMAN = [
    'ROLE_XMAN'
];

export const ROLE_NEOMAN_MORE = [
    'ROLE_NEOMAN',
    'ROLE_BEMAN',
    'ROLE_ADMAN',
    'ROLE_MAN',
    'ROLE_DEVMAN',
    'ROLE_DXMAN',
    'ROLE_XMAN',
];

export const ROLE_BEMAN_MORE = [
    'ROLE_BEMAN',
    'ROLE_ADMAN',
    'ROLE_MAN',
    'ROLE_DEVMAN',
    'ROLE_DXMAN',
    'ROLE_XMAN',
];

export const ROLE_ALL = [
    'ROLE_XMAN',
    'ROLE_DXMAN',
    'ROLE_DEVMAN',
    'ROLE_ADMAN',
    'ROLE_NEOMAN',
    'ROLE_DXMAN',
    'ROLE_MAN',
    'ROLE_BEMAN',
    'ROLE_ONEMAN',
    'ROLE_GUEST',
];

