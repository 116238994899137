import ItemWorkshop from '../../../../templates/Connected/Workshop/ItemWorkshop';

const WorkshopPaymentSimulation = () => {

    return (
        <div>
            <ItemWorkshop />
        </div>
    )

}

export default WorkshopPaymentSimulation;
