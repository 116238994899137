import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/hooks";
import { useNotifications } from "@mantine/notifications";
import useApi from "../../../hooks/useApi";
import event from "../../../api/event";
import dayjs from "dayjs";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Chips,
  Col,
  LoadingOverlay,
  MultiSelect,
  NumberInput,
  Select,
  SimpleGrid,
  Textarea,
  TextInput,
  RadioGroup,
  Radio,
} from "@mantine/core";
import { DatePicker, DateRangePicker, TimeInput } from "@mantine/dates";
import storage from "../../../auth/storage";
import "dayjs/locale/fr";
import { Link, useNavigate } from "react-router-dom";
import { ROLE_WITHOUT_TOUS, ROLES } from "../../../constants/roles";
import {
  HABILITATION,
  HABILITATION_WITHOUT_TOUS,
} from "../../../constants/habilitation";
import { isVoidExpression } from "typescript";
import Footer from "../../Footer";
import users from "../../../api/users";
import eventDate from "../../../api/eventDate";
import { RichTextEditor } from "@mantine/rte";
import thematic from "../../../api/thematic";
import site from "../../../api/site";
import { handleImageUpload } from "../../../utility/imageUpload";

const CreateEvent = () => {
  const [value, setValue] = useState<[Date, Date]>();
  const notifications = useNotifications();
  const [loading, setLoading] = useState(false);
  const [displayButton, setDisplayButton] = useState(true);
  const navigation = useNavigate();
  const [disabledSite, setdisabledSite] = useState(false);
  const [disabledPlace, setdisabledPlace] = useState(false);
  // @ts-ignore
  const user: any = storage.getUser();
  const [thematics, setThematics] = useState([]);
  const [sites, setSites] = useState([]);
  const [thematicSelected, setThematicSelected] = useState<number>(0);
  const { request } = useApi(event.create);

  const { request: requestThematics, data: dataThematics } = useApi(
    thematic.collection,
  );

  const { request: requestSite, data: dataSite } = useApi(site.collection);

  useEffect(() => {
    window.scrollTo(0, 0);
    requestSite().then((value) => {
      setSites(
        value.data.map((site: any) => {
          return {
            label: site.name,
            value: site.id,
          };
        }),
      );
    });
  }, []);

  const { request: requestDate } = useApi(eventDate.create);

  const [formValues, setFormValues] = useState<any>([]);

  const { request: requestUser } = useApi(users.getUserByEmail);

  const handleChange = (i: any, e: any, name: any) => {
    let formValues1 = formValues;
    // @ts-ignore
    formValues1[i][name] = e;
    setFormValues(formValues1);
  };

  useEffect(() => {
    if (value && value[0] && value[1]) {
      const startAt = value[0];
      const endAt = value[1];
      // @ts-ignore
      const days = dayjs(endAt).diff(dayjs(startAt), "day");

      const tab: any = [];
      for (let i = 0; i < days + 1; i++) {
        tab.push({
          startAt: new Date("2022-01-01T09:00:00"),
          endAt: new Date("2022-01-01T18:00:00"),
        });
      }
      setFormValues([...tab]);
      form.setFieldValue("startDate", value[0]);
      form.setFieldValue("endDate", value[1]);
    }
  }, [value]);

  const handleSubmit = async (values: any) => {
    if (form.validate()) {
      setLoading(true);

      await request({
        ...values,
        former: user.id,
      })
        .then(async (values) => {
          const eventId = values.data.id;
          for (let i = 0; i < formValues.length; i++) {
            await requestDate(
              dayjs(formValues[i].startAt).add(1, "hour").toDate(),
              eventId,
              dayjs(formValues[i].endAt).add(1, "hour").toDate(),
            );
          }

          notifications.showNotification({
            title: "Nice !",
            message: "Vous avez crée un évenement ! 😀",
            color: "green",
          });
          navigation("/auth/event/detail/" + eventId, { replace: true });
        })
        .catch((values) => {
          notifications.showNotification({
            title: "Une erreur est survenue.",
            message: "Impossible de creer un évenement ! 🤥",
            color: "red",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const form = useForm({
    initialValues: {
      title: "",
      price: "",
      place: "",
      description: "",
      prerequisite: "",
      formerWords: "",
      videoConferenceLink: "",
      maxGuests: 0,
      minGuests: 0,
      startDate: new Date(),
      endDate: new Date(),
      visioPassword: "",
      isVisio: "false",
      destined: [],
      habilitation: [],
      externLink: "",
      priceBedOne: 0,
      priceBedTwo: 0,
      priceBedThree: 0,
      site: "",
    },

    validationRules: {
      title: (value) => value?.length >= 3,
      price: (value) => !isNaN(parseFloat(value)),
      site: (value, values) =>
        values?.isVisio == "false"
          ? (value != "" && value != null) || values?.place != ""
          : true,
      videoConferenceLink: (value, values) =>
        values?.isVisio == "true" ? value != "" : true,
      maxGuests: (value) => value > 0,
      minGuests: (value) => value > 0,
      startDate: () =>
        !value || value[0] == null || value[1] == null ? false : true,
    },
  });

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Évenements", href: "/auth/event" },
    { title: "Création d'un évenement", href: "/auth/event/create" },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  useEffect(() => {
    // @ts-ignore
    if (form.values.destined.includes("All")) {
      // @ts-ignore
      form.setFieldValue("destined", ROLE_WITHOUT_TOUS);
    }
  }, [form.values.destined]);

  useEffect(() => {
    // @ts-ignore
    if (form.values.habilitation.includes("All")) {
      // @ts-ignore
      form.setFieldValue("habilitation", HABILITATION_WITHOUT_TOUS);
    }
  }, [form.values.habilitation]);

  useEffect(() => {
    if (parseInt(form.values.site) > 0) {
      setdisabledPlace(true);
    } else {
      setdisabledPlace(false);
    }
  }, [form.values.site]);

  useEffect(() => {
    if (form.values.place != "") {
      setdisabledSite(true);
    } else {
      setdisabledSite(false);
    }
  }, [form.values.place]);

  useEffect(() => {
    if (form.values.isVisio == "true") {
      form.setFieldValue("place", "");
      form.setFieldValue("site", "");
    } else {
      form.setFieldValue("videoConferenceLink", "");
      form.setFieldValue("visioPassword", "");
    }
  }, [form.values.isVisio]);

  window.onscroll = () => {
    //@ts-ignore
    if (window.scrollY + window.innerHeight < document.getElementById("SubmitFix")?.offsetTop + document.getElementById("SubmitFix")?.offsetHeight
    ) {
      setDisplayButton(true);
    } else {
      setDisplayButton(false);
    }
  };

  return (
    <div className="pb-12 pt-6 md:pt-12 pl-4 pr-6">
      <div className="mx-auto md:px-4 sm:px-6 lg:px-8">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <h2 className="text-3xl mt-6 md:mt-10">Création d'un événement</h2>
        <p className="max-w-3xl my-5">
          Vous êtes sur le point de créer un nouvel évenement : merci !<br /> Il
          vous suffit de remplir les champs suivants.
        </p>

        <div className="mt-10">
          <LoadingOverlay visible={loading} />
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <div className="md:flex md:justify-between">
              <div className="md:w-7/12">
                <div>
                  <h3 className="text-2xl">Informations générales</h3>
                  <div className="flex flex-row  mt-4 gap-20">
                    {/* <Select
                                            className='md:w-4/5'
                                            placeholder={window.innerWidth > 450 ? "Choix de thématique" : "La thématique"}
                                            searchable
                                            clearable
                                            data={thematics}
                                            onChange={(value: string) => setThematicSelected(parseInt(value))}
                                        /> */}
                    <TextInput
                      className="md:w-3/5 md:mr-8 mb-2 md:mb-0"
                      placeholder="Titre"
                      error={
                        form.errors.title &&
                        "Veuillez spécifier un titre valide"
                      }
                      value={form.values.title}
                      onChange={(event) =>
                        form.setFieldValue("title", event.currentTarget.value)
                      }
                      required
                    />
                    <TextInput
                      required
                      className="md:w-2/5"
                      placeholder="Prix €"
                      error={
                        form.errors.price && "Veuillez spécifier un prix valide"
                      }
                      value={form.values.price}
                      onChange={(event) =>
                        form.setFieldValue("price", event.currentTarget.value)
                      }
                    />
                    {/* modifié la taille du select pour l'ajuster au multiSlect*/}
                  </div>
                </div>
                <div className="my-10">
                  <h3 className="text-2xl mb-3">Dates & Lieu</h3>
                  <DateRangePicker
                    className="mb-4"
                    locale="fr"
                    allowSingleDateInRange
                    inputFormat="DD/MM/YYYY"
                    labelFormat="MM/YYYY"
                    placeholder="Date"
                    minDate={new Date()}
                    maxDate={new Date(2030, 11, 31)}
                    error={
                      form.errors.startDate &&
                      "Veuillez spécifier une date valide"
                    }
                    value={value}
                    onChange={(event: [Date, Date]) => setValue(event)}
                    required
                  />
                  <div className="flex gap-3">
                    <div className="grid grid-cols-2 gap-4 w-full">
                      {formValues.map((element: any, index: any) => (
                        <>
                          <div className="col-span-1">
                            <TimeInput
                              name="startAt"
                              required
                              placeholder="Horaires de début"
                              value={element.startAt}
                              onChange={(e) =>
                                handleChange(index, e, "startAt")
                              }
                            />
                          </div>
                          <div className="col-span-1">
                            <TimeInput
                              name="endAt"
                              required
                              placeholder="Horaires de fin / Jour 1"
                              value={element.endAt}
                              onChange={(e) => handleChange(index, e, "endAt")}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Chips
                      value={form.values.isVisio}
                      onChange={(event: string) =>
                        form.setFieldValue("isVisio", event)
                      }
                      className="flex justify-center my-5 w-full"
                      color="indigo"
                      variant="filled"
                      size="xl"
                      radius="sm"
                    >
                      <Chip value="false">Evénement physique</Chip>
                      <Chip value="true">Evénement à distance</Chip>
                    </Chips>
                  </div>

                  {form.values.isVisio === "false" && (
                    <>
                      <div className="flex justify-between flex-wrap">
                        <Select
                          required
                          id="lieu"
                          className="md-w-1/3"
                          placeholder="Sélectionnez un lieu"
                          searchable
                          clearable
                          disabled={disabledSite}
                          data={sites}
                          value={form.values.site}
                          error={
                            form.errors.site && "Veuillez spécifier un lieu"
                          }
                          onChange={(value: string) =>
                            form.setFieldValue("site", value)
                          }
                        />
                        <TextInput
                          required
                          id="place"
                          className="md:w-1/3 w-fit"
                          placeholder="Entrez un lieu"
                          disabled={disabledPlace}
                          value={form.values.place}
                          //error={form.errors.place && ''}
                          onChange={(event) =>
                            form.setFieldValue(
                              "place",
                              event.currentTarget.value,
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                  {form.values.isVisio === "true" && (
                    <>
                      <TextInput
                        className="mb-4"
                        placeholder="Lien de la visioconférence"
                        error={
                          form.errors.videoConferenceLink &&
                          "Veuillez spécifier un lien valide"
                        }
                        value={form.values.videoConferenceLink}
                        onChange={(event) =>
                          form.setFieldValue(
                            "videoConferenceLink",
                            event.currentTarget.value,
                          )
                        }
                        required
                      />
                      <TextInput
                        className="mb-4"
                        placeholder="Mot de passe de la visioconférence (si nécessaire)"
                        error={
                          form.errors.visioPassword &&
                          "Veuillez spécifier un mot de passe valide"
                        }
                        value={form.values.visioPassword}
                        onChange={(event) =>
                          form.setFieldValue(
                            "visioPassword",
                            event.currentTarget.value,
                          )
                        }
                        required
                      />
                      <TextInput
                        className="mb-1"
                        required
                        placeholder="Infos de la visioconférence (si nécessaire)"
                      />
                      <div className="text-justify mx-2">
                        <small className="text-xs">
                          Merci d'indiquer au moins la plateforme utilisée
                          (zoom, teams, meet, skype...)
                        </small>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  <h3 className="text-2xl mb-4">Détails</h3>

                  <div className="mb-4">
                    <label>Description / Mots du Formateur</label>
                    <RichTextEditor
                      value={form.values.formerWords}
                      onChange={(event) =>
                        form.setFieldValue("formerWords", event)
                      }
                      onImageUpload={handleImageUpload}
                    />
                  </div>

                  <div className="mb-4">
                    <label>Pré-requis</label>
                    <RichTextEditor
                      value={form.values.prerequisite}
                      onChange={(event) =>
                        form.setFieldValue("prerequisite", event)
                      }
                      onImageUpload={handleImageUpload}
                    />
                  </div>
                </div>
              </div>
              <div className="md:w-3/12">
                <div>
                  <h2 className="text-xl">Places</h2>
                  <NumberInput
                    className="mt-3"
                    placeholder="Nombre de participants maximum"
                    error={
                      form.errors.maxGuests &&
                      "Veuillez spécifier un maximum d'invités valide"
                    }
                    required
                    onChange={(event: number) =>
                      form.setFieldValue("maxGuests", event)
                    }
                    min={0}
                  />
                  <NumberInput
                    className="mt-3"
                    placeholder="Nombre minimum"
                    error={
                      form.errors.minGuests &&
                      "Veuillez spécifier un minimum d'invités valide"
                    }
                    onChange={(event: number) =>
                      form.setFieldValue("minGuests", event)
                    }
                    required
                    min={0}
                  />
                  <NumberInput
                    className="mt-3"
                    placeholder="Liste d'attente max"
                    error={
                      form.errors.maxGuests &&
                      "Veuillez spécifier un maximum d'invités pour la liste d'attende"
                    }
                    required
                    min={0}
                  />
                </div>

                <div className="my-10">
                  <h2 className="text-xl mb-2">Public visé</h2>
                  <MultiSelect
                    required
                    data={ROLES}
                    onChange={(event: []) =>
                      form.setFieldValue("destined", event)
                    }
                    value={form.values.destined}
                    placeholder="Public visé"
                  />
                </div>
                <div className="my-10">
                  <h2 className="text-xl mb-2">Habilitation requise</h2>
                  <MultiSelect
                    required
                    data={HABILITATION}
                    value={form.values.habilitation}
                    onChange={(event: []) =>
                      form.setFieldValue("habilitation", event)
                    }
                    placeholder="Habilitation"
                  />
                </div>
                <div className="my-10">
                  <h2 className="text-xl mb-2">Lien externe</h2>
                  <TextInput
                    value={form.values.externLink}
                    onChange={(event) =>
                      form.setFieldValue(
                        "externLink",
                        event.currentTarget.value,
                      )
                    }
                    placeholder="Lien externe"
                  />
                </div>
              </div>
            </div>
            <div id="SubmitFix" className="flex mb-2 justify-center md:block">
              <Button
                onClick={() => handleSubmit(form.values)}
                mt={20}
                mr={5}
                className="px-20"
                size="md"
                color="pink"
                type="button"
              >
                Je valide
              </Button>
            </div>
            <div
              className={`hidden md:${
                displayButton ? "block" : "hidden"
              } fixed bottom-0 right-0 mr-16 bg-white px-3 pb-3 shadow-xl border`}
            >
              <Button
                onClick={() => handleSubmit(form.values)}
                mt={20}
                mr={5}
                className="mr-auto px-20"
                size="md"
                color="pink"
              >
                Je valide
              </Button>
            </div>
          </form>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
