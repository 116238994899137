import {
    ActionIcon,
    Anchor,
    Breadcrumbs,
    Divider,
    Button,
    LoadingOverlay,
    Transition,
} from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Footer";
import {
    BsFillEmojiSmileFill,
    BsFillEmojiNeutralFill,
    BsFillEmojiFrownFill,
} from "react-icons/all";
import HasRoles from "../../../components/Security/hasRoles";
import { ROLE_XMAN } from "../../../constants/roles";
import storage from "../../../auth/storage";
import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import faq from "../../../api/faq";
import { useNavigate } from 'react-router-dom';
const CategoryFaq = () => {
    const navigate = useNavigate();
    const { id }: any = useParams();
    const { request, data } = useApi(faq.getCategorie);

    const items = [
        { title: "Accueil", href: "/auth/home" },
        { title: "FAQ", href: "/auth/FAQ" },
    ].map((item, index) => (
        <Anchor
            component={Link}
            to={item.href}
            key={index}
            style={{ color: "#333", fontSize: 14 }}
        >
            {item.title}
        </Anchor>
    ));

    useEffect(() => {
        request(id);
    }, [id]);

    return (
        <>
            {/* <LoadingOverlay visible={loading} /> */}
            <div className="w-screen md:w-auto px-6 py-2 md:p-12">
                <div className="absolute left-60 top-5">
                        <Button
                            onClick={() => navigate(-1)}
                            className="text-sm hover:text-gray-600 hover:bg-gray-200 w-auto bg-gray-100 text-gray-400 shadow"
                            color="lime"
                            size="xs"
                            variant="filled"
                        >
                            Retour à la liste
                        </Button>
                </div>
                <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
                    {items}
                </Breadcrumbs>
                <div className="mt-6 md:mt-10 flex justify-between">
                    <div>
                        <div className="font-bold text-xl">FAQ</div>
                        <div
                            className="bg-white rounded mt-4"
                            style={{ boxShadow: "0px 1px 6px #8E8E8E59" }}
                        >
                        {data?.issues?.length ? (
                            data?.issues?.map((issue: any, index: number) => (
                                <Link to={"/auth/FAQ/issue/" + issue.id}>
                                    <h4
                                        className="hover:bg-gray-50 shadow duration-150 flex-wrap"
                                        style={{ padding: "12px 14px" }}
                                    >
                                        {index+1}. {issue.question}
                                    </h4>
                                </Link>
                            ))
                        ) : (
                            <div className="w-full flex items-center p-4">
                                <div className="w-full text-left text-sm font-medium text-gray-600">
                                    Aucune sous catégorie n'a été trouvé à cette catégorie
                                    de FAQ
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default CategoryFaq;

/*

 <div className='mb-8 mt-8 md:mb-0'>
                    <h1 className='uppercase text-2xl mb-4'>Autre FAQ {dataCategory[category-1].subCategory[subcategory-1].title}</h1>
                    <div className="bg-white rounded" style={{boxShadow: "0px 1px 6px #8E8E8E59"}}>
                        {dataCategory[category-1].subCategory[subcategory-1].questions.length ? (
                                dataCategory[category-1].subCategory[subcategory-1].questions.slice(0, 5).map((ItemQuestion: any,key:number) => (
                                    key != question-1 &&
                                    <Link to={"/auth/FAQ/category/"+category+"/subcategory/"+subcategory+"/question/"+(key+1)}>
                                        <h4 className='hover:bg-gray-50 duration-150 flex-wrap' style={{padding: "12px 14px"}}>{ItemQuestion.question}</h4>
                                    </Link>
                                ))
                            )
                            :
                            <div className="w-full flex items-center p-4">
                                <div className="w-full text-left text-sm font-medium text-gray-600">
                                    Aucune autre question trouvé
                                </div>
                            </div>
                        }
                    </div>
                </div>


                <div className="flex justify-between gap-2">
                            <div>
                                <h3 className="font-bold text-gray-600 text-xl">VOUS AVEZ AIMÉ CETTE FAQ ?</h3>
                            </div>
                            <div className="flex flex-wrap gap-2">
                                <button onClick={() => vote1()} className="p-2 gap-1 rounded bg-gray-500 hover:bg-gray-700 flex text-white text-sm items-center" style={{height:36}}><BsFillEmojiSmileFill size="16" color="white"/>
                                    {`Utile (${dataCategory[category-1].subCategory[subcategory-1].questions[question-1].vote?.filter((Vote1:any) => Vote1.vote == 2).length})`}
                                </button>
                                <button className="p-2 gap-1 rounded bg-gray-500 hover:bg-gray-700 flex text-white text-sm items-center" style={{height:36}}><BsFillEmojiNeutralFill size="16" color="white"/>
                                    {`Peu utile (${(dataCategory[category-1].subCategory[subcategory-1].questions[question-1].vote?.filter((Vote1:any) => Vote1.vote == 1).length)})`}
                                </button>
                                <button className="p-2 gap-1 rounded bg-gray-500 hover:bg-gray-700 flex text-white text-sm items-center" style={{height:36}}><BsFillEmojiFrownFill size="16" color="white"/>
                                    {`Pas utile (${(dataCategory[category-1].subCategory[subcategory-1].questions[question-1].vote?.filter((Vote1:any) => Vote1.vote == -1).length)})`}
                                </button>
                            </div>
                        </div>

 */
