import request from "./request";

const endpoint = "/settings";

const getTva = (id: number) => {
  return request.get(endpoint + "/" + id);
};

const edit = (id: number, valueTVA: number, ca: number, caugmente: number) => {
  return request.put(endpoint + "/" + id, {
    valueTVA: valueTVA,
    CA: ca,
    CAugmente: caugmente,
  });
};

const SettingsExported = {
  getTva,
  edit,
};

export default SettingsExported;
