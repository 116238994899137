import React, {useEffect} from 'react';
import { GrLinkTop } from "react-icons/gr";
import logoForman from "../assets/images/logo_FM.png";
import { useWindowScroll } from '@mantine/hooks';
//@ts-ignore
import pdf from "../assets/images/Politique.pdf"
//@ts-ignore
import int from "../assets/images/reglem.pdf"
//@ts-ignore
import cgu from "../assets/images/cgu.pdf"
import useApi from "../hooks/useApi";
import users from "../api/users";


export default function Footer() {
    const [scroll, scrollTo] = useWindowScroll();
    const { request: requestMe, data: dataMe } = useApi(users.me);

    useEffect(() => {
        requestMe();
    }, []);


    if(dataMe?.roles && dataMe?.roles[0] == "ROLE_GUEST"){
        return (
            <>
                {window.innerWidth > 768 ?
                    <footer className='hidden md:flex justify-center items-center mx-auto p-2 mt-10' style={{position:"absolute" , bottom:0}}>
                        <div>
                            <p className='text-xxs'>Tous droits réservés Association FORMAN / 2009 - 2022</p>
                        </div>
                    </footer> : <footer className='md:hidden mt-10 my-24'>
                        <GrLinkTop onClick={() => scrollTo({ y: 0 })} color='blue' className='mx-auto text-3xl mt-9 mb-8' />
                        <div className='flex justify-center'>
                            <img className='w-2/5 mt-6 my-1' src={logoForman} alt="Logo FORMAN" />
                        </div>
                        <p className='text-xxs text-center mt-6'>Tous droits réservés Association FORMAN / 2009 - 2022</p>
                    </footer>
                }
            </>
        )
    }

    return <>
        {window.innerWidth > 768 ?
            <footer className='hidden md:flex justify-center items-center mx-auto p-2 mt-10' style={{position:"absolute", left:"30%", bottom:0}}>
                <div>
                    <p className='text-xxs'>Tous droits réservés Association FORMAN / 2009 - 2022</p>
                </div>
                <ul className='flex text-xxs space-x-4 ml-5'>
                    <li><a href='#/auth/status'>Statuts de l'Association</a></li>
                    <li><a target="_blank" href={int}>Règlement intérieur</a></li>
                    <li><a href='#/mentions-legales'>Crédits & mentions légales</a></li>
                    <li><a target="_blank" href={pdf}>Politique de confidentialité</a></li>
                    <li><a  target="_blank" href={cgu}>CGU</a></li>
                    <li><a href='#/auth/ContactApp'>Contact</a></li>
                </ul>
            </footer> : <footer className='md:hidden mt-10 my-24'>
                <GrLinkTop onClick={() => scrollTo({ y: 0 })} color='blue' className='mx-auto text-3xl mt-9 mb-8' />
                <ul className='text-xxs text-center space-y-1'>
                    <li><a href='#/auth/status'>Statuts de l'Association</a></li>
                    <li><a target="_blank" href={int} >Règlement intérieur</a></li>
                    <li><a href='#/mentions-legales'>Crédits & mentions légales</a></li>
                    <li><a target="_blank" href={pdf}>Politique de confidentialité</a></li>
                    <li><a href='#/auth/cgu'>CGU</a></li>
                    <li><a href='#/auth/ContactApp'>Contact</a></li>
                </ul>
                <div className='flex justify-center'>
                    <img className='w-2/5 mt-6 my-1' src={logoForman} alt="Logo FORMAN" />
                </div>
                <p className='text-xxs text-center mt-6'>Tous droits réservés Association FORMAN / 2009 - 2022</p>
            </footer>
        }
    </>
}
