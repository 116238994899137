import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  LoadingOverlay,
  MultiSelect,
  Table,
  Modal,
  Button,
  ScrollArea,
} from "@mantine/core";
import GoalNeedValidationItem from "../../../components/goals/GoalNeedValidationItem";
import storage from "../../../auth/storage";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import finishedGoals from "../../../api/finishedGoals";
import workshop from "../../../api/workshop";
import {
  AiOutlineSearch,
  FaCheck,
  FaSearch,
  FaTimes,
  FiUserCheck,
} from "react-icons/all";
import WorkshopCardNotifItem from "../../../components/workshop/WorkshopCardNotifItem";
import { format_dddd_d_MMMM_YYYY } from "../../../utility/dateUtility";

const WorkshopScreen = () => {
  const { isAdmin, id }: any = storage.getUser();
  const navigation = useNavigate();
  const [isBoss, setIsBoss] = useState(false);
  const { data, loading, request } = useApi(workshop.getWorkshopNonePublish);
  const [opened, setOpened] = useState(false);
  const {
    data: dataCopilot,
    loading: loadingCopilot,
    request: requestCopilot,
  } = useApi(workshop.getWorkshopsWithCopilotWanted);
  const {
    data: dataFormer,
    loading: loadingFormer,
    request: requestFormer,
  } = useApi(workshop.getWorkshopAfterDateByFormerAndNotEndedAbsent);
  const {
    data: dataCopilots,
    loading: loadingCopilots,
    request: requestCopilots,
  } = useApi(workshop.getWorkshopAfterDateByCopilotAndNotEndedAbsent);
  const [absents, setAbsents] = useState([]);
  const [guests, setGuests] = useState([]);
  const [itemId, setItemId] = useState(Number);
  const [workshopAbsent, setWorkshopAbsent] = useState([]);

  useEffect(() => {
    if (isAdmin === true) {
      setIsBoss(true);
      request();
    }
    requestCopilot().then((res) => console.log(res));
  }, [isAdmin]);

  useEffect(() => {
    requestFormer(id);
    requestCopilots(id);
  }, [id]);

  useEffect(() => {
    if(!loadingFormer && !loadingCopilots){
      const array = dataFormer.concat(dataCopilots);
      setWorkshopAbsent(array);
    }
  },[dataFormer,dataCopilots])


  const workshopAbsentFilter = workshopAbsent.filter((item: any) => {
    //filter workshop with startDate is today or before
    const date = new Date();
    const currentDate = new Date(item.startDate);
    return (
        currentDate.getTime() <= date.getTime()
    );
  });

  const rows = data.map((element: any) => (
    <tr key={element.id}>
      <td>
        {format_dddd_d_MMMM_YYYY(element.startDate)}
      </td>
      <td>{element.title}</td>
      <td>{element.site ? element.site.name : "Pas de site"}</td>
      <td>
        {element.former.firstName} {element.former.lastName}
      </td>
      <td className="inline-flex text-center justify-center">
        <ActionIcon
          onClick={() => handleValidate(element.id)}
          className="ml-10"
          color="green"
          variant="filled"
        >
          <FaCheck />
        </ActionIcon>
        <ActionIcon
          onClick={() => handleNotValidate(element.id)}
          className="ml-3"
          color="red"
          variant="filled"
        >
          <FaTimes />
        </ActionIcon>
      </td>
    </tr>
  ));

  const rowsFormer = workshopAbsentFilter.map((element: any) => {
    const tab: any = [];
    for (let i = 0; i < element.guests.length; i++) {
      tab.push({
        value: "/api/users/" + element.guests[i].id,
        label:
          element.guests[i].firstName +
          " " +
          element.guests[i].lastName +
          " (" +
          element.guests[i].email +
          ")",
      });
    }
    return (
      <tr key={element.id}>
        <td>
          {format_dddd_d_MMMM_YYYY(element.startDate)}
        </td>
        <td>{element.title}</td>
        <td>{element.site ? element.site.name : "Pas de site"}</td>
        <td>
          {element.former.firstName} {element.former.lastName}
        </td>
        <td>
          <MultiSelect
            className="max-w-xs"
            data={[...tab]}
            placeholder={
              window.innerWidth > 450
                ? "Sélectionnez les absents"
                : "Les Absents"
            }
            nothingFound="Aucun résulat"
            onChange={(value: []) => setAbsents(value)}
          />
        </td>
        <td className="flex gap-x-2 text-center items-center my-auto">
          <div className="flex-col">
            <ActionIcon
              size={50}
              onClick={() =>
                navigation("/auth/workshop/detail/" + element.id, {
                  replace: true,
                })
              }
              className="mx-auto"
              color="gray"
              variant="transparent"
            >
              <FaSearch size={25} />
            </ActionIcon>
            <p>Voir l'atelier</p>
          </div>
          <div className="flex-col">
            <ActionIcon
              size={50}
              onClick={() => (setOpened(true), setItemId(element.id))}
              className="mx-auto"
              color="gray"
              variant="transparent"
            >
              <FiUserCheck size={30} />
            </ActionIcon>
            <p>Valider les absents</p>
          </div>
          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title="Feuille D'émargement"
          >
            <h3>
              Êtes vous sûr de vouloir valider les absences de cet Atelier ?
            </h3>
            <p className="text-xs text-gray-500 my-2 flex">
              Il ne sera plus possible de les modifier
              <p className="text-red-600">*</p>
            </p>
            <div className="w-full text-center">
              <Button onClick={() => handleValidateAbsents(absents, itemId)}>
                Valider
              </Button>
            </div>
          </Modal>
        </td>
      </tr>
    );
  });

  const handleValidateAbsents = (users: Array<any>, id: number) => {
    workshop.updateWorkshopAbsents(users, id).then((res) => {
      console.log(res, "absents :", users, "idAtelier :", id);
      window.location.reload();
    });
  };

  const handleValidate = (id: number) => {
    workshop.publish(id).then(() => {
      request();
    });
  };

  const handleNotValidate = (id: number) => {
    workshop.Unpublish(id).then(() => {
      request();
    });
  };

  const handleValidateCopilot = (id: number, userId: number) => {
    workshop.registerApprouvedCopilotBy(id, userId).then(() => {
      workshop.unRegisterCopilotBy(id, userId).then(() => {
        requestCopilot().then(() => {
          request();
        });
      });
    });
  };

  const handleNotValidateCopilot = (id: number, userId: number) => {
    workshop.unRegisterCopilotBy(id, userId).then(() => {
      requestCopilot();
    });
  };

  return (
    <>
      <LoadingOverlay visible={loading} />
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl ">
          <span className="font-bold text-xl">NOTIFICATIONS</span>
          <br />
          <span className="text-3xl">ATELIERS</span>
        </div>
        {isBoss ? (
          <>
            <div className="mt-6 md:mt-10 text-xl ">
              <span className=" text-2xl">
                ATELIERS EN ATTENTE DE VALIDATION
              </span>
            </div>
            <ScrollArea>
              <div
                className="mt-3 md:mt-3 text-xl mb-2"
                style={{ minWidth: 950 }}
              >
                <Table striped>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Titre</th>
                      <th>Lieu</th>
                      <th>Pilote</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              </div>
            </ScrollArea>
          </>
        ) : null}
        {dataCopilot?.length > 0 && (
          <>
            <div className="mt-6 md:mt-10 text-xl ">
              <span className=" text-2xl">CANDIDATURE DE COPILOTE</span>
            </div>
            <div className="mt-3 md:mt-3 text-xl ">
              {dataCopilot?.length > 0 &&
                dataCopilot.map((item: any) => (
                  <WorkshopCardNotifItem
                    onDelete={handleNotValidateCopilot}
                    onValidate={handleValidateCopilot}
                    workshop={item}
                  />
                ))}
            </div>
          </>
        )}
        {workshopAbsent?.length > 0 && (
          <>
            <div className="mt-6 md:mt-10 text-xl ">
              <span className=" text-2xl">
                FEUILLES D'ÉMARGEMENTS DES ATELIERS PASSÉS
              </span>
              <span className="text-sm block">
                Merci de signaler les absents à l'atelier (rappel: absent si 1
                demi-journée manquée)
              </span>
            </div>
            <ScrollArea type="always">
              <div
                className="mt-3 md:mt-3 text-xl mb-2"
                style={{ minWidth: 950 }}
              >
                <Table striped>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Titre</th>
                      <th>Lieu</th>
                      <th>Pilote</th>
                      <th>Absents</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{rowsFormer}</tbody>
                </Table>
              </div>
            </ScrollArea>
          </>
        )}
      </div>
    </>
  );
};

const items = [
  { title: "Accueil", href: "/auth/home" },
  { title: "Ateliers", href: `/auth/workshop` },
  { title: "Notifications", href: `/auth/home` },
].map((item, index) => (
  <Anchor
    component={Link}
    to={item.href}
    key={index}
    style={{ color: "#333", fontSize: 14 }}
  >
    {item.title}
  </Anchor>
));

export default WorkshopScreen;
