import React from 'react';
import {Link} from "react-router-dom";

interface NotificationItemLinkProps {
    path: any;
    title: string;
    display: boolean;
}

const NotificationItemLink = (props: NotificationItemLinkProps) => {
    return <Link className={`text-xs text-gray-300 hover:underline ${ props.display ? `block` : 'hidden'}`} to={props.path}>{props.title}</Link>;
};

export default NotificationItemLink;
