import React, {useEffect, useState} from "react";
import {FiEdit, FiDelete} from "react-icons/fi";
import {Checkbox, NumberInput, Select, ActionIcon} from "@mantine/core";
import {ROLES_WITH_EXPERT_TOUS, ROLES_WOUT_GUEST_TOUS} from "../../constants/roles";
import useApi from "../../hooks/useApi";
import users from "../../api/users";
import {useNotifications} from "@mantine/notifications";
import {DatePicker} from "@mantine/dates";
import { format_d_MMMM_YYYY } from "../../utility/dateUtility";
import { getInverseUserFullName } from "../../utility/userUtility";

interface Props {
    element: any;
    onEditUser: (user: any) => void;
    onDeleteUser: (user: any) => void;
    onChangeAccount: (email: string) => void;
}

const UserRow = (props: Props) => {
    const [change, setChange] = useState(false);
    const notifications = useNotifications();
    const {
        data,
        loading,
        request,
    } = useApi(users.editRole);
    const {
        request: requestPilot,
    } = useApi(users.editPilot);
    const {
        request: requestCredit,
    } = useApi(users.editCredit);
    const {
        request: requestIsExpertAccountant,
    } = useApi(users.editIsExpertAccountant);
    const {
        request: requestManager,
    } = useApi(users.editManager);
    const {
        request: requestGodFather,
    } = useApi(users.editGodFather);
    const {
        request: requestDate,
    } = useApi(users.editEndCursus);
    const {
        request: requestJD,
    } = useApi(users.editJD);
    const [role, setRole] = React.useState(props.element.roles[0]);
    console.log("cursus",props.element.endCursus)
    console.log("element",props.element)
    console.log("props",props)
    const [isPilot, setIsPilot] = React.useState(props.element.isPilot);
    const [isJd, setIsJd] = React.useState(props.element.isJdEnded);
    const [isDemarrage, setIsDemarrage] = React.useState(props.element.isDemarrageEnded);
    const [is3JR, setIs3JR] = React.useState(props.element.is3JREnded);
    const [credits, setCredits] = React.useState(props.element.credit);
    const [isExpertAccountant, setIsExpertAccountant] = React.useState(props.element.isExpertAccountant);
    const [date, setDate] = React.useState(props.element.endCursus);
    const [newIDGodFather, setNewIDGodFather] = React.useState<number>(0);
    const [newIDManager, setNewIDManager] = React.useState<number>(0);

    useEffect(() => {
        console.log(props.element.roles[0])
        setRole(props.element.roles[0]);
        setIsPilot(props.element.isPilot);
        setIsJd(props.element.isJdEnded);
        setIsDemarrage(props.element.isDemarrageEnded);
        setIs3JR(props.element.is3JREnded);
        setCredits(props.element.credit);
        setIsExpertAccountant(props.element.isExpertAccountant);
        setDate(props.element.endCursus ?? new Date());
        setNewIDGodFather(0);
        setNewIDManager(0);
    }, [props.element]);


    useEffect(() => {
        if (role !== props.element.roles[0] && role !== undefined && role !== null && role !== "") {
            request(role, props.element.id).then(() => {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Role modifié ! 😀",
                    color: "green",
                });
            }).catch(() => {
                notifications.showNotification({
                    title: "Erreur !",
                    message: "Erreur lors de la modification du role !",
                    color: "red",
                })
                setRole(props.element.roles[0]);
            });
        }
        if (isPilot !== props.element.isPilot && isPilot !== undefined && isPilot !== null) {
            requestPilot(isPilot, props.element.id).then(() => {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Pilote modifié ! 😀",
                    color: "green",
                });
            }).catch(() => {
                setIsPilot(props.element.isPilot);
                notifications.showNotification({
                    title: "Erreur !",
                    message: "Erreur lors de la modification du pilote !",
                    color: "red",
                })
            });
        }
        if (credits !== props.element.credit && credits !== undefined && credits !== null) {
            requestCredit(credits, props.element.id).then(() => {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Crédits modifié ! 😀",
                    color: "green",
                });
            }).catch(() => {
                setCredits(props.element.credit);
                notifications.showNotification({
                    title: "Erreur !",
                    message: "Erreur lors de la modification des crédits !",
                    color: "red",
                })
            });
        }
        if (isExpertAccountant !== props.element.isExpertAccountant && isExpertAccountant !== undefined && isExpertAccountant !== null) {
            requestIsExpertAccountant(isExpertAccountant, props.element.id).then(() => {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Statut expert comptable modifié ! 😀",
                    color: "green",
                });
            }).catch(() => {
                setIsExpertAccountant(props.element.isExpertAccountant);
                notifications.showNotification({
                    title: "Erreur !",
                    message: "Erreur lors de la modification du statut expert comptable !",
                    color: "red",
                })
            });
        }
        if (date !== props.element.endCursus && date !== undefined && date !== null) {
            requestDate((date.getFullYear() + "-" + (date.getMonth()+1)  + "-" + date.getDate()), props.element.id).then(() => {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Date modifié ! 😀",
                    color: "green",
                });
            }).catch(() => {
                setDate(props.element.endCursus);
                notifications.showNotification({
                    title: "Erreur !",
                    message: "Erreur lors de la modification de la date !",
                    color: "red",
                })
            });
        }
        if (newIDGodFather !== undefined && newIDGodFather !== null && newIDGodFather !== 0) {
            requestGodFather(newIDGodFather, props.element.id).then(() => {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Parrain modifié ! 😀",
                    color: "green",
                });
            }).catch(() => {
                setNewIDGodFather(0);
                notifications.showNotification({
                    title: "Erreur !",
                    message: "Erreur lors de la modification du parrain !",
                    color: "red",
                })
            });
        }
        if (newIDManager !== undefined && newIDManager !== null && newIDManager !== 0) {
            requestManager(newIDManager, props.element.id).then(() => {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Manager modifié ! 😀",
                    color: "green",
                });
            }).catch(() => {
                setNewIDManager(0);
                notifications.showNotification({
                    title: "Erreur !",
                    message: "Erreur lors de la modification du manager !",
                    color: "red",
                })
            });
        }
    }, [role, isPilot, credits,date, newIDManager, newIDGodFather])


    useEffect(() => {
        if (change) {

            requestJD(isJd, isDemarrage, is3JR, props.element.id).then(() => {
                setChange(false);
            });
            requestIsExpertAccountant(isExpertAccountant, props.element.id).then(() => {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Statut expert comptable modifié ! 😀",
                    color: "green",
                });
            }).catch(() => {
                setIsExpertAccountant(props.element.isExpertAccountant);
                notifications.showNotification({
                    title: "Erreur !",
                    message: "Erreur lors de la modification du statut expert comptable !",
                    color: "red",
                })
            });
            setChange(false);
        }
    }, [change])

    return (
        <tr key={props.element.id}>
            <td style={{width: 50}}>
                {props.element.id}
            </td>
            <td style={{width: 200}}>
                {getInverseUserFullName(props.element)}
            </td>
            <td style={{width: 200}}>{props.element.email}</td>
            <td style={{width: 300}}>
                <DatePicker
                    style={{width: 200}}
                    inputFormat="DD/MM/YYYY"
                    labelFormat="DD/MM/YYYY"
                    className="w-full"
                    placeholder=""
                    label=""
                    value={date && new Date(date)}
                    onChange={(event: Date) =>
                        setDate(event)
                    }
                    required
                />
            </td>
            <td>{props.element.phoneNumber}</td>
            <td style={{width: 100}}>
                <Select
                    style={{width: 100}}
                    className="w-full"
                    placeholder="Role"
                    searchable
                    clearable
                    value={role}
                    data={ROLES_WITH_EXPERT_TOUS}
                    onChange={(value: string) =>
                        setRole(value)
                    }
                />
            </td>
            <td style={{width: 50}}>
                <Checkbox
                    style={{width: 50}}
                    label=""
                    checked={isPilot}
                    onChange={(event) =>
                        setIsPilot(event.currentTarget.checked)
                    }
                />
            </td>
            <td style={{width: 50}}>
                <Checkbox
                    style={{width: 50}}
                    label=""
                    checked={isJd}
                    onChange={(event) => {
                        setChange(true);
                        setIsJd(event.currentTarget.checked)
                    }
                    }
                />
            </td>
            <td style={{width: 50}}>
                <Checkbox
                    style={{width: 50}}
                    label=""
                    checked={isDemarrage}
                    onChange={(event) => {
                        setChange(true);
                        setIsDemarrage(event.currentTarget.checked)
                    }
                    }
                />
            </td>
            <td style={{width: 50}}>
                <Checkbox
                    style={{width: 50}}
                    label=""
                    checked={is3JR}
                    onChange={(event) => {
                        setChange(true);
                        setIs3JR(event.currentTarget.checked)
                    }
                    }
                />
            </td>
            <td style={{width: 100}}>
                <NumberInput
                    style={{width: 100}}
                    placeholder="Crédits"
                    value={credits}
                    onChange={(event: number) =>
                        setCredits(event)
                    }
                    min={0}
                />
            </td>
            <td style={{width: 200}}>
                {format_d_MMMM_YYYY(props.element.adhesionPaidAt, "Non")}
            </td>
            <td style={{width: 200}}>
                {props.element.address} {props.element.postalCode} {props.element.city}
            </td>
            <td style={{width: 200}}>
                {getInverseUserFullName(props.element.godFather, "Pas de parrain")}
            </td>
            <td style={{width: 200}}>
                <NumberInput
                    style={{width: 100}}
                    placeholder=""
                    value={newIDGodFather}
                    onChange={(event: number) =>
                        setNewIDGodFather(event)
                    }
                    min={0}
                />
            </td>
            <td style={{width: 200}}>
                {getInverseUserFullName(props.element.manager, "Pas de manager")}
            </td>
            <td style={{width: 200}}>
                <NumberInput
                    style={{width: 100}}
                    placeholder=""
                    value={newIDManager}
                    onChange={(event: number) =>
                       setNewIDManager(event)
                    }
                    min={0}
                />
            </td>
            <td style={{width: 200}}>
                <div className="flex-flex-auto">
                    <button onClick={() => props.onChangeAccount(props.element.email)}>
                        <span style={{whiteSpace: "nowrap"}}>Prendre le contrôle</span>
                    </button>
                    <div className="flex flex-auto">
                        <ActionIcon
                            size={40}
                            color="blue"
                            className="mx-auto"
                            variant="transparent"
                            onClick={() => props.onEditUser(props.element)}
                        >
                            <FiEdit size={20}/>
                        </ActionIcon>
                        <ActionIcon
                            size={40}
                            color="red"
                            className="mx-auto"
                            variant="transparent"
                            onClick={() => props.onDeleteUser(props.element)}
                        >
                            <FiDelete size={20}/>
                        </ActionIcon>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default UserRow