import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import event from "../../api/event";
import useApi from "../../hooks/useApi";
import storage from "../../auth/storage";
import CapitalizeLetter from "../../hooks/CapitalizeLetter";
import { format_dddd_d_MMMM_YYYY } from "../../utility/dateUtility";

const CardCollection = ({ data }: any) => {
    const { id: UserId }: any = storage.getUser();
    const { request: requestevent } = useApi(event.item);
    const idevent = data?.id;
    const [isGuest, setisGuest] = useState(Boolean);
    const [isLink, setisLink] = useState(true);
    const [listGuest, setlistGuest] = useState<any>();

    useEffect(() => {
        requestevent(idevent).then((r) => {
            setlistGuest(r?.data?.guests);
            if (r?.data?.externLink == "") {
                setisLink(false);
            }
        });
    }, []);

    useEffect(() => {
        setisGuest(
            !!listGuest?.find((guest: { id: any }) => guest.id === UserId),
        );
    }, [listGuest]);

    return (
        <div
            className={"w-full mb-5 bg-white duration-150 rounded shadow-md"}
            style={{ padding: "12px 14px" }}
        >
            <Link to={"/auth/event/detail/" + data?.id}>
                <div
                    className="flex flex-row justify-between"
                    style={{ color: "#687687" }}
                >
                    <div className="flex flex-col text-left">
                        <div className="flex items-center">
                            <h4
                                style={{
                                    fontWeight: "initial",
                                    color: "#333",
                                    fontSize: "21px",
                                }}
                            >
                                {data?.title && CapitalizeLetter(data?.title)}
                            </h4>

                            <span
                                className="ml-5"
                                style={{
                                    color: isGuest ? "white" : "white",
                                    opacity: isGuest ? 0.6 : 1,
                                }}
                            >
                                <span
                                    className={
                                        " rounded-md py-1 px-2 uppercase"
                                    }
                                    style={{
                                        background: isGuest
                                            ? "#84cc16"
                                            : "#6B7280",
                                    }}
                                >
                                    {data?.totalGuests}/{data?.maxGuests}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div
                        className="flex text-right"
                        style={{ fontSize: "13px" }}
                    >
                        <div>
                            <p>
                                {CapitalizeLetter(
                                    format_dddd_d_MMMM_YYYY(data?.startDate),
                                )}
                            </p>
                            <div className="ml-auto items-center justify-between text-sm font-medium flex-wrap">
                                {data?.isVisio ? (
                                    <p
                                        className="text-right text-sm"
                                        style={{
                                            fontSize: "13px",
                                            color: "#687687",
                                        }}
                                    >
                                        Visio
                                    </p>
                                ) : (
                                    <div className="text-right">
                                        <p
                                            className="text-right text-sm"
                                            style={{
                                                fontSize: "13px",
                                                color: "#687687",
                                            }}
                                        >
                                            {data?.site.address &&
                                                CapitalizeLetter(
                                                    data?.site.address,
                                                )}
                                        </p>
                                        <p
                                            className="text-right text-sm"
                                            style={{
                                                fontSize: "13px",
                                                color: "#687687",
                                            }}
                                        >
                                            {data?.site?.postalCode &&
                                                CapitalizeLetter(
                                                    data?.site.postalCode,
                                                )}{" "}
                                            {data?.site?.city &&
                                                CapitalizeLetter(
                                                    data?.site.city,
                                                )}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default CardCollection;
