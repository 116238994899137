import React from "react";
import { format_MMMM_YYYY } from "../../utility/dateUtility";

interface Interface {
    parcours: any;
}

const ParcoursItem = (props: Interface) => {
    const getParcourText = () => {
        const parcours_total =
            props.parcours.voteGodFather +
            props.parcours.voteDevMan +
            props.parcours.voteXman;

        if (parcours_total > 0) {
            return "PROMOTION";
        } else if (parcours_total < 0) {
            return "REGRADE";
        } else {
            return "MAINTIEN";
        }
    };

    return (
        <li className="border-b">
            <div className="flex justify-between px-4 pt-4 pb-2 text-gray-600 text-sm mb-3">
                <p>{getParcourText()}</p>
                <p className="bg-blue-100 rounded px-1 text-xxs text-center mx-1">
                    MAINTIEN{" "}
                    <span className="font-medium">
                        {props.parcours.goalsMaintien}
                    </span>{" "}
                    - PROMOTION{" "}
                    <span className="font-medium">
                        {props.parcours.goalsPromotion}
                    </span>
                </p>

                <p className="uppercase">
                    {format_MMMM_YYYY(props.parcours?.createdAt)}
                </p>
            </div>
        </li>
    );
};

export default ParcoursItem;
