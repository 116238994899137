import { REACT_APP_API_BASE_URL } from "../env";

/*
export const URL = "http://127.0.0.1:8000"
export const API = "http://127.0.0.1:8000/api"

    */

//export const URL = "https://api.asso-forman.com"
//export const API = "https://api.asso-forman.com/api"
//export const URL2 = "https://api.asso-forman.com"


//export const URL = "https://api.appyness.eu"
//export const API = "https://api.appyness.eu/api"
//export const URL2 = "https://api.appyness.eu"

export const URL = REACT_APP_API_BASE_URL
export const API = `${REACT_APP_API_BASE_URL}/api`
export const URL2 = REACT_APP_API_BASE_URL
