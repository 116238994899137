import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import news from "../../../api/news";
import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  Button,
  Divider,
  LoadingOverlay,
} from "@mantine/core";
import { URL } from "../../../constants/URL";
import { BiLike } from "react-icons/all";
import {ROLE_DXMAN_MORE, ROLE_XMAN} from "../../../constants/roles";
import HasRoles from "../../../components/Security/hasRoles";
import Footer from "../../Footer";
import storage from "../../../auth/storage";
import HomeCardNew from "../../../components/News/HomeCardNew";
import { useNotifications } from "@mantine/notifications";
import { format_ddMMYYYY } from "../../../utility/dateUtility";

const ItemNews = () => {
  const { id } = useParams();
  const user: any = storage.getUser();
  const [isLiked, setIsLiked] = useState(false);
  const { data, request, loading } = useApi(news.get);
  const { request: requestValidate } = useApi(news.validate);
  const { request: requestLike } = useApi(news.like);
  const { request: requestDisLike } = useApi(news.dislike);
  const { request: requestRemove } = useApi(news.remove);
  const { data: collectionData, request: collectionReq } = useApi(
    news.collection,
  );
  const navigation = useNavigate();
  const notifications = useNotifications();

  useEffect(() => {
    request(id);
    collectionReq(user.role);
  }, [id]);

  useEffect(() => {
    if (data && data?.likes) {
      setIsLiked(!!data?.likes.find((guest: any) => guest.id === user?.id));
    }
  }, [data]);

  const like = () => {
    requestLike(id).then((value) => {
      setIsLiked(true);
    });
  };

  const dislike = () => {
    requestDisLike(id).then((value) => {
      setIsLiked(false);
    });
  };

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Actualités", href: "/auth/news" },
    { title: data?.title, href: "/auth/news/detail/" + id },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  const handleValidate = () => {
    requestValidate(id).then((value) => {
      request(id);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRemove = async () => {
    const result = await requestRemove(Number(id));
    if (!result.ok) {
      notifications.showNotification({
        title: "Une erreur est survenue.",
        message: "L'Actualiter n'a pas pu être supprimée ! 🤥",
        color: "red",
      });
    } else {
      notifications.showNotification({
        title: "Nice !",
        message: "Actualiter supprimée ! 😀",
        color: "green",
      });
      navigation("/auth/news", { replace: true });
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} />
      <div className="absolute left-60 top-5">
        <Link to="/auth/news">
          <Button
              className="text-sm hover:text-gray-600 hover:bg-gray-200 w-auto bg-gray-100 text-gray-400 shadow"
              color="lime"
              size="xs"
              variant="filled"
          >
            Retour à la liste
          </Button>
        </Link>
      </div>
      <div className="w-screen md:w-full px-4 md:px-14 py-2 md:pb-12">
        <Breadcrumbs
          className="px-4 my-5 text-gray-800"
          separator="/"
          styles={{ separator: { color: "#333" } }}
        >
          {items}
        </Breadcrumbs>
        <div className="flex justify-start space-x-2 mb-4">
          <HasRoles role={ROLE_XMAN}  checkIsAuthor datas={data}>
            <Link to={"/auth/news/edit/" + id}>
              <Button
                  className="text-sm hover:text-white hover:bg-yellow-600 w-auto bg-gray-100 text-gray-400 shadow"
                  color="lime"
                  size="xs"
                  variant="filled"
              >
                Modifier
              </Button>
            </Link>
            <Button
                onClick={() => handleRemove()}
                className="text-sm hover:text-white hover:bg-red-600 w-auto bg-gray-100 text-gray-400 shadow"
                color="lime"
                size="xs"
                variant="filled"
            >
              Supprimer
            </Button>
          </HasRoles>
          {data?.isValidate === false && (
            <HasRoles checkIsAuthor role={ROLE_XMAN}>
              <Button
                onClick={handleValidate}
                className="uppercase mb-5 ml-5"
                color="blue"
                size="xs"
                variant="filled"
              >
                VALIDER
              </Button>
            </HasRoles>
          )}
        </div>

        <div className="md:flex flex-wrap justify-between">
          <div className="md:w-2/6">
            <img
              className="rounded shadow"
              src={data?.fileUrl && URL + data?.fileUrl}
              alt=""
            />
          </div>

          <div className="md:w-4/6 md:px-4">
            <div className="flex justify-between">
              <div>
                <h3 className="font-bold break-all mb-4 md:mb-0 text-gray-600">
                  {data?.category}
                </h3>
                <h1 className="font-light break-all mb-4 md:mb-0 text-4xl uppercase">
                  {data?.title}
                </h1>
                {window.innerWidth > 768 ? (
                  <div className="md:flex">
                    <p className="text-gray-500">
                      {data?.author?.firstName} {data?.author?.lastName}
                    </p>
                    <p className="ml-10 text-gray-500">
                      {format_ddMMYYYY(data?.publishAt)}
                    </p>
                  </div>
                ) : null}
              </div>
              {!isLiked ? (
                <ActionIcon
                  onClick={() => like()}
                  size="xl"
                  className="my-2 bg-gray-500 hover:bg-green-500"
                >
                  <BiLike size="24" color="white" />
                </ActionIcon>
              ) : (
                <ActionIcon
                  onClick={() => dislike()}
                  size="xl"
                  className="my-2 bg-green-500 hover:bg-green-700"
                >
                  <BiLike size="24" color="white" />
                </ActionIcon>
              )}
            </div>
            {window.innerWidth < 768 ? (
              <div className="flex justify-between">
                <p className="text-gray-500">
                  {data?.author?.firstName} {data?.author?.lastName}
                </p>
                <p className="ml-10 text-gray-500">
                  {format_ddMMYYYY(data?.publishAt)}
                </p>
              </div>
            ) : null}
            <br />

            <div
              className="break-all"
              dangerouslySetInnerHTML={{
                __html: data?.description,
              }}
            />

            <Divider className="my-5" />
            
          </div>
        </div>

        <div className="md:flex mt-16 md:gap-20">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className=" text-2xl mb-4">Autre articles récents</h1>
            <div
              className="bg-white rounded"
              style={{ boxShadow: "0px 1px 6px #8E8E8E59" }}
            >
              {collectionData.length ? (
                collectionData
                  .filter((item: any) => item.id != id)
                  .slice(0, 5)
                  .map((news: any) => <HomeCardNew data={news} />)
              ) : (
                <div className="w-full flex items-center p-4">
                  <div className="w-full text-left text-sm font-medium text-gray-600">
                    Aucune Actualité trouvé
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-2xl mb-4">
              Articles de votre parrain
            </h1>
            <p className="bg-white rounded p-4 shadow">Titre de l'actualité</p>
          </div>
        </div>
        <br />

        <Footer />
      </div>
    </>
  );
};

export default ItemNews;
