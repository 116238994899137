import request from "./request";

const endpoint = "/sites";

const collection = () => {
  return request.get(endpoint+"?isActive=1");
};

const collectionAdmin = () => {
    return request.get(endpoint);
};


const create = (site: any) => {
  return request.post(endpoint, {
    name: site.name,
    address: site.address,
    postalCode: site.postalCode,
    city: site.city,
    gmap: site.gmap,
      isActive: true,
  });
};

const edit = (site: any, id: number) => {
  return request.put(endpoint + "/" + id, {
    name: site.name,
    address: site.address,
    postalCode: site.postalCode,
    city: site.city,
    gmap: site.gmap,
  });
};

const remove = (id: number) => {
  return request.put(endpoint + "/" + id, {
             name: "Site Supprimé",
             address: "Site Supprimé",
             postalCode: "Site Supprimé",
             city: "Site Supprimé",
             gmap: "Site Supprimé",
                isActive: false,
           });
};

const desactivate = (isActive :boolean,id: number) => {
    return request.put(endpoint + "/" + id, {
        isActive: isActive,
    });
}

export default {
  collection,
    collectionAdmin,
  create,
  edit,
  remove,
    desactivate
};
