import request from "./request";

const endpoint = "/evolutions";

const collectionByUser = (userId: number) => {
    return request.get(endpoint+"?user="+userId+"&isEnded=0");
};

const collection = () => {
    return request.get(endpoint+"?&isEnded=false");
};

const collectionNotifs = (userId: number) => {
    return request.get("/test/notifs/"+userId);
}

const collectionByUserEnded = (userId: number) => {
    return request.get(endpoint+"?user="+userId+"&isEnded=1");
};

const put = (id: number, voteGodFather: number | null , voteDevMan:  number | null , voteXman:  number | null ) => {
    return request.put(endpoint+"/"+id, {
        voteGodFather: voteGodFather,
        voteDevMan : voteDevMan,
        voteXman: voteXman
    });
};

const end = (id: number, voteGodFather: number | null , voteDevMan:  number | null , voteXman:  number | null) => {
    return request.put(endpoint+"/"+id+"/xman", {
        voteGodFather: voteGodFather,
        voteDevMan : voteDevMan,
        voteXman: voteXman
    });
};

const create = (user: number,goalsMaintien: string, goalsPromotion: string ) => {
    return request.post(endpoint, {
        user: user,
        raison: "Validation de tous les objectifs",
        voteGodFather: null,
        voteDevMan : null,
        voteXman: null,
        goalsMaintien : goalsMaintien,
        goalsPromotion : goalsPromotion,
    });
}

export default {
    collection, collectionByUser, put, end, collectionByUserEnded,create, collectionNotifs
};