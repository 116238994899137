export const getPriceHTBase = (price: string, tva: number) => {
    const tvaRate = tva / 100;
    const ttc = parseInt(price);
    const value = ttc / (1 + tvaRate);
    const result = parseFloat(value.toFixed(2));
    return result;
};

export const getPriceTVABase = (price: string, tva: number) => {
    const tvaRate = tva / 100;
    const ttc = parseInt(price);
    const value = (ttc * tvaRate) / (1 + tvaRate);
    const result = parseFloat(value.toFixed(2));
    return result;
};

export const getNumberTVA = () => {
    return "FR 36 845118231";
};