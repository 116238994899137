import request from './request';

const send = (data: any) => {
  return request.post('/contacts', {
    name: data.name,
    lastName: data.lastName,
    email: data.email,
    tel: data.tel,
    object: data.object,
    content: data.content,
  });
};

const image = (image: File, id: Number) => {
  const data = new FormData();
  // @ts-ignore
  data.append('file', image);
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return request.post('/contacts' + '/' + id + '/image', data, {
    headers,
  });
};

export default {
  send,
  image,
};
